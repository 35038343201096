import request from '@/utils/request.js';

export function searchComponents(data) {
  return request.get('searchComponents', data);
}

export function getComponent(data) {
  return request.get('getComponent', data);
}

export function getComponentImg2(data) {
  return request.get('getComponentImg2', data);
}

export function deleteComponentImg2(data) {
  return request.post('deleteComponentImg2', data);
}

export function getComponentNames() {
  return request.get('getComponentNames');
}

//--------------------------------
export function getComponents2(data) {
  return request.get('getComponents2', data);
}

export function getComponent2(data) {
  return request.get('getComponent2', data);
}

export function deleteComponent2(data) {
  return request.post('deleteComponent2', data);
}

export function updateComponent2(data) {
  return request.post('updateComponent2', data);
}

export function addComponent2(data) {
  return request.post('addComponent2', data);
}

export function getComponentImg(data) {
  return request.get('getComponentImg', data);
}

export function deleteComponentImg(data) {
  return request.post('deleteComponentImg', data);
}

//-------------------------
export function searchComponents2(data) {
  return request.get('searchComponents2', data);
}