import {login, logout, getSelf as getSelfApi} from '@/api/admin.js';
import {getToken, setToken, removeToken, getSelf, setSelf, removeSelf} from "@/utils/storage.js";

const state = {
  token: getToken() || '',
  self: getSelf() || null,
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setSelf(state, _self) {
    state.self = _self;
  },
};

const actions = {
  login({commit}, admin) {
    return new Promise((resolve, reject) => {
      login(admin).then(response => {
        const {data} = response;
        commit('setToken', data.token);
        commit('setSelf', data.self);
        setToken(data.token);
        setSelf(data.self);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  getSelf({commit}) {
    return new Promise((resolve, reject) => {
      getSelfApi().then(response => {
        const {data} = response;
        commit('setSelf', data.self);
        setSelf(data.self);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  logout({dispatch}) {
    return new Promise((resolve) => {
      logout().then(() => {
      }).catch(err => {
        console.log(err);
      });
      dispatch('clearToken').then(() => {
        resolve();
      });
    });
  },
  clearToken({commit}) {
    return new Promise((resolve) => {
      commit('setToken', '');
      commit('setSelf', null);
      removeToken();
      removeSelf();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};