<template>
  <div>
    <a-spin :spinning="updatePart.loading">
      <span class="my-label">原厂号码：</span>
      <a-input v-model:value="updatePart.data.part_no" style="width:200px;" placeholder="原厂号码"/>
      <br>
      <span class="my-label">配件名称：</span>
      <a-input v-model:value="updatePart.data.part_name" placeholder="配件名称" style="width:200px;"/>
      <span class="my-label2">配件名称（中文）：</span>
      <a-input v-model:value="updatePart.data.part_name_2" placeholder="配件名称（中文）" style="width:200px;"/>
      <br>

      <span class="my-label">成本价：</span>
      <a-input-number v-model:value="updatePart.data.price_1" style="width:200px;" placeholder="成本价"/>
      <span class="my-label2">批发价：</span>
      <a-input-number v-model:value="updatePart.data.price_2" style="width:200px;" placeholder="批发价"/>
      <br>
      <span class="my-label">零售价：</span>
      <a-input-number v-model:value="updatePart.data.price_3" style="width:200px;" placeholder="零售价"/>
      <span class="my-label2">特殊价：</span>
      <a-input-number v-model:value="updatePart.data.price_4" style="width:200px;" placeholder="特殊价"/>
      <br>
      <span class="my-label">重量：</span>
      <a-input-number v-model:value="updatePart.data.weight" style="width:200px;" placeholder="重量"/>
      <span class="my-label2">重量单位：</span>
      <a-input v-model:value="updatePart.data.weight_unit" style="width:200px;" placeholder="重量单位" disabled/>
      <br>
      <span class="my-label">规格：</span>
      <a-input v-model:value="updatePart.data.spec" style="width:200px;" placeholder="规格"/>
      <span class="my-label2">排序：</span>
      <a-input-number v-model:value="updatePart.data.order" style="width:200px;" placeholder="排序"/>
      <br>
      <span class="my-label">材质：</span>
      <a-input v-model:value="updatePart.data.material" style="width:200px;" placeholder="材质"/>
      <span class="my-label2">备注：</span>
      <a-input v-model:value="updatePart.data.remark" style="width:200px;" placeholder="备注"/>
      <br>
      <span class="my-label">描述：</span>
      <a-textarea v-model:value="updatePart.data.descr" style="width:550px;" placeholder="描述"/>

      <div style="text-align: center;margin-top: 10px;">
        <a-button type="primary" @click="onUpdatePart" :loading="updatePart.submitting">
          更新
        </a-button>
        <a-button type="primary" @click="resetUpdatePart" style="margin-left: 20px;">
          重填
        </a-button>
        <a-button type="primary" @click="goReplace" style="margin-left: 20px;">仅查看</a-button>
        <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getPart2, updatePart2} from "@/api/part";
  import {cloneDeep} from "@/utils/util";
  import {message, Modal} from 'ant-design-vue';

  export default {
    name: "part-update",
    data() {
      return {
        part: null,
        updatePart: {
          loading: false,
          options: [],
          data: {
            id:'',
            part_no: '',
            part_name: '',
            part_name_2: '',
            price_1: null,
            price_2: null,
            price_3: null,
            price_4: null,
            weight: null,
            weight_unit: '',
            spec: '',
            order: null,
            material: '',
            remark: '',
            descr: '',
          },
          submitting: false,
        },
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({part_no}) {
        this.getPart2({part_no: part_no});
        this.isGoBack = false;
      },
      getPart2(params) {
        this.updatePart.loading = true;
        getPart2(params).then(res => {
          this.part = JSON.parse(JSON.stringify(res.data.part));

          this.updatePart.data.id = res.data.part.id;
          this.updatePart.data.part_no = res.data.part.part_no;
          this.updatePart.data.part_name = res.data.part.part_name;
          this.updatePart.data.part_name_2 = res.data.part.part_name_2;
          this.updatePart.data.price_1 = res.data.part.price_1;
          this.updatePart.data.price_2 = res.data.part.price_2;
          this.updatePart.data.price_3 = res.data.part.price_3;
          this.updatePart.data.price_4 = res.data.part.price_4;
          this.updatePart.data.weight = res.data.part.weight;
          this.updatePart.data.weight_unit = res.data.part.weight_unit;
          this.updatePart.data.spec = res.data.part.spec;
          this.updatePart.data.order = res.data.part.order;
          this.updatePart.data.material = res.data.part.material;
          this.updatePart.data.remark = res.data.part.remark;
          this.updatePart.data.descr = res.data.part.descr;

        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updatePart.loading = false;
        });
      },
      onUpdatePart() {
        //检查是不有空以及 TODO重复
        let part = this.updatePart;
        if (this.checkEmpty(part.data.part_no, '配件原厂号码不能空') || this.checkEmpty(part.data.part_name, '配件名称不能空') || this.checkEmpty(part.data.part_name_2, '配件名称（中文）不能空') || this.checkEmpty(part.data.order, '配件排序不能空')) {
          return;
        }

        let params = {
          part: JSON.parse(JSON.stringify(part.data)),
        };

        part.submitting = true;
        updatePart2(params).then(res => {
          Modal.success({
            title: '配件 ' + params.part.part_no + ' 更新成功',
          });
          //清空配件数据
          this.getPart2({part_no: this.updatePart.data.part_no});
        }).catch(err => {
          Modal.error({
            title: '更新配件失败',
            content: err.message || '',
          });
        }).finally(() => {
          part.submitting = false;
        });
      },
      resetUpdatePart() {
        let part = JSON.parse(JSON.stringify(this.part));

        this.updatePart.data.part_no = part.part_no;
        this.updatePart.data.part_name = part.part_name;
        this.updatePart.data.part_name_2 = part.part_name_2;
        this.updatePart.data.price_1 = part.price_1;
        this.updatePart.data.price_2 = part.price_2;
        this.updatePart.data.price_3 = part.price_3;
        this.updatePart.data.price_4 = part.price_4;
        this.updatePart.data.weight = part.weight;
        this.updatePart.data.weight_unit = part.weight_unit;
        this.updatePart.data.spec = part.spec;
        this.updatePart.data.order = part.order;
        this.updatePart.data.material = part.material;
        this.updatePart.data.remark = part.remark;
        this.updatePart.data.descr = part.descr;

      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/part/detail', query: {part_no: this.part.part_no}});
      },
    }
  }
</script>

<style scoped>
  .delete-button {
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .delete-button:hover {
    color: #ff4d4f;
  }

  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>