import request from '@/utils/request.js';

export function getModels(data) {
  return request.get('getModels', data);
}

export function addModel(data) {
  return request.post('addModel', data);
}

//--------------------------
export function searchModels(data) {
  return request.get('searchModels', data);
}

export function getModels2(data) {
  return request.get('getModels2', data);
}

export function deleteModel2(data) {
  return request.post('deleteModel2', data);
}

export function updateModel2(data) {
  return request.post('updateModel2', data);
}

export function getModelImg(data) {
  return request.get('getModelImg', data);
}

export function deleteModelImg(data) {
  return request.post('deleteModelImg', data);
}

export function getModelPdf(data) {
  return request.get('getModelPdf', data);
}

export function deleteModelPdf(data) {
  return request.post('deleteModelPdf', data);
}

export function searchSerials(data) {
  return request.get('searchSerials', data);
}

export function searchNickNames(data) {
  return request.get('searchNickNames', data);
}