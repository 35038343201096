<template>
  <div>
    <a-layout>
      <a-layout-header class="model-kit-header">
        <a-select
          show-search
          v-model:value="modelSelector.value"
          :options="modelSelector.options"
          style="width: 500px"
          @change="onModelChange"
          placeholder="选择机型"
          :loading="modelSelector.loading"
        >
          <template #option="{ label,value,nick_name }">
            <template v-if="value">
              {{label}}
              <span v-if="value" style="float: right;">{{nick_name}}</span>
            </template>
            <template v-else>
              <span style="color:dodgerblue;font-size: 16px;font-weight: bolder;">{{label}}</span>
            </template>
          </template>

        </a-select>
        <a-button type="primary" @click="goBack" style="float: right;margin-top: 8px;margin-left: 10px;">返回</a-button>
        <a-button v-if="_self&&_self.type==='admin'" type="primary" @click="onShowAddModelModal" style="float: right;margin-top: 8px;">
          新增机型
        </a-button>
        <span style="float:right;color:#666;padding-right: 10px;">
          品牌编号：{{brand_no}}
        </span>
      </a-layout-header>
      <a-layout>
        <a-layout-sider class="model-kit-sider" style="min-height: 228px;" width="250">

          <a-spin :spinning="kitsLoading">
            <div style="padding:5px 5px 57px 5px;min-height: 170px;">
              <a-tree v-if="kit_component_tree.length" v-model:selectedKeys="treeSelectedKeys" :tree-data="kit_component_tree" :showLine="true" @select="onTreeSelect">
                <template #title="{ title,remark }">
                  {{title}}{{remark? '（'+remark+'）':''}}
                </template>
              </a-tree>
              <div v-else-if="modelSelector.value&&!kitsLoading" style="text-align: center;color:#aaa;">没有修理包</div>
            </div>
          </a-spin>
          <div style="position: absolute;bottom: 10px;left: 70px;" v-if="_self&&_self.type==='admin'">
            <a-button v-if="modelSelector.value" type="primary" @click="onShowAddKitModal" style="margin-top: 5px;">
              添加修理包
            </a-button>
          </div>
        </a-layout-sider>
        <a-layout-content class="model-kit-content">

          <div v-if="showType==='addKit'">
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">KIT NO.：</span>
            <a-auto-complete
              v-model:value="addKit.data.kit_no"
              :options="addKit.options"
              style="width: 200px"
              placeholder="修理包编号"
              @search="onKitNameSearch(addKit)"
              @select="onKitNameSelect(addKit)"
              :loading="addKit.loading"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">KIT NAME：</span>
            <a-auto-complete
              v-model:value="addKit.data.kit_name"
              :options="kitNames2"
              style="width: 200px"
              placeholder="修理包名称"
              @select="onKitNameSelect2(addKit)"
              :filter-option="filterOption"
            />
            <span style="display: inline-block;text-align: right;width:150px;padding: 6px 0;">修理包名称（中文）：</span>
            <a-input v-model:value="addKit.data.kit_name_2" placeholder="修理包名称（中文）" style="width:200px;"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">MODEL NO.：</span>
            <a-input v-model:value="addKit.data.part_no2" placeholder="" style="width:200px;"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">MODEL NAME：</span>
            <a-input v-model:value="addKit.data.model_no2" placeholder="" style="width:200px;"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">备注：</span>
            <a-input v-model:value="addKit.data.remark" placeholder="" style="width:200px;"/>
            <span style="display: inline-block;text-align: right;width:150px;padding: 6px 0;">排序：</span>
            <a-input-number v-model:value="addKit.data.order" style="width:200px;"/>
            <br>

            <template v-for="(component,c_index) in addKit.data.components" :key="c_index">
              <a-divider style="background-color: #888;margin: 5px 0;"/>
              序号：
              <a-input v-model:value="component.data.index" placeholder="" style="width:50px;"/>
              元件编号：
              <a-auto-complete
                v-model:value="component.data.component_no"
                :options="component.options"
                style="width: 200px;"
                placeholder="元件编号"
                @search="onComponentNameSearch(component)"
                @select="onComponentNameSelect(component)"
                :loading="component.loading"
              />
              元件名称：
              <a-auto-complete
                v-model:value="component.data.component_name"
                :options="componentNames2"
                style="width:calc(100%/2 - 730px/2);"
                placeholder="元件名称"
                @select="onComponentNameSelect2(component)"
                :filter-option="filterOption"
              />
              元件名称（中文）：
              <a-input v-model:value="component.data.component_name_2" placeholder="元件名称（中文）" style="width:calc(100%/2 - 730px/2);"/>
              数量：
              <a-input v-model:value="component.data.count" placeholder="" style="width:50px;"/>

              <MinusCircleOutlined title="取消元件" style="position: relative;top: 4px;left: 10px;" class="delete-button" @click="onRemoveComponent(addKit,c_index)"/>
              <br>

              <a-table :columns="columns" :data-source="component.data.parts" bordered :pagination="false" size="small">
                <template #bodyCell="{record,index,column}">
                  <template v-if="!['part_no','operation'].includes(column.dataIndex)">
                    <a-input v-model:value="record.data[column.dataIndex]"/>
                  </template>
                  <template v-else-if="column.dataIndex==='part_no'">
                    <a-auto-complete
                      v-model:value="record.data[column.dataIndex]"
                      :options="record.options"
                      style="width: 100%"
                      @search="onPartNameSearch(record)"
                      @select="onPartNameSelect(record)"
                      :loading="record.loading"
                    />
                  </template>
                  <template v-else>
                    <MinusCircleOutlined title="取消配件" class="delete-button" @click="onRemovePart(component,index)"/>
                  </template>
                </template>
              </a-table>

              <div style="margin-top: 5px;">
                <a-button type="dashed" @click="onAddPart(component)">
                  <PlusOutlined/>
                  添加配件
                </a-button>
              </div>

            </template>
            <a-divider style="background-color: #888;margin: 5px 0;"/>
            <div>
              <a-button type="dashed" @click="onAddComponent(addKit)">
                <PlusOutlined/>
                添加元件
              </a-button>
            </div>

            <div style="text-align: center;margin-top: 10px;">
              <a-button type="primary" @click="onAddKit" :loading="addKit.submitting">
                添加
              </a-button>
              <a-button type="primary" @click="clearAddKit" style="margin-left: 20px;">
                重填
              </a-button>
            </div>
          </div>
          <div v-else-if="showType==='component'">
            <a-spin :spinning="component.loading">
              <a-image-preview-group v-if="component.data">
                <a-list :grid="{gutter:8,column: 2}" :data-source="component.data.img2">
                  <template #renderItem="{item,index}">
                    <a-list-item>
                      <a-card hoverable style="text-align: center;">
                        <template #cover>
                          <a-image :src="item" style="max-width: 100%;width:auto;"/>
                        </template>
                        <template v-if="component.showEdit" #actions>
                          <delete-outlined @click="onDeleteImg2(index)"/>
                        </template>
                      </a-card>
                    </a-list-item>
                  </template>
                </a-list>
              </a-image-preview-group>
              <div style="text-align: center;" v-if="_self&&_self.type==='admin'">
                <a-upload
                  v-if="component.data"
                  :showUploadList="false"
                  v-model:file-list="component.upload.fileList"
                  name="img2"
                  :multiple="false"
                  accept="image/apng,image/bmp,image/gif,image/jpeg,image/pjpeg,image/png,image/svg+xml,image/tiff,image/webp,image/x-icon"
                  :headers="component.upload.headers"
                  :action="component.upload.action"
                  :before-upload="beforeUpload"
                  @change="onUploadChange"
                  :data="{component_no:component.data.component_no,kit_model_id:component.data.kit_model_id}"
                  :disabled="component.upload.loading"
                >
                  <a-button type="dashed" :loading="component.upload.loading">
                    <upload-outlined v-if="!component.upload.loading"></upload-outlined>
                    上传图片
                  </a-button>
                </a-upload>
                <a-button style="margin-left: 20px;" type="primary" @click="onTriggerComponentEdit">
                  {{component.showEdit? '仅查看':'可编辑'}}
                </a-button>
              </div>
            </a-spin>
          </div>
          <div v-else-if="showType==='kit'">
            <a-spin :spinning="kit.loading">
              <div v-if="kit.data">
                <div v-if="!showModel2Img">
                  <span class="my-label">KIT NO.：</span><span :class="{'haha':search_type==='kit'&&search_key===kit.data.kit_no}">{{kit.data.kit_no}}</span>
                  <br>
                  <span class="my-label">KIT NAME：</span><span style="display:inline-block;min-width: 200px;">{{kit.data.kit_name}}</span>
                  <span class="my-label2">修理包名称（中文）：</span><span style="display:inline-block;min-width:200px;">{{kit.data.kit_name_2}}</span>
                  <br>
                  <span class="my-label">MODEL NO.：</span><span :class="{'haha':search_type==='part_no2'&&search_key===kit.data.part_no2}">{{kit.data.part_no2}}</span>
                  <a-button v-if="kit.data.part_no2" style="margin-left: 20px;" type="primary" size="small" @click="onShowModel2Img(kit.data.part_no2)">
                    图片
                  </a-button>
                  <br>
                  <span class="my-label">MODEL NAME：</span><span :class="{'haha':search_type==='model_no2'&&search_key===kit.data.model_no2}">{{kit.data.model_no2}}</span>
                  <br>
                  <span class="my-label">备注：</span><span style="display:inline-block;min-width: 200px;">{{kit.data.remark}}</span>
                  <span class="my-label2">排序：</span><span style="display:inline-block;min-width:200px;">{{kit.data.order}}</span>
                  <br>

                  <template v-for="(component,c_index) in kit.data.components" :key="c_index">
                    <a-divider style="background-color: #888;margin: 5px 0;"/>
                    序号：<span style="display:inline-block;width:50px;">{{component.data.index}}</span>
                    元件编号：<span style="display:inline-block;min-width: 200px;" :class="{'haha':search_type==='component'&&search_key===component.data.component_no}">{{component.data.component_no}}</span>
                    元件名称：<span style="display:inline-block;min-width: 200px;">{{component.data.component_name}}</span>
                    元件名称（中文）：<span style="display:inline-block;min-width: 200px;">{{component.data.component_name_2}}</span>
                    数量：<span style="display:inline-block;min-width: 50px;">{{component.data.count}}</span>
                    <br>

                    <a-table :columns="columns2" :data-source="component.data.parts" bordered :pagination="false" size="small" :rowClassName="haha" :locale="{emptyText:'暂无数据'}">
                      <template #bodyCell="{record,column}">
                        {{record.data[column.dataIndex]}}
                      </template>
                    </a-table>
                  </template>
                  <!--
                  <a-button type="primary" @click="onDownloadKit" :loading="downloading">导出修理包</a-button>
                  -->
                  <div style="text-align: center;margin-top:10px;">
                    <a-button type="dashed" v-if="_self&&(_self.type==='admin'||(_self.type==='user'&&_self.qx.download))">
                      <a :href="downloadBaseUrl +'&kit_no='+ kit.data.kit_no+'&model_no='+modelSelector.value" target="_blank">
                        <download-outlined/>
                        导出修理包</a>
                    </a-button>
                    <a-button v-if="_self&&_self.type==='admin'" style="margin-left: 20px;" type="primary" @click="onTriggerKitEdit">
                      {{showEdit?
                      '仅查看':'可编辑'}}
                    </a-button>
                  </div>
                </div>
                <div v-else>

                  <a-spin :spinning="model2.loading">
                    <p>MODEL NO.：{{model2.data&&model2.data.model_no}}</p>
                    <a-image-preview-group v-if="model2.data">
                      <a-list :grid="{gutter:16,column: 2}" :data-source="model2.data.img">
                        <template #renderItem="{item,index}">
                          <a-list-item>
                            <a-card hoverable style="text-align: center;">
                              <template #cover>
                                <a-image :src="item" style="max-width: 100%;width:auto;"/>
                              </template>
                              <template v-if="model2.showEdit" #actions>
                                <delete-outlined @click="onDeleteModel2Img(index)"/>
                              </template>
                            </a-card>
                          </a-list-item>
                        </template>
                      </a-list>
                    </a-image-preview-group>
                    <div style="text-align: center;">
                      <a-upload
                        v-if="model2.data&&_self&&_self.type==='admin'"
                        :showUploadList="false"
                        v-model:file-list="model2.upload.fileList"
                        name="img"
                        :multiple="false"
                        accept="image/apng,image/bmp,image/gif,image/jpeg,image/pjpeg,image/png,image/svg+xml,image/tiff,image/webp,image/x-icon"
                        :headers="model2.upload.headers"
                        :action="model2.upload.action"
                        :before-upload="beforeUpload"
                        @change="onUploadChangeModel2"
                        :data="{model_no:model2.data.model_no}"
                        :disabled="model2.upload.loading"
                      >
                        <a-button type="dashed" :loading="model2.upload.loading">
                          <upload-outlined v-if="!model2.upload.loading"></upload-outlined>
                          上传图片
                        </a-button>
                      </a-upload>
                      <a-button type="primary" @click="onTriggerModel2Edit" style="margin-left: 20px;" v-if="_self&&_self.type==='admin'">
                        {{model2.showEdit? '仅查看':'可编辑'}}
                      </a-button>
                      <a-button type="primary" @click="onHideModel2Img" style="margin-left: 20px;">返回</a-button>
                    </div>
                  </a-spin>

                </div>
              </div>
            </a-spin>
          </div>
          <div v-else-if="showType==='updateKit'">
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">KIT NO.：</span>
            <a-input v-model:value="updateKit.data.kit_no" style="width:200px;" placeholder="修理包编号"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">KIT NAME：</span>
            <a-auto-complete
              v-model:value="updateKit.data.kit_name"
              :options="kitNames2"
              style="width: 200px"
              placeholder="修理包名称"
              @select="onKitNameSelect2(updateKit)"
              :filter-option="filterOption"
            />
            <span style="display: inline-block;text-align: right;width:150px;padding: 6px 0;">修理包名称（中文）：</span>
            <a-input v-model:value="updateKit.data.kit_name_2" placeholder="修理包名称（中文）" style="width:200px;"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">MODEL NO.：</span>
            <a-input v-model:value="updateKit.data.part_no2" placeholder="" style="width:200px;"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">MODEL NAME：</span>
            <a-input v-model:value="updateKit.data.model_no2" placeholder="" style="width:200px;"/>
            <br>
            <span style="display: inline-block;text-align: right;width:120px;padding: 6px 0;">备注：</span>
            <a-input v-model:value="updateKit.data.remark" placeholder="" style="width:200px;"/>
            <span style="display: inline-block;text-align: right;width:150px;padding: 6px 0;">排序：</span>
            <a-input-number v-model:value="updateKit.data.order" style="width:200px;"/>
            <br>

            <template v-for="(component,c_index) in updateKit.data.components" :key="c_index">
              <a-divider style="background-color: #888;margin: 5px 0;"/>
              序号：
              <a-input v-model:value="component.data.index" placeholder="" style="width:50px;"/>
              元件编号：
              <a-auto-complete
                v-model:value="component.data.component_no"
                :options="component.options"
                style="width: 200px"
                placeholder="元件编号"
                @search="onComponentNameSearch(component)"
                @select="onComponentNameSelect(component)"
                :loading="component.loading"
              />
              元件名称：
              <a-auto-complete
                v-model:value="component.data.component_name"
                :options="componentNames2"
                style="width:calc(100%/2 - 730px/2);"
                placeholder="元件名称"
                @select="onComponentNameSelect2(component)"
                :filter-option="filterOption"
              />
              元件名称（中文）：
              <a-input v-model:value="component.data.component_name_2" placeholder="元件名称（中文）" style="width:calc(100%/2 - 730px/2);"/>
              数量：
              <a-input v-model:value="component.data.count" placeholder="" style="width:50px;"/>

              <MinusCircleOutlined title="取消元件" style="position: relative;top: 4px;left: 10px;" class="delete-button" @click="onRemoveComponent(updateKit,c_index)"/>
              <br>

              <a-table :columns="columns" :data-source="component.data.parts" bordered :pagination="false" size="small">
                <template #bodyCell="{record,index,column}">
                  <template v-if="!['part_no','operation'].includes(column.dataIndex)">
                    <a-input v-model:value="record.data[column.dataIndex]"/>
                  </template>
                  <template v-else-if="column.dataIndex==='part_no'">
                    <a-auto-complete
                      v-model:value="record.data[column.dataIndex]"
                      :options="record.options"
                      style="width: 100%"
                      @search="onPartNameSearch(record)"
                      @select="onPartNameSelect(record)"
                      :loading="record.loading"
                    />
                  </template>
                  <template v-else>
                    <MinusCircleOutlined title="取消配件" class="delete-button" @click="onRemovePart(component,index)"/>
                  </template>
                </template>
              </a-table>

              <div style="margin-top: 5px;">
                <a-button type="dashed" @click="onAddPart(component)">
                  <PlusOutlined/>
                  添加配件
                </a-button>
              </div>

            </template>
            <a-divider style="background-color: #888;margin: 5px 0;"/>
            <div style="">
              <a-button type="dashed" @click="onAddComponent(updateKit)">
                <PlusOutlined/>
                添加元件
              </a-button>
            </div>

            <div style="text-align: center;margin-top: 10px;">
              <a-button type="primary" @click="onUpdateKit" :loading="updateKit.submitting">
                更新
              </a-button>
              <a-button type="primary" @click="onTriggerKitEdit" style="margin-left: 20px;">{{showEdit? '仅查看':'可编辑'}}
              </a-button>
              <a-button type="primary" @click="onDeleteKit" :loading="updateKit.deleting" style="margin-left: 20px;">
                {{'取消修理包'}}
              </a-button>
            </div>
          </div>

        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal v-model:visible="addModelModal.visible" title="新增机型" :closable="true" :maskClosable="false" @cancel="onCloseAddModelModal" :footer="null">
      <a-form>
        <a-form-item label="机型品牌" name="brand_no">
          <a-input v-model:value="addModelModal.data.brand_no" disabled/>
        </a-form-item>
        <a-form-item label="机型编号" name="model_no">
          <a-input v-model:value="addModelModal.data.model_no"/>
        </a-form-item>
        <a-form-item label="机型系列" name="serial">
          <a-auto-complete
            v-model:value="addModelModal.data.serial"
            :options="addModelModal.options2"
            @search="onModelSerialSearch(addModelModal)"
            :loading="addModelModal.options_loading2"/>
        </a-form-item>
        <a-form-item label="机型别名" name="nick_name">
          <a-auto-complete
            v-model:value="addModelModal.data.nick_name"
            :options="addModelModal.options3"
            @search="onModelNickNameSearch(addModelModal)"
            :loading="addModelModal.options_loading3"/>
        </a-form-item>
        <a-form-item label="机型排序" name="order" extra="排序号越大，机型越靠前">
          <a-input v-model:value="addModelModal.data.order"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onAdd" :loading="addModelModal.loading">新增</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {cloneDeep} from "@/utils/util";
  import {getModels, addModel, searchSerials, searchNickNames} from "@/api/model";
  import {getModel2Img, deleteModel2Img} from "@/api/model2";
  import {getKits, addKit, searchKits, getKit, deleteKit, updateKit, downloadKit, getKitNames} from "@/api/kit";
  import {
    searchComponents,
    getComponent,
    getComponentImg2,
    deleteComponentImg2,
    getComponentNames
  } from "@/api/component";
  import {searchParts, getPart} from "@/api/part";
  import {message, Modal} from 'ant-design-vue';
  import {
    MinusCircleOutlined,
    PlusOutlined,
    UploadOutlined,
    DownloadOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
  } from '@ant-design/icons-vue';
  import config from '@/config.js';
  import {createVNode} from 'vue';

  let initConfig = {
    emptyKit: {
      loading: false,
      options: [],
      data: {
        kit_no: '',
        kit_name: '',
        kit_name_2: '',
        part_no2: '',
        model_no2: '',
        remark: '',
        order: 0,
        components: [
          {
            data: {
              index: '',
              component_no: '',
              component_name: '',
              component_name_2: '',
              parts: [
                {
                  data: {
                    order: '',
                    index: '',
                    part_no: '',
                    part_name: '',
                    spec: '',
                    count: '',
                    remark: '',
                  },
                  options: [],
                  loading: false,
                }
              ],
            },
            options: [],
            loading: false,
          },
        ],
      },
      submitting: false,
    },
  };

  export default {
    name: "model-kit",
    components: {
      MinusCircleOutlined,
      PlusOutlined,
      UploadOutlined,
      DownloadOutlined,
      DeleteOutlined,
    },
    data() {
      return {
        brand_no: null,
        modelSelector: {
          value: undefined,
          options: [],
          loading: false,
        },
        addModelModal: {
          visible: false,
          loading: false,
          options2: [],
          options_loading2: false,
          options3: [],
          options_loading3: false,
          data: {
            brand_no: '',
            model_no: '',
            serial: '',
            nick_name: '',
            order: 0,
          }
        },

        //修理包树结构
        kitsLoading: false,
        kits: [],
        treeSelectedKeys: [],

        //添加修理包
        addKit: cloneDeep(initConfig.emptyKit),
        columns: [
          {
            title: '排序',
            dataIndex: 'order',
            width: '80px',
          },
          {
            title: '序号',
            dataIndex: 'index',
            width: '80px',
          },
          {
            title: '原厂号码',
            dataIndex: 'part_no',
            width: '20%',
          },
          {
            title: '名称',
            dataIndex: 'part_name',
            width: '20%',
          },
          {
            title: '规格',
            dataIndex: 'spec',
            width: '20%',
          },
          {
            title: '数量',
            dataIndex: 'count',
            width: '80px',
          },
          {
            title: '用处备注',
            dataIndex: 'remark',
            //width:'15%',
          },
          {
            title: '取消',
            dataIndex: 'operation',
            width: '45px',
          }
        ],


        showType: null,//component,kit,updateKit,addKit,null

        //元件显示的图片
        component: {
          loading: false,
          data: null,
          upload: {
            loading: false,
            headers: {
              'X-Token': this.$store.getters.token,
            },
            fileList: [],
            action: config.baseURL + '/uploadComponentImg2',
          },
          showEdit: false,
        },

        //修理包显示的数据
        kit: cloneDeep(initConfig.emptyKit),
        showEdit: false,
        columns2: [
          {
            title: '排序',
            dataIndex: 'order',
            width: '80px',
          },
          {
            title: '序号',
            dataIndex: 'index',
            width: '80px',
          },
          {
            title: '原厂号码',
            dataIndex: 'part_no',
            width: '20%',
          },
          {
            title: '名称',
            dataIndex: 'part_name',
            width: '20%',
          },
          {
            title: '规格',
            dataIndex: 'spec',
            width: '20%',
          },
          {
            title: '数量',
            dataIndex: 'count',
            width: '80px',
          },
          {
            title: '用处备注',
            dataIndex: 'remark',
            //width:'15%',
          },
        ],
        //更新修理包
        updateKit: null,

        downloading: false,

        downloadBaseUrl: config.baseURL + '/downloadKit?token=' + this.$store.getters.token,

        kitNames: [],
        componentNames: [],

        search_key: '',
        search_type: '',

        showModel2Img: false,
        model2: {
          loading: false,
          data: null,
          upload: {
            loading: false,
            headers: {
              'X-Token': this.$store.getters.token,
            },
            fileList: [],
            action: config.baseURL + '/uploadModel2Img',
          },
          showEdit: false,
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      kit_component_tree() {
        return this.kits.map(item => {
          let children = item.components.map(item2 => {
            return {
              title: item2.component_name_2,
              key: item.kit_no + ':' + item.kit_model_id + ':' + item2.component_no + ':component'
            };
          });
          return {
            title: item.kit_name_2,
            key: item.kit_no + ':kit',
            children: children,
            isLeaf: false,
            remark: item.remark
          };
        });
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      kitNames2() {
        return this.kitNames.map(item => {
          return {value: item.kit_name, label: item.kit_name, kit_name_2: item.kit_name_2};
        })
      },
      componentNames2() {
        return this.componentNames.map(item => {
          return {value: item.component_name, label: item.component_name, component_name_2: item.component_name_2};
        })
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({brand_no, model_no, kit_no, search_key, search_type}) {
        this.brand_no = brand_no;
        this.addModelModal.data.brand_no = brand_no;
        let tem = this.getModels({brand_no: this.brand_no});
        if (model_no) {
          this.modelSelector.value = model_no;
          this.onModelChange();
        }
        if (kit_no) {
          this.showType = 'kit';
          this.getKit({kit_no: kit_no, model_no: model_no});
          tem.then(() => {
            this.treeSelectedKeys = [kit_no + ':kit'];
          });
        }
        this.isGoBack = false;
        this.getKitNames();
        this.getComponentNames();

        if (search_key) {
          this.search_key = search_key;
          this.search_type = search_type;
        }
      },
      getComponentNames() {
        getComponentNames().then(res => {
          this.componentNames = res.data.component_names;
        }).catch(err => {

        }).finally(() => {

        });
      },
      getKitNames() {
        getKitNames().then(res => {
          this.kitNames = res.data.kit_names;
        }).catch(err => {

        }).finally(() => {

        });
      },
      getModels(params) {
        this.modelSelector.loading = true;
        return getModels(params).then(res => {
          this.modelSelector.options = res.data.models;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.modelSelector.loading = false;
        });
      },
      filterOption(input, option) {
        return input ? option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0 : true;
      },

      onShowAddModelModal() {
        this.addModelModal.visible = true;
      },
      onAdd() {
        if (this.addModelModal.data.model_no === '') {
          Modal.warning({
            title: '机型编号不能空',
          });
          return;
        }
        if (this.addModelModal.data.serial === '') {
          Modal.warning({
            title: '机型系列不能空',
          });
          return;
        }
        if (this.addModelModal.data.order === '') {
          Modal.warning({
            title: '机型排序不能空',
          });
          return;
        }
        this.addModel({model: this.addModelModal.data});
      },
      addModel(params) {
        this.addModelModal.loading = true;
        addModel(params).then(() => {
          Modal.success({
            title: '机型 ' + params.model.model_no + ' 新增成功',
          });
          this.addModelModal.visible = false;
          this.getModels({brand_no: this.brand_no});
          this.addModelModal.data.model_no = '';
          this.addModelModal.data.serial = '';
          this.addModelModal.data.nick_name = '';
          this.addModelModal.data.order = 0;
        }).catch(err => {
          Modal.error({
            title: '新增机型失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.addModelModal.loading = false;
        });
      },
      onCloseAddModelModal() {
        this.addModelModal.visible = false;
      },

      onModelChange() {
        this.treeSelectedKeys = [];
        this.getKits();
        this.showType = null;
        this.showEdit = false;
        this.component.showEdit = false;
      },
      getKits() {
        let params = {
          model_no: this.modelSelector.value,
        };
        this.kitsLoading = true;
        getKits(params).then(res => {
          this.kits = res.data.kits;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.kitsLoading = false;
        });
      },

      onShowAddKitModal() {
        this.showType = 'addKit';
        this.treeSelectedKeys = [];
      },
      onKitNameSearch(kit) {
        let params = {
          kit_no: kit.data.kit_no,
        };

        if (kit.timer) {
          clearTimeout(kit.timer);
        }

        kit.timer = setTimeout(() => {
          kit.loading = true;
          searchKits(params).then(res => {
            if (kit.data.kit_no !== params.kit_no) {
              return;
            }
            kit.options = res.data.kits.map(item => {
              return {value: item.kit_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            kit.loading = false;
          });
        }, 500);

      },
      onKitNameSelect(kit) {
        let params = {
          kit_no: kit.data.kit_no,
        };
        kit.loading = true;
        getKit(params).then(res => {
          kit.data.kit_no = res.data.kit.kit_no;
          kit.data.kit_name = res.data.kit.kit_name;
          kit.data.kit_name_2 = res.data.kit.kit_name_2;
          kit.data.components = res.data.kit.components.map(item => {
            item.parts = item.parts.map(item2 => {
              return {
                data: item2,
                options: [],
                loading: false,
              };
            });
            return {
              data: item,
              options: [],
              loading: false,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          kit.loading = false;
        });
      },
      onComponentNameSearch(component) {
        let params = {
          component_no: component.data.component_no,
        };

        if (component.timer) {
          clearTimeout(component.timer);
        }

        component.timer = setTimeout(() => {
          component.loading = true;
          searchComponents(params).then(res => {
            if (component.data.component_no !== params.component_no) {
              return;
            }
            component.options = res.data.components.map(item => {
              return {value: item.component_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            component.loading = false;
          });
        }, 500);
      },
      onComponentNameSelect(component) {
        let params = {
          component_no: component.data.component_no,
        };
        component.loading = true;
        getComponent(params).then(res => {
          component.data.component_no = res.data.component.component_no;
          component.data.component_name = res.data.component.component_name;
          component.data.component_name_2 = res.data.component.component_name_2;
          component.data.parts = res.data.component.parts.map(item => {
            return {
              data: item,
              options: [],
              loading: false,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          component.loading = false;
        });
      },
      onPartNameSearch(part) {
        let params = {
          part_no: part.data.part_no,
        };

        if (part.timer) {
          clearTimeout(part.timer);
        }

        part.timer = setTimeout(() => {
          part.loading = true;
          searchParts(params).then(res => {
            if (part.data.part_no !== params.part_no) {
              return;
            }
            part.options = res.data.parts.map(item => {
              return {value: item.part_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            part.loading = false;
          });
        }, 500);
      },
      onPartNameSelect(part) {
        let params = {
          part_no: part.data.part_no,
        };
        part.loading = true;
        getPart(params).then(res => {
          part.data.part_no = res.data.part.part_no;
          part.data.part_name = res.data.part.part_name;
          part.data.spec = res.data.part.spec;
          //part.data.remark = res.data.part.remark;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          part.loading = false;
        });
      },
      onAddComponent(kit) {
        kit.data.components.push({
          data: {
            index: '',
            component_no: '',
            component_name: '',
            component_name_2: '',
            count: 1,
            parts: [
              {
                data: {
                  order: '',
                  index: '',
                  part_no: '',
                  part_name: '',
                  spec: '',
                  count: '',
                  remark: '',
                },
                options: [],
                loading: false,
              }
            ],
          },
          options: [],
          loading: false,
        });
      },
      onAddPart(component) {
        component.data.parts.push({
          data: {
            order: '',
            index: '',
            part_no: '',
            part_name: '',
            spec: '',
            count: '',
            remark: '',
          },
          options: [],
          loading: false,
        });
      },
      onRemoveComponent(kit, c_index) {
        kit.data.components.splice(c_index, 1);
      },
      onRemovePart(component, p_index) {
        component.data.parts.splice(p_index, 1);
      },
      onAddKit() {
        //检查是不有空以及 TODO重复
        let kit = this.addKit;
        if (this.checkEmpty(kit.data.kit_no, '修理包KIT NO.不能空') || this.checkEmpty(kit.data.kit_name, '修理包KIT NAME不能空') || this.checkEmpty(kit.data.kit_name_2, '修理包名称（中文）不能空') || this.checkEmpty(kit.data.part_no2, '修理包MODEL NO.不能空') || this.checkEmpty(kit.data.order, '修理包排序不能空')) {
          return;
        }
        for (let i = 0; i < kit.data.components.length; i++) {
          let component = kit.data.components[i].data;
          if (this.checkEmpty(component.index, '第' + (i + 1) + '个元件序号不能空') || this.checkEmpty(component.component_no, '第' + (i + 1) + '个元件编号不能空') || this.checkEmpty(component.component_name, '第' + (i + 1) + '个元件名称不能空') || this.checkEmpty(component.component_name_2, '第' + (i + 1) + '个元件名称（中文）不能空') || this.checkEmpty(component.count, '第' + (i + 1) + '个元件数量不能空')) {
            return;
          }
          for (let j = 0; j < component.parts.length; j++) {
            let part = component.parts[j].data;
            if (this.checkEmpty(part.order, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件排序不能空') || this.checkEmpty(part.index, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件序号不能空') || this.checkEmpty(part.part_no, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件原厂号码不能空') || this.checkEmpty(part.part_name, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件名称不能空') || this.checkEmpty(part.spec, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件规格不能空') || this.checkEmpty(part.count, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件数量不能空')) {
              return;
            }
          }
        }

        let params = {
          model_no: this.modelSelector.value,
          kit: JSON.parse(JSON.stringify(kit.data)),
        };
        params.kit.components = params.kit.components.map((item, index) => {
          //item.data.index = index + 1;
          item.data.parts = item.data.parts.map(item2 => item2.data);
          return item.data;
        })
        kit.submitting = true;
        addKit(params).then(res => {
          Modal.success({
            title: '机型 ' + params.model_no + ' 添加修理包 ' + params.kit.kit_no + ' 成功',
          });
          //清空修理包数据
          this.getKits();
          this.clearAddKit();
          this.getKitNames();
          this.getComponentNames();
        }).catch(err => {
          Modal.error({
            title: '添加修理包失败',
            content: err.message || '',
          });
        }).finally(() => {
          kit.submitting = false;
        });
      },
      clearAddKit() {
        this.addKit = cloneDeep(initConfig.emptyKit);
      },
      resetAddKit() {

      },

      onTreeSelect(selectedKeys, e) {
        if (!e.selected) {
          //组件中节点第二次点击是会取消selected，fix
          this.treeSelectedKeys = [e.node.key];
        }
        let selected = this.treeSelectedKeys[0].split(':');
        this.showType = selected[selected.length - 1];
        selected.length = selected.length - 1;

        if (this.showType === 'kit') {
          let key = selected.join(':');
          this.getKit({kit_no: key, model_no: this.modelSelector.value});
          this.showEdit = false;
        } else {
          selected.shift();
          let kit_model_id = selected.shift();
          let key = selected.join(':');
          this.getComponentImg2({component_no: key, kit_model_id: kit_model_id});
          this.component.showEdit = false;
        }
      },

      getKit(params) {
        this.onHideModel2Img();
        this.kit.loading = true;
        return getKit(params).then(res => {
          this.kit.data.id = res.data.kit.id;
          this.kit.data.kit_no = res.data.kit.kit_no;
          this.kit.data.kit_name = res.data.kit.kit_name;
          this.kit.data.kit_name_2 = res.data.kit.kit_name_2;
          this.kit.data.part_no2 = res.data.kit.part_no2;
          this.kit.data.model_no2 = res.data.kit.model_no2;
          this.kit.data.remark = res.data.kit.remark;
          this.kit.data.order = res.data.kit.order;
          this.kit.data.components = res.data.kit.components.map(item => {
            item.parts = item.parts.map(item2 => {
              return {
                data: item2,
                options: [],
                loading: false,
              };
            });
            return {
              data: item,
              options: [],
              loading: false,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.kit.loading = false;
        });
      },
      onTriggerKitEdit() {
        //处理数据
        if (!this.showEdit) {
          this.updateKit = JSON.parse(JSON.stringify(this.kit));
          this.showType = 'updateKit';
        } else {
          this.showType = 'kit';
        }
        this.showEdit = !this.showEdit;
      },
      onUpdateKit() {
        //检查是不有空以及 TODO重复
        let kit = this.updateKit;
        if (this.checkEmpty(kit.data.kit_no, '修理包KIT NO.不能空') || this.checkEmpty(kit.data.kit_name, '修理包KIT NAME不能空') || this.checkEmpty(kit.data.kit_name_2, '修理包名称（中文）不能空') || this.checkEmpty(kit.data.part_no2, '修理包MODEL NO.不能空') || this.checkEmpty(kit.data.order, '修理包排序不能空')) {
          return;
        }
        for (let i = 0; i < kit.data.components.length; i++) {
          let component = kit.data.components[i].data;
          if (this.checkEmpty(component.index, '第' + (i + 1) + '个元件序号不能空') || this.checkEmpty(component.component_no, '第' + (i + 1) + '个元件编号不能空') || this.checkEmpty(component.component_name, '第' + (i + 1) + '个元件名称不能空') || this.checkEmpty(component.component_name_2, '第' + (i + 1) + '个元件名称（中文）不能空') || this.checkEmpty(component.count, '第' + (i + 1) + '个元件数量不能空')) {
            return;
          }
          for (let j = 0; j < component.parts.length; j++) {
            let part = component.parts[j].data;
            if (this.checkEmpty(part.order, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件排序不能空') || this.checkEmpty(part.index, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件序号不能空') || this.checkEmpty(part.part_no, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件原厂号码不能空') || this.checkEmpty(part.part_name, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件名称不能空') || this.checkEmpty(part.spec, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件规格不能空') || this.checkEmpty(part.count, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件数量不能空')) {
              return;
            }
          }
        }

        let params = {
          model_no: this.modelSelector.value,
          kit: JSON.parse(JSON.stringify(kit.data)),
        };
        params.kit.components = params.kit.components.map((item, index) => {
          //item.data.index = index + 1;
          item.data.parts = item.data.parts.map(item2 => item2.data);
          return item.data;
        })
        kit.submitting = true;
        updateKit(params).then(res => {
          Modal.success({
            title: '修理包 ' + params.kit.kit_no + ' 更新成功',
          });
          //清空修理包数据
          this.getKits();
          this.getKit({kit_no: params.kit.kit_no, model_no: this.modelSelector.value}).then(() => {
            this.updateKit = JSON.parse(JSON.stringify(this.kit));
          });
          this.getKitNames();
          this.getComponentNames();
        }).catch(err => {
          Modal.error({
            title: '更新修理包失败',
            content: err.message || '',
          });
        }).finally(() => {
          kit.submitting = false;
        });
      },
      onDeleteKit() {
        let kit = this.updateKit;
        let params = {
          model_no: this.modelSelector.value,
          kit_no: kit.data.kit_no,
        };
        let that = this;

        let deleteModal = Modal.confirm({
          title: '确定机型 ' + params.model_no + ' 要取消修理包 ' + params.kit_no + ' ？',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteKit(params).then(() => {
              Modal.success({
                title: '机型 ' + params.model_no + ' 取消修理包 ' + params.kit_no + ' 成功',
              });
              //清空修理包数据
              that.getKits();
              that.showType = null;
              that.showEdit = false;
            }).catch(err => {
              Modal.error({
                title: '取消修理包失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },

      getComponentImg2(params) {
        this.component.loading = true;
        getComponentImg2(params).then(res => {
          res.data.component.img2 = res.data.component.img2.map(item => {
            return config.staticURL + item;
          });
          this.component.data = res.data.component;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.component.loading = false;
        });
      },
      beforeUpload(file) {
        const isImg = ['image/apng', 'image/bmp', 'image/gif', 'image/jpeg', 'image/pjpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp', 'image/x-icon'].includes(file.type);
        if (!isImg) {
          Modal.warning({
            title: '上传提示',
            content: '只能上传图片文件',
          });
        }
        return isImg;
      },
      onUploadChange(info) {
        if (info.file.status === 'uploading') {
          this.component.upload.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          this.component.upload.loading = false;
          if (info.file.response) {
            if (info.file.response.status === 200) {
              Modal.success({
                title: '图片上传成功',
              });
            } else {
              Modal.error({
                title: '图片上传失败',
                content: info.file.response.message || '',
              });
            }
          }
          this.getComponentImg2({
            component_no: this.component.data.component_no,
            kit_model_id: this.component.data.kit_model_id
          });
          return;
        }
        if (info.file.status === 'error') {
          this.component.upload.loading = false;
          this.getComponentImg2({
            component_no: this.component.data.component_no,
            kit_model_id: this.component.data.kit_model_id
          });
          Modal.warning({
            title: '上传提示',
            content: '图片上传失败',
          });
        }

      },
      onDeleteImg2(index) {
        let that = this;
        let deleteImg2Modal = Modal.confirm({
          title: '确定要删除图片？',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteImg2Modal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteComponentImg2({
              component_no: that.component.data.component_no,
              kit_model_id: that.component.data.kit_model_id,
              img2_index: index
            }).then(() => {
              Modal.success({
                title: '图片删除成功',
              });
              that.getComponentImg2({
                component_no: that.component.data.component_no,
                kit_model_id: that.component.data.kit_model_id
              });
            }).catch(err => {
              Modal.error({
                title: '图片删除失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      onTriggerComponentEdit() {
        this.component.showEdit = !this.component.showEdit;
      },


      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },

      onDownloadKit() {
        //onload没有触发
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = config.baseURL + '/downloadKit?token=' + this.$store.getters.token + '&kit_no=' + this.kit.data.kit_no;
        if (iframe.attachEvent) {
          iframe.attachEvent("onload", function () {
            document.body.removeChild(iframe);
            console.log('aaa');
          });
        } else {
          iframe.onload = function () {
            document.body.removeChild(iframe);
            console.log('aaabbb');
          };
        }
        document.body.appendChild(iframe);
      },

      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      onKitNameSelect2(kit) {
        let op = this.kitNames.filter(item => {
          return item.kit_name === kit.data.kit_name;
        });
        if (op.length) {
          kit.data.kit_name_2 = op[0].kit_name_2;
        }
      },
      onComponentNameSelect2(component) {
        let op = this.componentNames.filter(item => {
          return item.component_name === component.data.component_name;
        });
        if (op.length) {
          component.data.component_name_2 = op[0].component_name_2;
        }
      },

      haha(record) {
        if (this.search_type === 'part') {
          if (record.data.part_no === this.search_key) {
            return 'haha';
          }
        }
        return null;
      },

      onShowModel2Img(model_no) {
        this.showModel2Img = true;
        this.getModel2Img({model_no: model_no});
      },
      getModel2Img(params) {
        this.model2.loading = true;
        getModel2Img(params).then(res => {
          res.data.model.img = res.data.model.img.map(item => {
            return config.staticURL + item;
          });
          this.model2.data = res.data.model;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.model2.loading = false;
        });
      },
      onHideModel2Img() {
        this.showModel2Img = false;
        this.model2.data = null;
        this.model2.loading = false;
        this.model2.showEdit = false;
      },
      onUploadChangeModel2(info) {
        if (info.file.status === 'uploading') {
          this.model2.upload.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          this.model2.upload.loading = false;
          if (info.file.response) {
            if (info.file.response.status === 200) {
              Modal.success({
                title: '图片上传成功',
              });
            } else {
              Modal.error({
                title: '图片上传失败',
                content: info.file.response.message || '',
              });
            }
          }
          this.getModel2Img({model_no: this.model2.data.model_no});
          return;
        }
        if (info.file.status === 'error') {
          this.component.upload.loading = false;
          this.getModel2Img({model_no: this.model2.data.model_no});
          Modal.warning({
            title: '上传提示',
            content: '图片上传失败',
          });
        }

      },
      onDeleteModel2Img(index) {
        let that = this;
        let deleteImg2Modal = Modal.confirm({
          title: '确定要删除图片？',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteImg2Modal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteModel2Img({
              model_no: that.model2.data.model_no,
              img_index: index
            }).then(() => {
              Modal.success({
                title: '图片删除成功',
              });
              that.getModel2Img({model_no: that.model2.data.model_no});
            }).catch(err => {
              Modal.error({
                title: '图片删除失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      onTriggerModel2Edit() {
        this.model2.showEdit = !this.model2.showEdit;
      },
      onModelSerialSearch(model) {
        let params = {
          serial: model.data.serial,
        };

        if (model.timer2) {
          clearTimeout(model.timer2);
        }

        model.timer2 = setTimeout(() => {
          model.options_loading2 = true;
          searchSerials(params).then(res => {
            if (model.data.serial !== params.serial) {
              return;
            }
            model.options2 = res.data.serials.map(item => {
              return {value: item.serial};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            model.options_loading2 = false;
          });
        }, 500);
      },

      onModelNickNameSearch(model) {
        let params = {
          nick_name: model.data.nick_name,
        };

        if (model.timer3) {
          clearTimeout(model.timer3);
        }

        model.timer3 = setTimeout(() => {
          model.options_loading3 = true;
          searchNickNames(params).then(res => {
            if (model.data.nick_name !== params.nick_name) {
              return;
            }
            model.options3 = res.data.nick_names.map(item => {
              return {value: item.nick_name};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            model.options_loading3 = false;
          });
        }, 500);
      },

    }
  }
</script>

<style>
  .haha {
    background-color: #ffaa00;
  }

  .model-kit-content .ant-table-tbody > tr.ant-table-row.haha:hover > td {
    background-color: #ffaa00;
  }

  .model-kit-header .ant-select-item.ant-select-item-group {
    color: red;
    font-size: 16px;
    font-weight: bolder;
  }
</style>

<style scoped>
  .model-kit-header {
    padding: 0 5px;
    background-color: rgba(100, 100, 100, 0.2);
    height: 48px;
    line-height: 48px;
  }

  .model-kit-sider {
    background-color: rgba(100, 100, 100, 0.1);
    width: 150px;
  }

  .model-kit-content {
    padding: 5px;
  }

  .delete-button {
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .delete-button:hover {
    color: #ff4d4f;
  }

  .my-label {
    display: inline-block;
    width: 120px;
    text-align: right;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }

</style>