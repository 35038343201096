<template>
  <div>
    <a-spin :spinning="kit.loading" style="width: 100%;">
      <div v-if="kit.data">
        <span class="my-label">适应机型：</span>
        <template v-for="item in kit.kitModel" :key="item.model_no">
          <a-button type="link" @click="goTo(item.brand_no,item.model_no)">{{item.model_no}}</a-button>
        </template>
        <br>
        <span class="my-label">KIT NO.：</span>
        <a-input :class="{'haha':search_type==='kit'&&search_key===kit.data.kit_no}" v-model:value="kit.data.kit_no" style="width:200px;" readonly/>
        <br>
        <span class="my-label">KIT NAME：</span>
        <a-input v-model:value="kit.data.kit_name" style="width:200px;" readonly/>
        <span class="my-label2">修理包名称（中文）：</span>
        <a-input v-model:value="kit.data.kit_name_2" style="width:200px;" readonly/>
        <br>
        <span class="my-label">MODEL NO.：</span>{{kit.kitModel.map(item=>item.part_no2).join(', ')}}
        <br>
        <span class="my-label">MODEL NAME：</span>{{kit.kitModel.map(item=>item.model_no2).join(', ')}}
        <br>

        <span class="my-label">成本价：</span>
        <a-input v-model:value="kit.data.price_1" style="width:200px;" readonly/>
        <span class="my-label2">批发价：</span>
        <a-input v-model:value="kit.data.price_2" style="width:200px;" readonly/>
        <br>
        <span class="my-label">零售价：</span>
        <a-input v-model:value="kit.data.price_3" style="width:200px;" readonly/>
        <span class="my-label2">特殊价：</span>
        <a-input v-model:value="kit.data.price_4" style="width:200px;" readonly/>
        <br>
        <span class="my-label">重量：</span>
        <a-input v-model:value="kit.data.weight" style="width:200px;" readonly/>
        <span class="my-label2">重量单位：</span>
        <a-input v-model:value="kit.data.weight_unit" style="width:200px;" readonly/>
        <br>
        <span class="my-label">规格：</span>
        <a-input v-model:value="kit.data.spec" style="width:200px;" readonly/>
        <span class="my-label2">排序：</span>
        <a-input v-model:value="kit.data.order" style="width:200px;" readonly/>
        <br>
        <span class="my-label">包装：</span>
        <template v-if="kit.data.box">
          <a-button :class="{lose:kit.data.box.price_1===null||kit.data.box.price_1===undefined||kit.data.box.weight===null||kit.data.box.weight===undefined}" type="link" @click="goTo2(kit.data.box.id)">
            {{kit.data.box.box_no}}
          </a-button>
          <span style="font-size:12px;color:#999;">（规格：{{kit.data.box.spec}}）</span>
        </template>
        <br>
        <span class="my-label">替换件：</span>
        <template v-for="item in kit.data.replacement" :key="item">
          <a-button type="link" @click="goTo3(item)">{{item}}</a-button>
        </template>
        <br>
        <template v-for="(component,c_index) in kit.data.components" :key="c_index">
          <a-divider style="background-color: #888;margin: 5px 0;"/>
          序号：<span style="display: inline-block;width:50px;">{{component.index}}</span>
          元件编号：<span style="display: inline-block;min-width:200px;" :class="{'haha':search_type==='component'&&search_key===component.component_no}">{{component.component_no}}</span>
          元件名称：<span style="display: inline-block;min-width:200px;">{{component.component_name}}</span>
          元件名称（中文）：<span style="display: inline-block;min-width:200px;">{{component.component_name_2}}</span>
          数量：<span style="display: inline-block;min-width:50px;">{{component.count}}</span>
          <br>

          <a-table :columns="kit.columns" :data-source="component.parts" bordered :pagination="false" size="small" :rowClassName="haha" :locale="{emptyText:'暂无数据'}">
            <template #bodyCell="{text, record, column}">
              <template v-if="column.key === 'part_no'">
                <a-button :class="{lose:record.price_1===null||record.price_1===undefined||record.weight===null||record.weight===undefined}" type="link" @click="goTo4(text)" style="padding:0;height: 22px;">
                  {{text}}
                </a-button>
              </template>
              <template v-else-if="column.key === 'count'">
                {{component.count>1&&0? record.count+' ('+(record.count*component.count)+')':record.count}}
              </template>
              <template v-else>
                {{text}}
              </template>
            </template>
          </a-table>
        </template>
        <div style="text-align: center;margin-top: 10px;margin-bottom: 5px;">
          <a-button type="primary" @click="goTo5(kit.data.kit_no)">图片</a-button>
          <a-button type="primary" @click="goReplace" v-if="_self&&_self.type==='admin'" style="margin-left: 20px;">
            可编辑
          </a-button>
          <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
        </div>
        <p style="color:red;">
          备注：若有包装或配件缺少成本价、重量，则无法自动计算。包装或配件缺少成本价或重量时背景色显示为红色。
        </p>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getKit2} from "@/api/kit";
  import {message} from 'ant-design-vue';

  export default {
    name: "kit-detail",
    data() {
      return {
        kit: {
          loading: false,
          data: null,
          kitModel: [],
          columns: [
            {
              title: '排序',
              dataIndex: 'order',
              width: '80px',
              key: 'order',
            },
            {
              title: '序号',
              dataIndex: 'index',
              width: '80px',
              key: 'index',
            },
            {
              title: '原厂号码',
              dataIndex: 'part_no',
              width: '20%',
              key: 'part_no',
            },
            {
              title: '名称',
              dataIndex: 'part_name',
              width: '20%',
              key: 'part_name',
            },
            {
              title: '规格',
              dataIndex: 'spec',
              width: '20%',
              key: 'spec',
            },
            {
              title: '数量',
              dataIndex: 'count',
              width: '80px',
              key: 'count',
            },
            {
              title: '用处备注',
              dataIndex: 'remark',
              //width:'15%',
              key: 'remark',
            },

          ],
        },
        search_key: '',
        search_type: '',
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({kit_no, search_key, search_type}) {
        let params = {kit_no: kit_no};
        this.getKit2(params);
        this.isGoBack = false;

        if (search_key) {
          this.search_key = search_key;
          this.search_type = search_type;
        }
      },
      getKit2(params) {
        this.kit.loading = true;
        getKit2(params).then(res => {
          this.kit.data = res.data.kit;
          this.kit.kitModel = res.data.kitModel;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.kit.loading = false;
        });
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/kit/update', query: {kit_no: this.kit.data.kit_no}})
      },
      goTo(brand_no, model_no) {
        this.$router.push({path: '/brand/model-kit?', query: {brand_no: brand_no, model_no: model_no}});
      },
      goTo2(box_id) {
        this.$router.push({path: '/box/detail?', query: {box_id: box_id}});
      },
      goTo3(kit_no) {
        this.$router.push({path: '/kit/detail?', query: {kit_no: kit_no}});
      },
      goTo4(part_no) {
        this.$router.push({path: '/part/detail?', query: {part_no: part_no}});
      },
      goTo5(kit_no) {
        this.$router.push({path: '/kit/img?', query: {kit_no: kit_no}});
      },

      haha(record) {
        if (this.search_type === 'part') {
          if (record.part_no === this.search_key) {
            return 'haha';
          }
        }
        return null;
      }
    },
  }
</script>

<style>
  .haha {
    background-color: #ffaa00;
  }

  .ant-table-tbody > tr.ant-table-row.haha:hover > td {
    background-color: #ffaa00;
  }
</style>

<style scoped>
  .my-label {
    display: inline-block;
    width: 120px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }

  .lose {
    background-color: pink;
  }

</style>