<template>
  <div>
    <a-spin :spinning="model.loading">
      <div v-if="model.data">
        <a-list class="models-list" :data-source="model.data.pdf" size="small" bordered>
          <template #renderItem="{item}">
            <a-list-item class="item">
              <template #actions>
                <a-button type="primary" @click="downloadPdf(item);" size="small">下载</a-button>
                <a-button type="primary" @click="downloadPdf2(item);" size="small">查看</a-button>
                <!--
                <a-button type="primary" @click="void(0)" size="small" v-if="_self&&_self.type==='admin'&&model.showEdit">
                  修改
                </a-button>
                -->

                <a-button danger type="primary" @click="onDeletePdf(item.id)" size="small" v-if="_self&&_self.type==='admin'&&model.showEdit">
                  删除
                </a-button>
              </template>
              <a-list-item-meta class="item-meta" :description="item.size">
                <template #title>
                  {{ item.file_name }}
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <div style="text-align: center;padding: 15px 0 5px">
        <a-upload
          v-if="model.data&&_self&&_self.type==='admin'"
          :showUploadList="false"
          v-model:file-list="model.upload.fileList"
          name="pdf"
          :multiple="false"
          accept="application/zip,text/plain,application/pdf,application/vnd.ms-powerpoint,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          :headers="model.upload.headers"
          :action="model.upload.action"
          :before-upload="beforeUpload"
          @change="onUploadChange"
          :data="{model_no:model.data.model_no}"
          :disabled="model.upload.loading"
        >
          <a-button type="dashed" :loading="model.upload.loading">
            <upload-outlined v-if="!model.upload.loading"></upload-outlined>
            上传文件
          </a-button>
        </a-upload>
        <a-button type="primary" @click="onTriggerModelEdit" style="margin-left: 20px;" v-if="_self&&_self.type==='admin'">{{model.showEdit? '仅查看':'可编辑'}}
        </a-button>
        <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
      </div>
      <div style="color:#aaa;font-size: 12px;padding:3px 0;">
        备注："查看"按键是指尝试用浏览器打开文件，当浏览器不支持打开该文件时，请下载到本地电脑后打开。
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getModelPdf, deleteModelPdf} from "@/api/model";
import config from '@/config.js';
import {message, Modal} from 'ant-design-vue';
import {
  UploadOutlined,
  //DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import {createVNode} from 'vue';

export default {
  name: "model-pdf",
  components: {
    UploadOutlined,
    //DeleteOutlined,
  },
  data() {
    return {
      model: {
        loading: false,
        data: null,
        upload: {
          loading: false,
          headers: {
            'X-Token': this.$store.getters.token,
          },
          fileList: [],
          action: config.baseURL + '/uploadModelPdf',
        },
        showEdit: false,
      },
    };
  },
  computed: {
    _self() {
      return this.$store.state.admin.self;
    },
    isGoBack: {
      get() {
        return this.$store.state.bus.isGoBack;
      },
      set(newValue) {
        this.$store.commit('bus/setIsGoBack', newValue);
      },
    },
  },
  created() {
    this.init(this.$route.query);
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.init(to.query);
      }
    }
  },
  methods: {
    init({model_no}) {
      this.getModelPdf({model_no: model_no});
      this.isGoBack = false;
    },
    getModelPdf(params) {
      this.model.loading = true;
      getModelPdf(params).then(res => {
        /**
        res.data.model.pdf = res.data.model.pdf.map(item => {
          return config.staticURL + item;
        });
         */
        this.model.data = res.data.model;
      }).catch(err => {
        message.error(err.message ? err.message : '未知错误', 5);
      }).finally(() => {
        this.model.loading = false;
      });
    },
    beforeUpload(file) {
      const isPdf = ['application/zip','text/plain','application/pdf','application/vnd.ms-powerpoint','.doc','.docx','.xml','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type);
      if (!isPdf) {
        Modal.warning({
          title: '上传提示',
          content: '只能上传pdf,doc,docx,txt等文本文件',
        });
      }
      return isPdf;
    },
    onUploadChange(info) {
      if (info.file.status === 'uploading') {
        this.model.upload.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.model.upload.loading = false;
        if (info.file.response) {
          if (info.file.response.status === 200) {
            Modal.success({
              title: '文件上传成功',
            });
          } else {
            Modal.error({
              title: '文件上传失败',
              content: info.file.response.message || '',
            });
          }
        }
        this.getModelPdf({model_no: this.model.data.model_no});
        return;
      }
      if (info.file.status === 'error') {
        this.model.upload.loading = false;
        this.getModelPdf({model_no: this.model.data.model_no});
        Modal.warning({
          title: '上传提示',
          content: '文件上传失败',
        });
      }

    },
    onDeletePdf(id) {
      let that = this;
      let deletePdfModal = Modal.confirm({
        title: '确定要删除文件？',
        icon: createVNode(ExclamationCircleOutlined),
        confirmLoading: false,
        onOk() {
          deletePdfModal.update({
            confirmLoading: true,
            cancelButtonProps: {disabled: true},
          });
          return deleteModelPdf({
            model_no: that.model.data.model_no,
            id: id,
          }).then(() => {
            Modal.success({
              title: '文件删除成功',
            });
            that.getModelPdf({model_no: that.model.data.model_no});
          }).catch(err => {
            Modal.error({
              title: '文件删除失败',
              content: err.message || '',
            });
          });
        },
        onCancel() {
        },
      });
    },
    onTriggerModelEdit() {
      this.model.showEdit = !this.model.showEdit;
    },
    goBack() {
      this.isGoBack = true;
      this.$router.back();
    },
    downloadPdf(item){
      //方式一
      window.open(config.baseURL+'/downloadModelPdf?token='+this.$store.getters.token+'&id='+item.id);

      //方式二
      //window.open(config.staticURL+item.store_name);
    },
    downloadPdf2(item){
      window.open(config.staticURL+item.store_name);
    }
  },
}
</script>

<style>
.models-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
}

.models-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
}
</style>
<style scoped>
.models-list {
    background-color: #fff;
}

.item:hover {
    background-color: #e6f7ff;
}

.item-meta {
    //cursor: pointer;
}

</style>