<template>
  <div>
    <a-spin :spinning="box.loading" style="width:100%;">
      <div v-if="box.data">
        <!--
        <span class="my-label">适应修理包：</span>
        <template v-for="item in box.boxKit" :key="item.kit_no">
          <a-button type="link" @click="goTo(item.kit_no)">{{item.kit_no}}</a-button>
        </template>
        <br>
        -->
        <span class="my-label">包装编号：</span>
        <a-input v-model:value="box.data.box_no" style="width:200px;" readonly/>
        <br>
        <span class="my-label">包装名称：</span>
        <a-input v-model:value="box.data.box_name" style="width:200px;" readonly/>
        <span class="my-label2">包装名称（中文）：</span>
        <a-input v-model:value="box.data.box_name_2" style="width:200px;" readonly/>
        <br>
        <span class="my-label">成本价：</span>
        <a-input v-model:value="box.data.price_1" style="width:200px;" readonly/>
        <span class="my-label2">规格：</span>
        <a-input v-model:value="box.data.spec" style="width:200px;" readonly/>
        <br>
        <span class="my-label">重量：</span>
        <a-input v-model:value="box.data.weight" style="width:200px;" readonly/>
        <span class="my-label2">重量单位：</span>
        <a-input v-model:value="box.data.weight_unit" style="width:200px;" readonly/>
        <br>

        <span class="my-label">排序：</span>
        <a-input v-model:value="box.data.order" style="width:200px;" readonly/>
        <br>

        <div style="text-align: center;margin-top: 10px;margin-bottom: 5px;">
          <a-button type="primary" @click="goTo2(box.data.id)">图片</a-button>
          <a-button type="primary" @click="goReplace" v-if="_self&&_self.type==='admin'" style="margin-left: 20px;">
            可编辑
          </a-button>
          <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getBox2} from "@/api/box";
  import {message} from 'ant-design-vue';

  export default {
    name: "box-detail",
    data() {
      return {
        box: {
          loading: false,
          data: null,
          boxKit: [],
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({box_id}) {
        let params = {box_id: box_id};
        this.getBox2(params);
        this.isGoBack = false;
      },
      getBox2(params) {
        this.box.loading = true;
        getBox2(params).then(res => {
          this.box.data = res.data.box;
          this.box.boxKit = res.data.boxKit;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.box.loading = false;
        });
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/box/update', query: {box_id: this.box.data.id}})
      },
      goTo(kit_no) {
        this.$router.push({path: '/kit/detail', query: {kit_no: kit_no}});
      },
      goTo2(box_id) {
        this.$router.push({path: '/box/img', query: {box_id: box_id}});
      },
    },
  }
</script>

<style scoped>
  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>