<template>
  <div>
    <div style="padding-bottom: 4px;">
      <a-auto-complete
        v-model:value="componentsList.search_key"
        :options="componentsList.options"
        style="width: 500px"
        placeholder="编号、名称"
        @search="onComponentNoSearch(componentsList)"
        :loading="componentsList.optionsLoading"
      >
        <template #option="item">
          <span v-html="item.label_html"></span>
        </template>
      </a-auto-complete>
      <a-button type="primary" @click="onSearchKey">搜索</a-button>
      <a-button style="float: right;" type="primary" @click="goTo(null,'add')" v-if="_self&&_self.type==='admin'">新增元件
      </a-button>
    </div>
    <a-list class="components-list" :data-source="componentsList.data" :loading="componentsList.loading" size="small" bordered :pagination="componentsList.pagination">
      <template #renderItem="{ item }">
        <a-list-item class="item">
          <template #actions>
            <a-button type="primary" @click="goTo(item.component_no,'img')" size="small">图片</a-button>
            <a-button type="primary" @click="goTo(item.component_no,'update')" size="small" v-if="_self&&_self.type==='admin'">
              修改
            </a-button>
            <a-button danger type="primary" @click="onDeleteComponent(item)" size="small" v-if="_self&&_self.type==='admin'">
              删除
            </a-button>
          </template>
          <a-list-item-meta class="item-meta" :description="'名称：'+item.component_name+'，中文名称：'+item.component_name_2" hover @click="goTo(item.component_no,'detail')">
            <template #title>
              {{ item.component_no }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>

  </div>
</template>

<script>
  import {getComponents2, deleteComponent2, searchComponents} from "@/api/component";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "component-index",
    data() {
      return {
        componentsList: {
          data: [],
          loading: false,
          search_key: '',
          options: [],
          options_loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        let params = {
          search_key: '',
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        this.getComponents2(params);
      },
      getComponents2(params) {
        this.componentsList.loading = true;
        getComponents2(params).then(res => {
          this.componentsList.data = res.data.components;
          this.componentsList.search_key = res.data.search_key;
          this.componentsList.pagination.current = res.data.current;
          this.componentsList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.componentsList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          search_key: this.componentsList.search_key,
          current: page,
        };
        this.getComponents2(params);
      },
      onSearchKey() {
        let params = {
          search_key: this.componentsList.search_key,
          current: 1,
        };
        this.getComponents2(params);
      },
      goTo(component_no, type) {
        this.params = {search_key: this.componentsList.search_key, current: this.componentsList.pagination.current};
        switch (type) {
          case 'detail':
            this.$router.push({path: '/component/detail', query: {component_no: component_no}});
            break;
          case 'update':
            this.$router.push({path: '/component/update', query: {component_no: component_no}});
            break;
          case 'img':
            this.$router.push({path: '/component/img', query: {component_no: component_no}});
            break;
          case 'add':
            this.$router.push({path: '/component/add'});
            break;
          default:
        }
      },
      onDeleteComponent(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除元件 ' + item.component_no + ' ？',
          content: '若有修理包拥有该元件，则修理包也会联动取消该元件！',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteComponent2({component_no: item.component_no}).then(() => {
              Modal.success({
                title: '元件 ' + item.component_no + '删除成功',
              });
              that.getComponents2({
                search_key: that.componentsList.search_key,
                current: that.componentsList.pagination.current
              });
            }).catch(err => {
              Modal.error({
                title: '删除元件' + item.component_no + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      onComponentNoSearch(list) {
        let params = {
          component_no: list.search_key,
        };

        if (list.timer) {
          clearTimeout(list.timer);
        }

        list.timer = setTimeout(() => {
          list.optionsLoading = true;
          searchComponents(params).then(res => {
            if (list.search_key !== params.component_no) {
              return;
            }
            let reg_key = new RegExp('(' + params.component_no + ')', "ig");
            list.options = res.data.components.map(item => {
              return {
                value: item.component_no,
                label: item.component_no + ' (' + item.component_name + ')',
                label_html: item.component_no.replace(reg_key, "<span class='search_key_html'>$1</span>") + ' (' + item.component_name.replace(reg_key, "<span class='search_key_html'>$1</span>") + ')'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            list.optionsLoading = false;
          });
        }, 500);
      }

    },

  }
</script>
<style>
  .components-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .components-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
  }
</style>
<style scoped>
  .components-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

</style>