import request from '@/utils/request.js';

export function getCaptcha() {
  return request.get('getCaptcha');
}

export function login(data) {
  return request.post('login', data);
}

//没用到
export function getSelf() {
  return request.get('getSelf');
}

export function logout() {
  return request.post('logout');
}

export function updatePwd(data) {
  return request.post('updatePwd', data);
}

export function addAdmin(data) {
  return request.post('addAdmin', data);
}

export function updateAdmin(data) {
  return request.post('resetPwd', data);
}

export function updateAdmin2(data) {
  return request.post('updateQx', data);
}

export function deleteAdmin(data) {
  return request.post('deleteAdmin', data);
}

export function getAdmins(data) {
  return request.get('getAdmins', data);
}