<template>
  <a-form @submit.prevent="onUpdate">
    <a-form-item label="旧密码" name="pwd">
      <a-input-password v-model:value="pwd" placeholder="请输入旧密码" style="width: 200px;"/>
    </a-form-item>
    <a-form-item label="新密码" name="newPwd">
      <a-input-password v-model:value="newPwd" placeholder="请输入新密码" style="width:200px;"/>
      <div style="color:red;font-size: 12px;">密码不能有空格，6-16位，密码至少包含一个数字和一个字母</div>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="updating">修改</a-button>
      <a-button type="primary" @click="onReset">重置</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
  import {cloneDeep} from "@/utils/util.js";
  import {updatePwd} from "@/api/admin.js";
  import {Modal} from 'ant-design-vue';

  const initConfig = {
    pwd: '',
    newPwd: '',
    updating: false,
  };

  export default {
    name: "xgmm",
    data() {
      return {
        pwd: initConfig.pwd,
        newPwd: initConfig.newPwd,
        updating: initConfig.updating,
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        this.pwd = cloneDeep(initConfig.pwd);
        this.newPwd = cloneDeep(initConfig.newPwd);
        this.updating = cloneDeep(initConfig.updating);
        this.isGoBack = false;
      },
      onReset() {
        this.pwd = '';
        this.newPwd = '';
      },
      onUpdate() {
        if (!this.pwd) {
          Modal.warning({
            title: '旧密码不能为空',
          });
          return;
        }
        if (!this.newPwd) {
          Modal.warning({
            title: '新密码不能为空',
          });
          return;
        }
        if (this.newPwd.includes(' ')) {
          Modal.warning({
            title: '新密码不能有空格',
          });
          return;
        }
        if (this.newPwd.length < 6 || this.newPwd.length > 17) {
          Modal.warning({
            title: '新密码长度必须为6-16位',
          });
          return;
        }
        if (!(/[A-Za-z]/.test(this.newPwd) && /[0-9]/.test(this.newPwd))) {
          Modal.warning({
            title: '至少包含一个数字和一个字母',
          });
          return;
        }

        this.updating = true;
        updatePwd({pwd: this.pwd, newPwd: this.newPwd}).then(res => {
          Modal.success({
            title: '修改密码成功',
          });
          this.init();
        }).catch(err => {
          Modal.error({
            title: '删除密码失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.updating = false;
        });
      },
    }

  }
</script>

<style scoped>

</style>