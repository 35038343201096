<template>
  <div>
    <a-spin :spinning="updateComponent.loading">
      <span class="my-label">元件编号：</span>
      <a-input v-model:value="updateComponent.data.component_no" style="width:200px;" placeholder="元件编号"/>
      <br>
      <span class="my-label">元件名称：</span>
      <a-auto-complete
        v-model:value="updateComponent.data.component_name"
        :options="componentNames2"
        style="width:200px;"
        placeholder="元件名称"
        @select="onComponentNameSelect2(updateComponent)"
        :filter-option="filterOption"
      />
      <span class="my-label2">元件名称（中文）：</span>
      <a-input v-model:value="updateComponent.data.component_name_2" placeholder="元件名称（中文）" style="width:200px;"/>
      <br>

      <span class="my-label">成本价：</span>
      <a-input-number v-model:value="updateComponent.data.price_1" style="width:200px;" placeholder="保存后自动计算成本价" disabled/>
      <span class="my-label2">批发价：</span>
      <a-input-number v-model:value="updateComponent.data.price_2" style="width:200px;" placeholder="批发价"/>
      <br>
      <span class="my-label">零售价：</span>
      <a-input-number v-model:value="updateComponent.data.price_3" style="width:200px;" placeholder="零售价"/>
      <span class="my-label2">特殊价：</span>
      <a-input-number v-model:value="updateComponent.data.price_4" style="width:200px;" placeholder="特殊价"/>
      <br>
      <span class="my-label">重量：</span>
      <a-input-number v-model:value="updateComponent.data.weight" style="width:200px;" placeholder="保存后自动计算重量" disabled/>
      <span class="my-label2">重量单位：</span>
      <a-input v-model:value="updateComponent.data.weight_unit" style="width:200px;" placeholder="重量单位" disabled/>
      <br>
      <span class="my-label">规格：</span>
      <a-input v-model:value="updateComponent.data.spec" style="width:200px;" placeholder="规格"/>

      <span class="my-label2">排序：</span>
      <a-input-number v-model:value="updateComponent.data.order" style="width:200px;" placeholder="排序"/>
      <br>

      <span class="my-label">包装：</span>
      <a-select
        show-search
        v-model:value="updateComponent.data.box_no"
        :options="boxSelector.options"
        style="width: 200px"
        placeholder="选择包装"
        :loading="boxSelector.loading"
        allowClear
      ></a-select>
      <span style="font-size:12px;color:#999;" v-if="updateComponent.data.box_no">
      （规格：{{boxes[updateComponent.data.box_no]&&boxes[updateComponent.data.box_no].spec}}）
      </span>
      <br>

      <a-table :columns="columns" :data-source="updateComponent.data.parts" bordered :pagination="false" size="small">
        <template #bodyCell="{record,index,column}">
          <template v-if="!['part_no','operation'].includes(column.dataIndex)">
            <a-input v-model:value="record.data[column.dataIndex]"/>
          </template>
          <template v-else-if="column.dataIndex==='part_no'">
            <a-auto-complete
              v-model:value="record.data[column.dataIndex]"
              :options="record.options"
              style="width: 100%"
              @search="onPartNameSearch(record)"
              @select="onPartNameSelect(record)"
              :loading="record.loading"
            />
          </template>
          <template v-else>
            <MinusCircleOutlined title="取消配件" class="delete-button" @click="onRemovePart(updateComponent,index)"/>
          </template>
        </template>
      </a-table>
      <div style="margin-top: 5px;">
        <a-button type="dashed" @click="onAddPart(updateComponent)">
          <PlusOutlined/>
          添加配件
        </a-button>
      </div>

      <div style="text-align: center;margin-top: 10px;">
        <a-button type="primary" @click="onUpdateComponent" :loading="updateComponent.submitting">
          更新
        </a-button>
        <a-button type="primary" @click="resetUpdateComponent" style="margin-left: 20px;">
          重填
        </a-button>
        <a-button type="primary" @click="goReplace" style="margin-left: 20px;">仅查看</a-button>
        <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
      </div>
      <p style="color:red;">
        备注：保存后会自动计算成本价、重量。(若有包装或配件缺少成本价、重量，则无法自动计算)
      </p>
    </a-spin>
  </div>
</template>

<script>
  import {getBoxes} from "@/api/box";
  import {getComponent2, updateComponent2, getComponentNames} from "@/api/component";
  import {cloneDeep} from "@/utils/util";
  import {searchParts, getPart} from "@/api/part";
  import {message, Modal} from 'ant-design-vue';
  import {
    MinusCircleOutlined,
    PlusOutlined,
  } from '@ant-design/icons-vue';

  export default {
    name: "component-update",
    components: {
      MinusCircleOutlined,
      PlusOutlined,
    },
    data() {
      return {
        boxes: {},

        boxSelector: {
          options: [],
          loading: false,
        },

        component: null,
        updateComponent: {
          loading: false,
          options: [],
          data: {
            id: '',
            component_no: '',
            component_name: '',
            component_name_2: '',
            price_1: null,
            price_2: null,
            price_3: null,
            price_4: null,
            weight: null,
            weight_unit: '',
            order: null,
            box_no: null,
            parts: [
              {
                data: {
                  order: '',
                  index: '',
                  part_no: '',
                  part_name: '',
                  spec: '',
                  count: '',
                  remark: '',
                },
                options: [],
                loading: false,
              }
            ],
          },
          submitting: false,
        },
        columns: [
          {
            title: '排序',
            dataIndex: 'order',
            width: '80px',
          },
          {
            title: '序号',
            dataIndex: 'index',
            width: '80px',
          },
          {
            title: '原厂号码',
            dataIndex: 'part_no',
            width: '20%',
          },
          {
            title: '名称',
            dataIndex: 'part_name',
            width: '20%',
          },
          {
            title: '规格',
            dataIndex: 'spec',
            width: '20%',
          },
          {
            title: '数量',
            dataIndex: 'count',
            width: '80px',
          },
          {
            title: '用处备注',
            dataIndex: 'remark',
            //width:'15%',
          },
          {
            title: '取消',
            dataIndex: 'operation',
            width: '45px',
          }
        ],

        componentNames: [],
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },

      componentNames2() {
        return this.componentNames.map(item => {
          return {value: item.component_name, label: item.component_name, component_name_2: item.component_name_2};
        })
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({component_no}) {
        this.getBoxes();

        this.getComponent2({component_no: component_no});
        this.isGoBack = false;

        this.getComponentNames();
      },

      getBoxes() {
        this.boxSelector.loading = true;
        getBoxes().then(res => {
          res.data.boxes.forEach(item => {
            this.boxes[item.box_no] = item;
          });
          this.boxSelector.options = res.data.boxes.map(item => {
            return {
              label: item.box_no,
              value: item.box_no,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.boxSelector.loading = false;
        });
      },

      getComponent2(params) {
        this.updateComponent.loading = true;
        getComponent2(params).then(res => {
          this.component = JSON.parse(JSON.stringify(res.data.component));

          this.updateComponent.data.id = res.data.component.id;
          this.updateComponent.data.component_no = res.data.component.component_no;
          this.updateComponent.data.component_name = res.data.component.component_name;
          this.updateComponent.data.component_name_2 = res.data.component.component_name_2;
          this.updateComponent.data.price_1 = res.data.component.price_1;
          this.updateComponent.data.price_2 = res.data.component.price_2;
          this.updateComponent.data.price_3 = res.data.component.price_3;
          this.updateComponent.data.price_4 = res.data.component.price_4;
          this.updateComponent.data.weight = res.data.component.weight;
          this.updateComponent.data.weight_unit = res.data.component.weight_unit;
          this.updateComponent.data.spec = res.data.component.spec;
          this.updateComponent.data.order = res.data.component.order;
          this.updateComponent.data.box_no = res.data.component.box && res.data.component.box.box_no;

          this.updateComponent.data.parts = res.data.component.parts.map(item => {
            return {
              data: item,
              options: [],
              loading: false,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updateComponent.loading = false;
        });
      },
      getComponentNames() {
        getComponentNames().then(res => {
          this.componentNames = res.data.component_names;
        }).catch(err => {

        }).finally(() => {

        });
      },
      filterOption(input, option) {
        return input ? option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0 : true;
      },

      onPartNameSearch(part) {
        let params = {
          part_no: part.data.part_no,
        };

        if (part.timer) {
          clearTimeout(part.timer);
        }

        part.timer = setTimeout(() => {
          part.loading = true;
          searchParts(params).then(res => {
            if (part.data.part_no !== params.part_no) {
              return;
            }
            part.options = res.data.parts.map(item => {
              return {value: item.part_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            part.loading = false;
          });
        }, 500);
      },
      onPartNameSelect(part) {
        let params = {
          part_no: part.data.part_no,
        };
        part.loading = true;
        getPart(params).then(res => {
          part.data.part_no = res.data.part.part_no;
          part.data.part_name = res.data.part.part_name;
          part.data.spec = res.data.part.spec;
          //part.data.remark = res.data.part.remark;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          part.loading = false;
        });
      },
      onAddPart(component) {
        component.data.parts.push({
          data: {
            order:'',
            index: '',
            part_no: '',
            part_name: '',
            spec: '',
            count: '',
            remark: '',
          },
          options: [],
          loading: false,
        });
      },
      onRemovePart(component, p_index) {
        component.data.parts.splice(p_index, 1);
      },
      onUpdateComponent() {
        //检查是不有空以及 TODO重复
        let component = this.updateComponent;
        if (this.checkEmpty(component.data.component_no, '元件编号不能空') || this.checkEmpty(component.data.component_name, '元件名称不能空') || this.checkEmpty(component.data.component_name_2, '元件名称（中文）不能空') || this.checkEmpty(component.data.order, '元件排序不能空')) {
          return;
        }
        for (let i = 0; i < component.data.parts.length; i++) {
          let part = component.data.parts[i].data;
          if (this.checkEmpty(part.order, '第' + (i + 1) + '个配件排序不能空') || this.checkEmpty(part.index, '第' + (i + 1) + '个配件序号不能空') || this.checkEmpty(part.part_no, '第' + (i + 1) + '个配件原厂号码不能空') || this.checkEmpty(part.part_name, '第' + (i + 1) + '个配件名称不能空') || this.checkEmpty(part.spec, '第' + (i + 1) + '个配件规格不能空') || this.checkEmpty(part.count, '第' + (i + 1) + '个配件数量不能空')) {
            return;
          }
        }

        let params = {
          component: JSON.parse(JSON.stringify(component.data)),
        };
        params.component.parts = params.component.parts.map((item) => {
          return item.data;
        })
        component.submitting = true;
        updateComponent2(params).then(res => {
          Modal.success({
            title: '元件 ' + params.component.component_no + ' 更新成功',
          });
          //清空修理包数据
          this.getComponent2({component_no: this.updateComponent.data.component_no});
        }).catch(err => {
          Modal.error({
            title: '更新元件失败',
            content: err.message || '',
          });
        }).finally(() => {
          component.submitting = false;
        });
      },
      resetUpdateComponent() {
        let component = JSON.parse(JSON.stringify(this.component));

        this.updateComponent.data.component_no = component.component_no;
        this.updateComponent.data.component_name = component.component_name;
        this.updateComponent.data.component_name_2 = component.component_name_2;
        this.updateComponent.data.price_1 = component.price_1;
        this.updateComponent.data.price_2 = component.price_2;
        this.updateComponent.data.price_3 = component.price_3;
        this.updateComponent.data.price_4 = component.price_4;
        this.updateComponent.data.weight = component.weight;
        this.updateComponent.data.weight_unit = component.weight_unit;
        this.updateComponent.data.spec = component.spec;
        this.updateComponent.data.order = component.order;
        this.updateComponent.data.box_no = component.box && component.box.box_no;

        this.updateComponent.data.parts = component.parts.map(item => {
          return {
            data: item,
            options: [],
            loading: false,
          };
        });
      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/component/detail', query: {component_no: this.component.component_no}});
      },

      onComponentNameSelect2(component) {
        let op = this.componentNames.filter(item => {
          return item.component_name === component.data.component_name;
        });
        if (op.length) {
          component.data.component_name_2 = op[0].component_name_2;
        }
      },
    }
  }
</script>

<style scoped>
  .delete-button {
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .delete-button:hover {
    color: #ff4d4f;
  }

  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>