//管理员管理

import Layout from '@/layout';

const searchRouter = {
  path: '/search',
  name: 'search',
  redirect: '/search/index',
  component: Layout,
  meta: {title: '搜索'},
  children: [
    {
      path: 'index',
      name: 'search-index',
      component: () => import('@/views/search'),
      meta: {title: '搜索'},
    },
    {
      path: 'kit',
      name: 'search-kit',
      component: () => import('@/views/search/kit'),
      meta: {title: '搜索修理包'},
    },
    {
      path: 'component',
      name: 'search-component',
      component: () => import('@/views/search/component'),
      meta: {title: '搜索元件'},
    },
    {
      path: 'part',
      name: 'search-part',
      component: () => import('@/views/search/part'),
      meta: {title: '搜索配件'},
    },
  ],
};
export default searchRouter;