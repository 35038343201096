<template>
  <div>
    <a-spin :spinning="component.loading" style="width: 100%;">
      <div v-if="component.data">
        <span class="my-label">适应修理包：</span>
        <template v-for="item in component.componentKit" :key="item.kit_no">
          <a-button type="link" @click="goTo(item.kit_no)">{{item.kit_no}}</a-button>
        </template>
        <br>
        <span class="my-label">元件编号：</span>
        <a-input :class="{'haha':search_type==='component'&&search_key===component.data.component_no}" v-model:value="component.data.component_no" style="width:200px;" readonly/>
        <br>
        <span class="my-label">元件名称：</span>
        <a-input v-model:value="component.data.component_name" style="width:200px;" readonly/>
        <span class="my-label2">元件名称（中文）：</span>
        <a-input v-model:value="component.data.component_name_2" style="width:200px;" readonly/>
        <br>

        <span class="my-label">成本价：</span>
        <a-input v-model:value="component.data.price_1" style="width:200px;" readonly/>
        <span class="my-label2">批发价：</span>
        <a-input v-model:value="component.data.price_2" style="width:200px;" readonly/>
        <br>
        <span class="my-label">零售价：</span>
        <a-input v-model:value="component.data.price_3" style="width:200px;" readonly/>
        <span class="my-label2">特殊价：</span>
        <a-input v-model:value="component.data.price_4" style="width:200px;" readonly/>
        <br>
        <span class="my-label">重量：</span>
        <a-input v-model:value="component.data.weight" style="width:200px;" readonly/>
        <span class="my-label2">重量单位：</span>
        <a-input v-model:value="component.data.weight_unit" style="width:200px;" readonly/>
        <br>
        <span class="my-label">规格：</span>
        <a-input v-model:value="component.data.spec" style="width:200px;" readonly/>

        <span class="my-label2">排序：</span>
        <a-input v-model:value="component.data.order" style="width:200px;" readonly/>
        <br>
        <span class="my-label">包装：</span>
        <template v-if="component.data.box">
          <a-button :class="{lose:component.data.box.price_1===null||component.data.box.price_1===undefined||component.data.box.weight===null||component.data.box.weight===undefined}" type="link" @click="goTo1(component.data.box.id)">
            {{component.data.box.box_no}}
          </a-button>
          <span style="font-size:12px;color:#999;">（规格：{{component.data.box.spec}}）</span>
        </template>
        <br>

        <span class="my-label">替换件：</span>
        <template v-for="item in component.data.replacement" :key="item">
          <a-button type="link" @click="goTo2(item)">{{item}}</a-button>
        </template>
        <br>

        <a-table :columns="component.columns" :data-source="component.data.parts" bordered :pagination="false" size="small" :rowClassName="haha" :locale="{emptyText:'暂无数据'}">
          <template #bodyCell="{text, record, column}">
            <template v-if="column.key === 'part_no'">
              <a-button :class="{lose:record.price_1===null||record.price_1===undefined||record.weight===null||record.weight===undefined}" type="link" @click="goTo4(text)" style="padding:0;height: 22px;">
                {{text}}
              </a-button>
            </template>
            <template v-else>
              {{text}}
            </template>
          </template>
        </a-table>

        <div style="text-align: center;margin-top: 10px;margin-bottom: 5px;">
          <a-button type="primary" @click="goTo3(component.data.component_no)">图片</a-button>
          <a-button type="primary" @click="goReplace" v-if="_self&&_self.type==='admin'" style="margin-left: 20px;">
            可编辑
          </a-button>
          <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
        </div>
        <p style="color:red;">
          备注：若有包装或配件缺少成本价、重量，则无法自动计算。包装或配件缺少成本价或重量时背景色显示为红色。
        </p>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getComponent2} from "@/api/component";
  import {message} from 'ant-design-vue';

  export default {
    name: "component-detail",
    data() {
      return {
        component: {
          loading: false,
          data: null,
          componentKit: [],
          columns: [
            {
              title: '排序',
              dataIndex: 'order',
              width: '80px',
              key: 'order',
            },
            {
              title: '序号',
              dataIndex: 'index',
              width: '80px',
              key: 'index',
            },
            {
              title: '原厂号码',
              dataIndex: 'part_no',
              width: '20%',
              key: 'part_no',
            },
            {
              title: '名称',
              dataIndex: 'part_name',
              width: '20%',
              key: 'part_name',
            },
            {
              title: '规格',
              dataIndex: 'spec',
              width: '20%',
              key: 'spec',
            },
            {
              title: '数量',
              dataIndex: 'count',
              width: '80px',
              key: 'count',
            },
            {
              title: '用处备注',
              dataIndex: 'remark',
              //width:'15%',
              key: 'remark',
            },

          ],
        },

        search_key: '',
        search_type: '',
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({component_no, search_key, search_type}) {
        let params = {component_no: component_no};
        this.getComponent2(params);
        this.isGoBack = false;

        if (search_key) {
          this.search_key = search_key;
          this.search_type = search_type;
        }
      },
      getComponent2(params) {
        this.component.loading = true;
        getComponent2(params).then(res => {
          this.component.data = res.data.component;
          this.component.componentKit = res.data.componentKit;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.component.loading = false;
        });
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({
          'path': '/component/update',
          query: {component_no: this.component.data.component_no}
        });
      },
      goTo(kit_no) {
        this.$router.push({path: '/kit/detail', query: {kit_no: kit_no}});
      },
      goTo1(box_id) {
        this.$router.push({path: '/box/detail?', query: {box_id: box_id}});
      },
      goTo3(component_no) {
        this.$router.push({path: '/component/img', query: {component_no: component_no}});
      },
      goTo2(component_no) {
        this.$router.push({path: '/component/detail', query: {component_no: component_no}});
      },
      goTo4(part_no) {
        this.$router.push({path: '/part/detail?', query: {part_no: part_no}});
      },

      haha(record) {
        if (this.search_type === 'part') {
          if (record.part_no === this.search_key) {
            return 'haha';
          }
        }
        return null;
      }
    },
  }
</script>
<style>
  .haha {
    background-color: #ffaa00;
  }

  .ant-table-tbody > tr.ant-table-row.haha:hover > td {
    background-color: #ffaa00;
  }
</style>
<style scoped>
  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }

  .lose {
      background-color: pink;
  }

</style>