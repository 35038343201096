import request from '@/utils/request.js';

export function getBrands() {
  return request.get('getBrands');
}

export function addBrand(data) {
  return request.post('addBrand', data);
}

export function updateBrand(data) {
  return request.post('updateBrand', data);
}

export function deleteBrand(data) {
  return request.post('deleteBrand', data);
}

export function searchBrands(data) {
  return request.get('searchBrands', data);
}