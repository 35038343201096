<template>
  <div>
    <div style="padding-bottom: 4px;">
      <a-auto-complete
        v-model:value="kitsList.search_key"
        :options="kitsList.options"
        style="width: 500px"
        placeholder="编号、名称"
        @search="onKitNoSearch(kitsList)"
        :loading="kitsList.optionsLoading"
      >
        <template #option="item">
          <span v-html="item.label_html"></span>
        </template>
      </a-auto-complete>
      <a-button type="primary" @click="onSearchKey">搜索</a-button>
      <a-button style="float: right;" type="primary" @click="goTo(null,'add')" v-if="_self&&_self.type==='admin'">
        新增修理包
      </a-button>
    </div>
    <a-list class="kits-list" :data-source="kitsList.data" :loading="kitsList.loading" size="small" bordered :pagination="kitsList.pagination">
      <template #renderItem="{ item }">
        <a-list-item class="item">
          <template #actions>
            <a-button type="primary" @click="goTo(item.kit_no,'img')" size="small">图片</a-button>
            <a-button type="primary" @click="goTo(item.kit_no,'update')" size="small" v-if="_self&&_self.type==='admin'">
              修改
            </a-button>
            <a-button danger type="primary" @click="onDeleteKit(item)" size="small" v-if="_self&&_self.type==='admin'">
              删除
            </a-button>
          </template>
          <a-list-item-meta class="item-meta" :description="'名称：'+item.kit_name+(item.kit_name_2? '，中文名称：'+item.kit_name_2:'')+(item.spec? '，规格：'+item.spec:'')" hover @click="goTo(item.kit_no,'detail')">
            <template #title>
              {{ item.kit_no }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>

  </div>
</template>

<script>
  import {getKits2, deleteKit2, searchKits} from "@/api/kit";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "kit",
    data() {
      return {
        kitsList: {
          data: [],
          loading: false,
          search_key: '',
          options: [],
          options_loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
    },

    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        let params = {
          search_key: '',
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        this.getKits2(params);
      },
      getKits2(params) {
        this.kitsList.loading = true;
        getKits2(params).then(res => {
          this.kitsList.data = res.data.kits;
          this.kitsList.search_key = res.data.search_key;
          this.kitsList.pagination.current = res.data.current;
          this.kitsList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.kitsList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          search_key: this.kitsList.search_key,
          current: page,
        };
        this.getKits2(params);
      },
      onSearchKey() {
        let params = {
          search_key: this.kitsList.search_key,
          current: 1,
        };
        this.getKits2(params);
      },
      goTo(kit_no, type) {
        this.params = {search_key: this.kitsList.search_key, current: this.kitsList.pagination.current};
        switch (type) {
          case 'detail':
            this.$router.push({path: '/kit/detail', query: {kit_no: kit_no}});
            break;
          case 'update':
            this.$router.push({path: '/kit/update', query: {kit_no: kit_no}});
            break;
          case 'img':
            this.$router.push({path: '/kit/img', query: {kit_no: kit_no}});
            break;
          case 'add':
            this.$router.push({path: '/kit/add'});
            break;
          default:
        }
      },
      onDeleteKit(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除修理包 ' + item.kit_no + ' ？',
          content: '若有机型拥有该修理包，则机型也会联动取消该修理包！',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteKit2({kit_no: item.kit_no}).then(() => {
              Modal.success({
                title: '修理包' + item.kit_no + '删除成功',
              });
              that.getKits2({search_key: that.kitsList.search_key, current: that.kitsList.pagination.current});
            }).catch(err => {
              Modal.error({
                title: '删除修理包' + item.kit_no + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      onKitNoSearch(list) {
        let params = {
          kit_no: list.search_key,
        };

        if (list.timer) {
          clearTimeout(list.timer);
        }

        list.timer = setTimeout(() => {
          list.optionsLoading = true;
          searchKits(params).then(res => {
            if (list.search_key !== params.kit_no) {
              return;
            }
            let reg_key = new RegExp('(' + params.kit_no + ')', "ig");
            list.options = res.data.kits.map(item => {
              return {
                value: item.kit_no,
                label: item.kit_no + ' (' + item.kit_name + ')',
                label_html: item.kit_no.replace(reg_key, "<span class='search_key_html'>$1</span>") + ' (' + item.kit_name.replace(reg_key, "<span class='search_key_html'>$1</span>") + ')'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            list.optionsLoading = false;
          });
        }, 500);
      }

    },

  }
</script>
<style>
  .kits-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .kits-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
  }
</style>
<style scoped>
  .kits-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

</style>