//品牌管理

import Layout from '@/layout';

const brandRouter = {
  path: '/brand',
  name: 'brand',
  redirect: '/brand/index',
  component: Layout,
  meta: {title: '品牌管理'},
  children: [
    {
      path: 'index',
      name: 'brand-index',
      component: () => import('@/views/brand'),
      meta: {title: '品牌管理'},
    },
    {
      path: 'model-kit',
      name: 'brand-model-kit',
      component: () => import('@/views/brand/model-kit'),
      meta: {title: '机型下的修理包'},
    }
  ],
};
export default brandRouter;