<template>
  <div>
    <a-spin :spinning="part.loading" style="width:100%;">
      <div v-if="part.data">
        <span class="my-label">适应元件：</span>
        <template v-for="item in part.partComponent" :key="item.component_no">
          <a-button type="link" @click="goTo(item.component_no)">{{item.component_no}}</a-button>
        </template>
        <br>
        <span class="my-label">原厂号码：</span>
        <a-input :class="{'haha':search_type==='part'&&search_key===part.data.part_no}" v-model:value="part.data.part_no" style="width:200px;" readonly/>
        <br>
        <span class="my-label">配件名称：</span>
        <a-input v-model:value="part.data.part_name" style="width:200px;" readonly/>
        <span class="my-label2">配件名称（中文）：</span>
        <a-input v-model:value="part.data.part_name_2" style="width:200px;" readonly/>
        <br>
        <span class="my-label">成本价：</span>
        <a-input v-model:value="part.data.price_1" style="width:200px;" readonly/>
        <span class="my-label2">批发价：</span>
        <a-input v-model:value="part.data.price_2" style="width:200px;" readonly/>
        <br>
        <span class="my-label">零售价：</span>
        <a-input v-model:value="part.data.price_3" style="width:200px;" readonly/>
        <span class="my-label2">特殊价：</span>
        <a-input v-model:value="part.data.price_4" style="width:200px;" readonly/>
        <br>
        <span class="my-label">重量：</span>
        <a-input v-model:value="part.data.weight" style="width:200px;" readonly/>
        <span class="my-label2">重量单位：</span>
        <a-input v-model:value="part.data.weight_unit" style="width:200px;" readonly/>
        <br>
        <span class="my-label">规格：</span>
        <a-input v-model:value="part.data.spec" style="width:200px;" readonly/>
        <span class="my-label2">排序：</span>
        <a-input v-model:value="part.data.order" style="width:200px;" readonly/>
        <br>
        <span class="my-label">材质：</span>
        <a-input v-model:value="part.data.material" style="width:200px;" readonly/>
        <span class="my-label2">备注：</span>
        <a-input v-model:value="part.data.remark" style="width:200px;" readonly/>
        <br>
        <span class="my-label">描述：</span>
        <a-textarea v-model:value="part.data.descr" style="width:550px;" readonly/>
        <br>
        <span class="my-label">替换件：</span>
        <template v-for="item in part.data.replacement" :key="item">
          <a-button type="link" @click="goTo2(item)">{{item}}</a-button>
        </template>
        <br>
        <div style="text-align: center;margin-top: 10px;margin-bottom: 5px;">
          <a-button type="primary" @click="goTo3(part.data.part_no)">图片</a-button>
          <a-button type="primary" @click="goReplace" v-if="_self&&_self.type==='admin'" style="margin-left:20px;">可编辑
          </a-button>
          <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getPart2} from "@/api/part";
  import {message} from 'ant-design-vue';

  export default {
    name: "part-detail",
    data() {
      return {
        part: {
          loading: false,
          data: null,
          partComponent: [],
        },

        search_key: '',
        search_type: '',
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({part_no, search_key, search_type}) {
        let params = {part_no: part_no};
        this.getPart2(params);
        this.isGoBack = false;

        if (search_key) {
          this.search_key = search_key;
          this.search_type = search_type;
        }
      },
      getPart2(params) {
        this.part.loading = true;
        getPart2(params).then(res => {
          this.part.data = res.data.part;
          this.part.partComponent = res.data.partComponent;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.part.loading = false;
        });
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/part/update', query: {part_no: this.part.data.part_no}})
      },
      goTo(component_no) {
        this.$router.push({path: '/component/detail', query: {component_no: component_no}});
      },
      goTo2(part_no) {
        this.$router.push({path: '/part/detail', query: {part_no: part_no}});
      },
      goTo3(part_no) {
        this.$router.push({path: '/part/img', query: {part_no: part_no}});
      }
    },
  }
</script>

<style>
  .haha {
    background-color: #ffaa00;
  }
</style>
<style scoped>
  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>