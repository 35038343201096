const state = {
  model_key: '',
  search_key: '',
};

const mutations = {
  setModelKey(state, model_key) {
    state.model_key = model_key;
  },
  setSearchKey(state, search_key) {
    state.search_key = search_key;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};