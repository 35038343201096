import request from '@/utils/request.js';

export function getKits(data) {
  return request.get('getKits', data);
}

export function addKit(data) {
  return request.post('addKit', data);
}

export function updateKit(data) {
  return request.post('updateKit', data);
}

export function searchKits(data) {
  return request.get('searchKits', data);
}

export function getKit(data) {
  return request.get('getKit', data);
}

export function deleteKit(data) {
  return request.post('deleteKit', data);
}

export function getKitNames() {
  return request.get('getKitNames');
}

//-----------
export function getKits2(data) {
  return request.get('getKits2', data);
}

export function getKit2(data) {
  return request.get('getKit2', data);
}

export function deleteKit2(data) {
  return request.post('deleteKit2', data);
}

export function updateKit2(data) {
  return request.post('updateKit2', data);
}

export function addKit2(data) {
  return request.post('addKit2', data);
}

export function getKitImg(data) {
  return request.get('getKitImg', data);
}

export function deleteKitImg(data) {
  return request.post('deleteKitImg', data);
}

//----------------------
export function searchKits2(data) {
  return request.get('searchKits2', data);
}