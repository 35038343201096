<template>
  <a-list class="search-kits-list" :data-source="kitsList.data" :loading="kitsList.loading" size="small" bordered :pagination="kitsList.pagination">
    <template #renderItem="{ item }">
      <a-list-item class="item">
        <template #actions>
          <a-button type="primary" @click="goTo(item.kit_no,'img')">图片</a-button>
          <a-button type="primary" @click="goTo(item.kit_no,'update')">修改</a-button>
          <a-button danger type="primary" @click="onDeleteKit(item)">删除</a-button>
        </template>
        <a-list-item-meta class="item-meta" hover @click="goTo(item.kit_no,'detail')">
          <template #description>
            <span v-html="'适应机型(系列)：'+item.model.map(ii=>ii.model_no_html+'('+ii.serial_html+')').join('，')"></span>
          </template>
          <template #title>
            <span v-html="item.kit_no_html"></span>
            <span class="sub-title" v-html="'名称：'+item.kit_name_html+(item.kit_name_2_html? '，中文名称：'+item.kit_name_2_html:'')+(item.spec_html? '，规格：'+item.spec_html:'')"></span>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</template>

<script>
  import {searchKits2, deleteKit2} from "@/api/kit";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "search-kit",
    data() {
      return {
        kitsList: {
          data: [],
          loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 10,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
      };
    },
    computed: {
      model_key: {
        get() {
          return this.$store.state.search.model_key;
        },
        set(newValue) {
          this.$store.commit('search/setModelKey', newValue);
        },
      },
      search_key: {
        get() {
          return this.$store.state.search.search_key;
        },
        set(newValue) {
          this.$store.commit('search/setSearchKey', newValue);
        },
      },
      search_type: {
        get() {
          return this.$store.state.search.search_type;
        },
        set(newValue) {
          this.$store.commit('search/setSearchType', newValue);
        },
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
      console.log('cr');
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
        console.log('wa');
      }
    },
    methods: {
      init() {
        this.search_type = 'kit';
        let params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            model_key: this.params.model_key || '',
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        this.searchKits2(params);
      },
      searchKits2(params) {
        this.kitsList.loading = true;
        searchKits2(params).then(res => {
          this.model_key = res.data.model_key;
          this.search_key = res.data.search_key;
          this.dealData(res.data.kits);
          this.kitsList.data = res.data.kits;
          this.kitsList.pagination.current = res.data.current;
          this.kitsList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.kitsList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: page,
        };
        this.searchKits2(params);
      },
      goTo(kit_no, type) {
        this.params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: this.kitsList.pagination.current
        };
        switch (type) {
          case 'detail':
            this.$router.push({path: '/kit/detail', query: {kit_no: kit_no}});
            break;
          case 'update':
            this.$router.push({path: '/kit/update', query: {kit_no: kit_no}});
            break;
          case 'img':
            this.$router.push({path: '/kit/img', query: {kit_no: kit_no}});
            break;
          case 'add':
            this.$router.push({path: '/kit/add'});
            break;
          default:
        }
      },
      onDeleteKit(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除修理包 ' + item.kit_no + ' ？',
          content: '若有机型拥有该修理包，则机型也会联动取消该修理包！',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteKit2({kit_no: item.kit_no}).then(() => {
              Modal.success({
                title: '修理包' + item.kit_no + '删除成功',
              });
              that.getKits2({search_key: that.kitsList.search_key, current: that.kitsList.pagination.current});
            }).catch(err => {
              Modal.error({
                title: '删除修理包' + item.kit_no + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },

      dealData(lists) {
        let reg_model_key = new RegExp('(' + this.model_key + ')', "ig");
        let reg_search_key = new RegExp('(' + this.search_key + ')', "ig");

        lists.forEach(item => {
          item.kit_no_html = item.kit_no;
          item.kit_name_html = item.kit_name;
          item.kit_name_2_html = item.kit_name_2;
          item.spec_html = item.spec;
          if (this.search_key) {
            item.kit_no_html = item.kit_no_html.replace(reg_search_key, "<span class='search_key_html'>$1</span>");
            item.kit_name_html = item.kit_name_html.replace(reg_search_key, "<span class='search_key_html'>$1</span>");
            if (item.kit_name_2_html) {
              item.kit_name_2_html = item.kit_name_2_html.replace(reg_search_key, "<span class='search_key_html'>$1</span>");
            }
            if (item.spec_html) {
              item.spec_html = item.spec_html.replace(reg_search_key, "<span class='search_key_html'>$1</span>");
            }
          }
          item.model.forEach(item2 => {
            item2.model_no_html = item2.model_no;
            item2.serial_html = item2.serial;
            if (this.model_key) {
              item2.model_no_html = item2.model_no_html.replace(reg_model_key, "<span class='model_key_html'>$1</span>");
              item2.serial_html = item2.serial_html.replace(reg_model_key, "<span class='model_key_html'>$1</span>");
            }
          });
        });
      },
    },

  }
</script>
<style>
  .search-kits-list .ant-list-item-meta-description {
    font-size: 12px;
  }

  .search-kits-list .model_key_html {
    color: red;
  }

  .search-kits-list .search_key_html {
    color: red;
  }
</style>
<style scoped>
  .search-kits-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

  .sub-title {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
    color: #aaa;
    font-weight: normal;
  }
</style>