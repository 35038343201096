//包装管理

import Layout from '@/layout';

const boxRouter = {
  path: '/box',
  name: 'box',
  redirect: '/box/index',
  component: Layout,
  meta: {title: '包装盒管理'},
  children: [
    {
      path: 'index',
      name: 'box-index',
      component: () => import('@/views/box'),
      meta: {title: '包装管理'},
    },
    {
      path: 'detail',
      name: 'box-detail',
      component: () => import('@/views/box/detail'),
      meta: {title: '包装详情'}
    },
    {
      path: 'add',
      name: 'box-add',
      component: () => import('@/views/box/add'),
      meta: {title: '新增包装'}
    },
    {
      path: 'update',
      name: 'box-update',
      component: () => import('@/views/box/update'),
      meta: {title: '包装修改'}
    },
    {
      path: 'img',
      name: 'box-img',
      component: () => import('@/views/box/img'),
      meta: {title: '包装图片'}
    },
  ],
};
export default boxRouter;