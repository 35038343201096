<template>
  <a-spin :spinning="searching" style="min-height: 20px;">
    <div v-if="search_type===''">
    </div>
    <div v-else-if="search_type==='none'">
      警告：没有符合搜索条件的搜索结果。
    </div>
    <template v-else>
      <div v-if="show_body">
        <a-button type="link" @click="goTo(body,search_type)">{{body?
          body[search_type+'_no']+'&emsp;'+body[search_type+'_name']+'&emsp;'+body[search_type+'_name_2']:''}}
        </a-button>
        <div v-if="body.replacement&&body.replacement.length" style="margin-left: 20px;">
          <span class="my-label">替换件：</span>
          <template v-for="item in body.replacement" :key="item">
            <a-button type="link" @click="goTo2({[search_type+'_no']:item,type:search_type})">{{item}}</a-button>
          </template>
        </div>
      </div>
      <a-list class="search-list" :data-source="list.data" size="small" bordered :pagination="list.pagination">
        <template #renderItem="{ item }">
          <a-list-item class="item">
            <a-list-item-meta class="item-meta" hover @click="goTo2(item)">
              <template #description>
                <span v-html="item.description_html"></span>
              </template>
              <template #title>
                <span v-html="item.title_html" style="font-size: 16px;font-weight: bolder;"></span>
                <span class="sub-title" v-html="item.sub_title_html"></span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </template>
  </a-spin>

</template>

<script>
  import {search} from "@/api/search";
  import {message} from 'ant-design-vue';

  export default {
    name: "search-index",
    data() {
      return {

        list: {
          data: [],
          loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },

        search_type: '',
        body: null,
        searching: false,

      };
    },
    computed: {
      model_key: {
        get() {
          return this.$store.state.search.model_key;
        },
        set(newValue) {
          this.$store.commit('search/setModelKey', newValue);
        },
      },
      search_key: {
        get() {
          return this.$store.state.search.search_key;
        },
        set(newValue) {
          this.$store.commit('search/setSearchKey', newValue);
        },
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
      show_body() {
        return ['kit', 'component', 'part'].indexOf(this.search_type) > -1;
      }
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        let params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            model_key: this.params.model_key || '',
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        if (params.search_key) {
          this.search(params);
        }
      },
      search(params) {
        this.list.loading = true;
        this.searching = true;
        search(params).then(res => {


          this.model_key = res.data.model_key;
          this.search_key = res.data.search_key;

          this.dealData(res.data);

          this.body = res.data.body;
          this.search_type = res.data.search_type;
          this.list.data = res.data.list;
          this.list.pagination.current = res.data.current;
          this.list.pagination.total = res.data.total;


        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.list.loading = false;
          this.searching = false;
        });
      },
      onChange(page) {
        let params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: page,
        };
        this.search(params);
        window.scrollTo(0, 0);
      },
      goTo(body, type) {
        this.params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: this.list.pagination.current
        };
        switch (type) {
          case 'kit':
            this.$router.push({
              path: '/kit/detail', query: {
                kit_no: body.kit_no, search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          case 'component':
            this.$router.push({
              path: '/component/detail', query: {
                component_no: body.component_no, search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          case 'part':
            this.$router.push({
              path: '/part/detail', query: {
                part_no: body.part_no, search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          default:
        }
      },
      goTo2(item) {
        this.params = {
          model_key: this.model_key,
          search_key: this.search_key,
          current: this.list.pagination.current
        };
        switch (item.type) {
          case 'kit':
            this.$router.push({
              path: '/kit/detail', query: {
                kit_no: item.kit_no,
                search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          case 'component':
            this.$router.push({
              path: '/component/detail', query: {
                component_no: item.component_no,
                search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          case 'part':
            this.$router.push({
              path: '/part/detail', query: {
                part_no: item.part_no,
                search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          case 'model-kit':
            this.$router.push({
              path: '/brand/model-kit',
              query: {
                brand_no: item.brand_no,
                model_no: item.model_no,
                kit_no: item.kit_no,
                search_key: this.search_key,
                search_type: this.search_type,
              }
            });
            break;
          default:
        }
      },
      dealData(data) {
        let reg_model_key = new RegExp('(' + this.model_key + ')', "ig");
        let reg_search_key = new RegExp('(' + this.search_key + ')', "ig");

        data.list.forEach(item => {
          item.title_html = '';
          item.sub_title_html = '';
          item.description_html = '';
          item.type = '';

          switch (data.search_type) {
            case 'kit':
              item.title_html += '<span class="haha2">' + item['kit_no'] + '</span>';
              item.sub_title_html += item['kit_name'] + '&emsp;' + item['kit_name_2'];

              item.description_html += item['model_no'] ? item['model_no'] + '&emsp;' + item['serial'] + '&emsp;' + item['brand_no'] + '&emsp;' + item['part_no2'] + '&emsp;' + item['model_no2'] + '<br>' : '';

              item.type = item['model_no'] ? 'model-kit' : 'kit';

              break;
            case 'component':
              item.title_html += '<span class="haha2">' + item['component_no'] + '</span>';
              item.sub_title_html += item['component_name'] + '&emsp;' + item['component_name_2'];

              item.description_html += item['model_no'] ? item['model_no'] + '&emsp;' + item['serial'] + '&emsp;' + item['brand_no'] + '&emsp;' + item['part_no2'] + '&emsp;' + item['model_no2'] + '<br>' : '';
              item.description_html += item['kit_no'] ? item['kit_no'] + '&emsp;' + item['kit_name'] + '&emsp;' + item['kit_name_2'] + '<br>' : '';

              item.type = item['model_no'] ? 'model-kit' : (item['kit_no'] ? 'kit' : 'component');

              break;
            case 'part':
              item.title_html += '<span class="haha2">' + item['part_no'] + '</span>';
              item.sub_title_html += item['part_name'] + '&emsp;' + item['part_name_2'];

              item.description_html += item['model_no'] ? item['model_no'] + '&emsp;' + item['serial'] + '&emsp;' + item['brand_no'] + '&emsp;' + item['part_no2'] + '&emsp;' + item['model_no2'] + '<br>' : '';
              item.description_html += item['kit_no'] ? item['kit_no'] + '&emsp;' + item['kit_name'] + '&emsp;' + item['kit_name_2'] + '<br>' : '';
              item.description_html += item['component_no'] ? item['component_no'] + '&emsp;' + item['component_name'] + '&emsp;' + item['component_name_2'] + '<br>' : '';

              item.type = item['model_no'] ? 'model-kit' : (item['kit_no'] ? 'kit' : item['component_no'] ? 'component' : 'part');

              break;
            case 'part_no2':
              item.title_html += item['kit_no'];
              item.sub_title_html += item['kit_name'] + '&emsp;' + item['kit_name_2'];

              item.description_html += item['model_no'] ? item['model_no'] + '&emsp;' + item['serial'] + '&emsp;' + item['brand_no'] + '&emsp;<span class="haha2">' + item['part_no2'] + '</span>&emsp;' + item['model_no2'] + '<br>' : '';

              item.type = 'model-kit';
              break;
            case 'model_no2':
              item.title_html += item['kit_no'];
              item.sub_title_html += item['kit_name'] + '&emsp;' + item['kit_name_2'];

              item.description_html += item['model_no'] ? item['model_no'] + '&emsp;' + item['serial'] + '&emsp;' + item['brand_no'] + '&emsp;' + item['part_no2'] + '&emsp;<span class="haha2">' + item['model_no2'] + '</span><br>' : '';
              item.type = 'model-kit';
              break;
          }

        });
      },
    },
  }
</script>

<style>
  .search-list .ant-list-item-meta-description {
    font-size: 14px;
    color: #333
  }

  .search-list .model_key_html {
    color: red;
  }

  .search-list .search_key_html {
    color: red;
  }

  .haha2 {
    color: #ffaa00;
    font-weight: bolder;
    font-size: 16px;
  }
</style>
<style scoped>
  .search-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

  .sub-title {
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
  //color: #aaa; font-weight: normal;
  }
</style>