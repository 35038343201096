const protocol = 'http';
//let doname = '127.0.0.1';
let doname = 'www.jfh.com';
const routerPre = '/admin';

let baseURL = `${protocol}://${doname}${routerPre}`;
let staticURL = `${protocol}://${doname}/`;
if (process.env.NODE_ENV !== 'development') {
  doname = '';
  baseURL = `${routerPre}`;
  staticURL = `/`;
}

module.exports = {
  protocol: protocol,
  doname: doname,
  routerPre: routerPre,
  baseURL: baseURL,
  staticURL: staticURL,
};