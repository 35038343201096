<template>
  <div id="login">
    <div class="center-div logo-con" style="text-align: right;">
      <!--
      <a-image
        :width="200"
        :src="imgs.logo"
        :preview="false"
      />
      -->
      <span style="line-height: 76px;font-size: 26px;">Power Seal</span><span style="font-size:16px;"> 管理系统</span>
    </div>
    <div class="center-div img-con">
      <a-carousel autoplay effect="fade">
        <div v-for="item in imgs.carousel" :key="item">
          <img :src="item"/>
        </div>
      </a-carousel>
    </div>
    <div class="center-div form-con">
      <!--
      <a-divider style="margin-bottom: 5px;border-width: 5px;border-color:#46a6ff; "/>
      -->
      <a-form layout="inline" ref="loginForm" :model="form" @finish="onSubmit" :rules="rules" :hideRequiredMark="true">
        <a-form-item name="account" label="账号" size="large" style="height: 38px;">
          <a-input v-model:value="form.account" dplaceholder="请输入账号" size="large" style="width:175px;">
            <!--
            <template #prefix>
              <UserOutlined :style="prefix" class="pre"/>
            </template>
            -->
          </a-input>
        </a-form-item>
        <a-form-item name="pwd" label="密码">
          <a-input-password v-model:value="form.pwd" dplaceholder="请输入密码" size="large" style="width:180px;">
            <!--
            <template #prefix>
              <LockOutlined :style="prefix" class="pre"/>
            </template>
            -->
          </a-input-password>
        </a-form-item>
        <a-form-item name="code" label="验证码">
          <a-input v-model:value="form.code" dplaceholder="请输入验证码" size="large" style="width:310px;">
            <!--
            <template #prefix>
              <VerifiedOutlined :style="prefix" class="pre"/>
            </template>
            -->
            <template #addonAfter>
              <img class="captcha" v-if="base64" title="点击，换一个" :src="base64" @click="updateCaptcha"/>
              <LoadingOutlined v-else style="padding: 0 11px;"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item style="margin-right: 0;">
          <a-button type="primary" html-type="submit" size="large" block :loading="submitting" style="width:110px;">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-divider/>
    <div class="footer">
      Copyright © {{(new Date).getFullYear()}} Power Seal. All rights reserved.
    </div>

  </div>
</template>

<script>
  import {getCaptcha} from "@/api/admin.js";
  import {UserOutlined, LockOutlined, VerifiedOutlined, LoadingOutlined} from '@ant-design/icons-vue';
  import {Modal} from 'ant-design-vue';
  import config from '@/config.js';


  export default {
    name: "Login",
    components: {
      //UserOutlined,
      //LockOutlined,
      //VerifiedOutlined,
      LoadingOutlined
    },
    data() {
      return {
        imgs: {
          logo: config.staticURL + 'storage/login/logo.jpg',
          carousel: [
            config.staticURL + 'storage/login/2.jpg',
            config.staticURL + 'storage/login/3.jpg',
            config.staticURL + 'storage/login/4.jpg',
            config.staticURL + 'storage/login/5.jpg',
          ],
        },

        wrapperCol: {span: 6, offset: 9},
        prefix: {
          color: 'rgba(0,0,0,.25)',
        },
        rules: {
          account: [
            {required: true, message: '请输入账号', trigger: 'blur'},
          ],
          pwd: [
            {required: true, message: '请输入密码', trigger: 'blur'},
          ],
          code: [
            {required: true, message: '请输入验证码', trigger: 'blur'},
          ],
        },
        form: {
          account: '',
          pwd: '',
          code: '',
          key: '',
        },
        base64: '',
        md5: '',
        submitting: false,
      };
    },
    mounted() {
      this.updateCaptcha();
    },
    methods: {
      onSubmit() {
        this.submitting = true;
        this.$store.dispatch('admin/login', this.form).then(() => {
          this.$router.push({path: '/'});
        }).catch(err => {
          if (err && err.message === '账户或密码错误。') {
            this.updateCaptcha();
          }
          Modal.warning({
            content: err.message,
          });
        }).finally(() => {
          this.submitting = false;
        });
      },
      updateCaptcha() {
        this.base64 = '';
        getCaptcha().then(response => {
          this.base64 = response.data.base64.replace(/\r\n/g, '');
          this.md5 = response.data.md5;
          this.form.key = response.data.key;
        }).catch(error => {
          console.log(error);
        });
      },
      async validateAccount(rule, value) {
        if (value.length < 1) {
          return Promise.reject('账号最少1个字母或数字');
        }
      },
      async validatePwd(rule, value) {
        if (value.length < 1) {
          return Promise.reject('密码最少1个字母或数字');
        }
      },
      async validateCode(rule, value) {
        if (value.length !== 5) {
          return Promise.reject('验证码错误');
        }
      },
    },
  };
</script>
<style>
  #login .ant-input-group-addon {
    padding: 0;
  }

  #login .ant-form-item-label > label {
    height: 38px;
    font-size: 16px;
  }
</style>

<style scoped>
  .center-div {
    width: 980px;
    margin: auto;
  }

  .logo-con {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Arial Black';
  }

  .form-con {
    margin-top: 40px;
    padding-bottom: 1px;
    min-height: 70px;
  }

  img.captcha {
    height: 38px;
    cursor: pointer;
  }

  .footer {
    text-align: center;
    color: #888;
    padding-bottom: 20px;
  }

  .pre {
    color: #000 !important;;
  }
</style>
