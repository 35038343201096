//机型管理

import Layout from '@/layout';

const modelRouter = {
  path: '/model',
  name: 'model',
  redirect: '/model/index',
  component: Layout,
  meta: {title: '机型管理'},
  children: [
    {
      path: 'index',
      name: 'model-index',
      component: () => import('@/views/model'),
      meta: {title: '机型管理'},
    },
    {
      path: 'img',
      name: 'model-img',
      component: () => import('@/views/model/img'),
      meta: {title: '机型图片'}
    },
    {
      path: 'pdf',
      name: 'model-pdf',
      component: () => import('@/views/model/pdf'),
      meta: {title: '机型文件'}
    },
  ],
};
export default modelRouter;