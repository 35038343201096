/**
 * token的本地存储和更新、删除
 * 此外使用cookie做本地存储
 * 可根据项目情况用localStorage替换cookie做本地存储
 */
import Cookies from 'js-cookie';

const TokenKey = 'AdminToken';
const SelfKey = 'AdminSelf';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getSelf() {
  return Cookies.getJSON(SelfKey);
}

export function setSelf(_self) {
  return Cookies.set(SelfKey, _self);
}

export function removeSelf() {
  return Cookies.remove(SelfKey);
}


