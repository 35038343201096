<template>
  <div>
    <span class="my-label">原厂号码：</span>
    <a-input v-model:value="addPart.data.part_no" style="width:200px;" placeholder="原厂号码"/>
    <br>
    <span class="my-label">配件名称：</span>
    <a-input v-model:value="addPart.data.part_name" placeholder="配件名称" style="width:200px;"/>
    <span class="my-label2">配件名称（中文）：</span>
    <a-input v-model:value="addPart.data.part_name_2" placeholder="配件名称（中文）" style="width:200px;"/>
    <br>
    <span class="my-label">成本价：</span>
    <a-input-number v-model:value="addPart.data.price_1" style="width:200px;" placeholder="成本价"/>
    <span class="my-label2">批发价：</span>
    <a-input-number v-model:value="addPart.data.price_2" style="width:200px;" placeholder="批发价"/>
    <br>
    <span class="my-label">零售价：</span>
    <a-input-number v-model:value="addPart.data.price_3" style="width:200px;" placeholder="零售价"/>
    <span class="my-label2">特殊价：</span>
    <a-input-number v-model:value="addPart.data.price_4" style="width:200px;" placeholder="特殊价"/>
    <br>
    <span class="my-label">重量：</span>
    <a-input-number v-model:value="addPart.data.weight" style="width:200px;" placeholder="重量"/>
    <span class="my-label2">重量单位：</span>
    <a-input v-model:value="addPart.data.weight_unit" style="width:200px;" placeholder="重量单位" disabled/>
    <br>
    <span class="my-label">规格：</span>
    <a-input v-model:value="addPart.data.spec" style="width:200px;" placeholder="规格"/>
    <span class="my-label2">排序：</span>
    <a-input-number v-model:value="addPart.data.order" style="width:200px;" placeholder="排序"/>
    <br>
    <span class="my-label">材质：</span>
    <a-input v-model:value="addPart.data.material" style="width:200px;" placeholder="材质"/>
    <span class="my-label2">备注：</span>
    <a-input v-model:value="addPart.data.remark" style="width:200px;" placeholder="备注"/>
    <br>
    <span class="my-label">描述：</span>
    <a-textarea v-model:value="addPart.data.descr" style="width:550px;" placeholder="描述"/>
    <br>
    <div style="text-align: center;margin-top:8px;">
      <a-button type="primary" @click="onAddPart" :loading="addPart.submitting">
        新增
      </a-button>
      <a-button type="primary" @click="clearAddPart" style="margin-left: 20px;">
        重填
      </a-button>
      <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
    </div>
  </div>
</template>

<script>
  import {addPart2} from "@/api/part";
  import {cloneDeep} from "@/utils/util";
  import {Modal} from 'ant-design-vue';

  let initConfig = {
    emptyPart: {
      loading: false,
      options: [],
      data: {
        part_no: '',
        part_name: '',
        part_name_2: '',
        price_1: null,
        price_2: null,
        price_3: null,
        price_4: null,
        weight: null,
        weight_unit: 'G',
        spec: '',
        order: 0,
        material: '',
        remark: '',
        descr: '',
      },
      submitting: false,
    },
  };

  export default {
    name: "part-add",
    data() {
      return {
        //新增配件
        addPart: cloneDeep(initConfig.emptyPart),
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        this.isGoBack = false;
      },
      onAddPart() {
        //检查是不有空以及 TODO重复
        let part = this.addPart;
        if (this.checkEmpty(part.data.part_no, '配件原厂号码不能空') || this.checkEmpty(part.data.part_name, '配件名称不能空') || this.checkEmpty(part.data.part_name_2, '配件名称（中文）不能空') || this.checkEmpty(part.data.order, '配件排序不能空')) {
          return;
        }

        let params = {
          part: JSON.parse(JSON.stringify(part.data)),
        };

        part.submitting = true;
        addPart2(params).then(res => {
          Modal.success({
            title: '配件 ' + params.part.part_name + ' 新增成功',
          });
          //清空配件数据
          this.clearAddPart();
        }).catch(err => {
          Modal.error({
            title: '新增配件失败',
            content: err.message || '',
          });
        }).finally(() => {
          part.submitting = false;
        });
      },
      clearAddPart() {
        this.addPart = cloneDeep(initConfig.emptyPart);
      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
    }
  }
</script>

<style scoped>
  .delete-button {
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .delete-button:hover {
    color: #ff4d4f;
  }

  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>