import request from '@/utils/request.js';

export function searchParts(data) {
  return request.get('searchParts', data);
}

export function getPart(data) {
  return request.get('getPart', data);
}

//------------------------------------
export function getParts2(data) {
  return request.get('getParts2', data);
}

export function getPart2(data) {
  return request.get('getPart2', data);
}

export function deletePart2(data) {
  return request.post('deletePart2', data);
}

export function updatePart2(data) {
  return request.post('updatePart2', data);
}

export function addPart2(data) {
  return request.post('addPart2', data);
}

export function getPartImg(data) {
  return request.get('getPartImg', data);
}

export function deletePartImg(data) {
  return request.post('deletePartImg', data);
}

export function autoReplacement() {
  return request.post('autoReplacement');
}

export function getPartNames() {
  return request.get('getPartNames');
}

//---------------------
export function searchParts2(data) {
  return request.get('searchParts2', data);
}