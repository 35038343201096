const state = {
  isGoBack: false,
  params: {},
};

const mutations = {
  setIsGoBack(state, isGoBack) {
    state.isGoBack = isGoBack;
  },
  setParams(state, params) {
    state.params = params;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};