<template>
  <div>
    <div style="padding-bottom: 4px;">
      <a-input v-model:value="adminsList.search_key" placeholder="账号" style="width:200px;"/>
      <a-button type="primary" @click="onSearchKey">搜索</a-button>
      <a-button style="float: right;" type="primary" @click="onShowAddAdminModal">新增帐号</a-button>
    </div>
    <a-list class="admins-list" :data-source="adminsList.data" :loading="adminsList.loading" size="small" bordered :pagination="adminsList.pagination">
      <template #renderItem="{ item }">
        <a-list-item class="item">
          <template #actions>
            <a-button type="primary" @click="onShowUpdateAdminModal(item)" size="small">重置密码</a-button>
            <a-button type="primary" @click="onShowUpdateAdminModal2(item)" size="small">修改权限</a-button>
            <a-button danger type="primary" @click="onDeleteAdmin(item)" size="small">删除</a-button>
          </template>
          <a-list-item-meta class="item-meta">
            <template #title>
              {{ item.account }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <div style="color:#aaa;font-size: 12px;padding:3px 0;">
      备注：只有帐号为admin是管理员，其他帐号为一般用户；只有管理员才有用户管理权限。
    </div>
    <a-modal v-model:visible="addAdminModal.visible" title="新增帐号" :closable="true" :maskClosable="false" @cancel="onCloseAddAdminModal" :footer="null" width="580px">
      <a-form>
        <a-form-item label="帐号" name="account">
          <a-input v-model:value="addAdminModal.data.account" autocomplete="new-password"/>
        </a-form-item>
        <a-form-item label="密码" name="pwd">
          <a-input-password v-model:value="addAdminModal.data.pwd" autocomplete="new-password"/>
        </a-form-item>
        <a-form-item label="权限" name="qx">
          成本价
          <a-switch v-model:checked="addAdminModal.data.qx.price_1" checked-children="开" un-checked-children="关"/>
          &emsp;
          批发价
          <a-switch v-model:checked="addAdminModal.data.qx.price_2" checked-children="开" un-checked-children="关"/>
          &emsp;
          零售价
          <a-switch v-model:checked="addAdminModal.data.qx.price_3" checked-children="开" un-checked-children="关"/>
          &emsp;
          特殊价
          <a-switch v-model:checked="addAdminModal.data.qx.price_4" checked-children="开" un-checked-children="关"/>
          <p></p>

          配件原厂号码
          <a-switch v-model:checked="addAdminModal.data.qx.part_no" checked-children="开" un-checked-children="关"/>
          &emsp;
          规格
          <a-switch v-model:checked="addAdminModal.data.qx.spec" checked-children="开" un-checked-children="关"/>
          &emsp;
          备注
          <a-switch v-model:checked="addAdminModal.data.qx.remark" checked-children="开" un-checked-children="关"/>
          <p></p>

          显示元件包含的配件
          <a-switch v-model:checked="addAdminModal.data.qx.component_parts" checked-children="开" un-checked-children="关"/>
          &emsp;
          导出修理包或配件
          <a-switch v-model:checked="addAdminModal.data.qx.download" checked-children="开" un-checked-children="关"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onAdd" :loading="addAdminModal.loading">新增</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="updateAdminModal.visible" title="重置密码" :closable="true" :maskClosable="false" @cancel="onCloseUpdateAdminModal" :footer="null">
      <a-form>
        <a-form-item label="帐号" name="account">
          <a-input v-model:value="updateAdminModal.data.account" :disabled="true"/>
        </a-form-item>
        <a-form-item label="密码" name="pwd">
          <a-input-password v-model:value="updateAdminModal.data.pwd" placeholder="请输入新密码" autocomplete="new-password"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onUpdateAdmin" :loading="updateAdminModal.loading">更新</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="updateAdminModal2.visible" title="修改权限" :closable="true" :maskClosable="false" @cancel="onCloseUpdateAdminModal2" :footer="null" width="580px">
      <a-form>
        <a-form-item label="帐号" name="account">
          <a-input v-model:value="updateAdminModal2.data.account" :disabled="true"/>
        </a-form-item>
        <a-form-item label="权限" name="qx">
          成本价
          <a-switch v-model:checked="updateAdminModal2.data.qx.price_1" checked-children="开" un-checked-children="关"/>
          &emsp;
          批发价
          <a-switch v-model:checked="updateAdminModal2.data.qx.price_2" checked-children="开" un-checked-children="关"/>
          &emsp;
          零售价
          <a-switch v-model:checked="updateAdminModal2.data.qx.price_3" checked-children="开" un-checked-children="关"/>
          &emsp;
          特殊价
          <a-switch v-model:checked="updateAdminModal2.data.qx.price_4" checked-children="开" un-checked-children="关"/>
          <p></p>


          配件原厂号码
          <a-switch v-model:checked="updateAdminModal2.data.qx.part_no" checked-children="开" un-checked-children="关"/>
          &emsp;
          规格
          <a-switch v-model:checked="updateAdminModal2.data.qx.spec" checked-children="开" un-checked-children="关"/>
          &emsp;
          备注
          <a-switch v-model:checked="updateAdminModal2.data.qx.remark" checked-children="开" un-checked-children="关"/>
          <p></p>


          显示元件包含的配件
          <a-switch v-model:checked="updateAdminModal2.data.qx.component_parts" checked-children="开" un-checked-children="关"/>
          &emsp;
          导出修理包或配件
          <a-switch v-model:checked="updateAdminModal2.data.qx.download" checked-children="开" un-checked-children="关"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onUpdateAdmin2" :loading="updateAdminModal2.loading">更新</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {getAdmins, deleteAdmin, updateAdmin, addAdmin, updateAdmin2} from "@/api/admin";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "gly",
    data() {
      return {
        adminsList: {
          data: [],
          loading: false,
          search_key: '',
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
        updateAdminModal: {
          visible: false,
          loading: false,
          data: {
            account: '',
            pwd: '',
          }
        },
        updateAdminModal2: {
          visible: false,
          loading: false,
          data: {
            account: '',
            qx: {
              price_1:false,//成本价
              price_2:false,//批发价
              price_3:false,//零售价
              price_4:false,//特殊价

              part_no:false,//原厂号码
              spec:false,//规格
              remark:false,//备注

              component_parts: false,//显示元件所包含的配件
              download: false,//导出修理包
            },
          }
        },
        addAdminModal: {
          visible: false,
          loading: false,
          data: {
            account: '',
            pwd: '',
            qx: {
              price_1:false,//成本价
              price_2:false,//批发价
              price_3:false,//零售价
              price_4:false,//特殊价

              part_no:false,//原厂号码
              spec:false,//规格
              remark:false,//备注

              component_parts: false,//显示元件所包含的配件
              download: false,//导出修理包
            },
            type: 'user',
          }
        },
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        let params = {
          search_key: '',
          current: 1,
        };
        this.getAdmins(params);
        this.isGoBack = false;
      },
      getAdmins(params) {
        this.adminsList.loading = true;
        getAdmins(params).then(res => {
          this.adminsList.data = res.data.admins;
          this.adminsList.search_key = res.data.search_key;
          this.adminsList.pagination.current = res.data.current;
          this.adminsList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.adminsList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          search_key: this.adminsList.search_key,
          current: page,
        };
        this.getAdmins(params);
      },
      onSearchKey() {
        let params = {
          search_key: this.adminsList.search_key,
          current: 1,
        };
        this.getAdmins(params);
      },
      onShowUpdateAdminModal(item) {
        this.updateAdminModal.visible = true;
        this.updateAdminModal.data.account = item.account;
        this.updateAdminModal.data.pwd = '';
      },
      onCloseUpdateAdminModal() {
        this.updateAdminModal.visible = false;
      },
      onUpdateAdmin() {
        let admin = this.updateAdminModal.data;
        if (this.checkEmpty(admin.pwd, '密码不能空')) {
          return;
        }
        if (admin.pwd.length < 6 || admin.pwd.length > 17) {
          Modal.warning({
            title: '密码长度必须为6-16位',
          });
          return;
        }
        if (!(/[A-Za-z]/.test(admin.pwd) && /[0-9]/.test(admin.pwd))) {
          Modal.warning({
            title: '密码至少包含一个数字和一个字母',
          });
          return;
        }
        let params = {
          admin: this.updateAdminModal.data,
        };
        this.updateAdminModal.loading = true;
        updateAdmin(params).then(res => {
          Modal.success({
            title: '帐号 ' + params.admin.account + ' 重置密码成功',
          });
          this.updateAdminModal.visible = false;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updateAdminModal.loading = false;
        });
      },
      onShowUpdateAdminModal2(item) {
        this.updateAdminModal2.visible = true;
        this.updateAdminModal2.data.account = item.account;
        this.updateAdminModal2.data.qx.price_1 = item.qx.price_1;
        this.updateAdminModal2.data.qx.price_2 = item.qx.price_2;
        this.updateAdminModal2.data.qx.price_3 = item.qx.price_3;
        this.updateAdminModal2.data.qx.price_4 = item.qx.price_4;
        this.updateAdminModal2.data.qx.part_no = item.qx.part_no;
        this.updateAdminModal2.data.qx.spec = item.qx.spec;
        this.updateAdminModal2.data.qx.remark = item.qx.remark;
        this.updateAdminModal2.data.qx.component_parts = item.qx.component_parts;
        this.updateAdminModal2.data.qx.download = item.qx.download;
      },
      onCloseUpdateAdminModal2() {
        this.updateAdminModal2.visible = false;
      },
      onUpdateAdmin2() {
        let params = {
          admin: this.updateAdminModal2.data,
        };
        this.updateAdminModal2.loading = true;
        updateAdmin2(params).then(res => {
          Modal.success({
            title: '帐号 ' + params.admin.account + ' 修改权限成功',
          });
          this.updateAdminModal2.visible = false;
          this.getAdmins({search_key: this.adminsList.search_key, current: this.adminsList.pagination.current});
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updateAdminModal2.loading = false;
        });
      },

      onDeleteAdmin(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除帐号 ' + item.account + ' ？',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteAdmin({admin_account: item.account}).then(() => {
              Modal.success({
                title: '帐号' + item.account + '删除成功',
              });
              that.getAdmins({search_key: that.adminsList.search_key, current: that.adminsList.pagination.current});
            }).catch(err => {
              Modal.error({
                title: '删除帐号' + item.account + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },

      onShowAddAdminModal() {
        this.addAdminModal.visible = true;
      },
      onAdd() {
        if (this.addAdminModal.data.account === '') {
          Modal.warning({
            title: '帐号不能空',
          });
          return;
        }
        if (this.addAdminModal.data.pwd === '') {
          Modal.warning({
            title: '密码不能空',
          });
          return;
        }
        if (this.addAdminModal.data.pwd.length < 6 || this.addAdminModal.data.pwd.length > 17) {
          Modal.warning({
            title: '密码长度必须为6-16位',
          });
          return;
        }
        if (!(/[A-Za-z]/.test(this.addAdminModal.data.pwd) && /[0-9]/.test(this.addAdminModal.data.pwd))) {
          Modal.warning({
            title: '密码至少包含一个数字和一个字母',
          });
          return;
        }
        this.addAdmin({admin: this.addAdminModal.data});
      },
      addAdmin(params) {
        this.addAdminModal.loading = true;
        addAdmin(params).then(() => {
          Modal.success({
            title: '帐号 ' + params.admin.account + ' 新增成功',
          });
          this.addAdminModal.visible = false;
          this.getAdmins({search_key: this.adminsList.search_key, current: this.adminsList.pagination.current});
          this.addAdminModal.data.account = '';
          this.addAdminModal.data.pwd = '';
          this.addAdminModal.data.qx.price_1 = false;
          this.addAdminModal.data.qx.price_2 = false;
          this.addAdminModal.data.qx.price_3 = false;
          this.addAdminModal.data.qx.price_4 = false;
          this.addAdminModal.data.qx.part_no = false;
          this.addAdminModal.data.qx.spec = false;
          this.addAdminModal.data.qx.remark = false;
          this.addAdminModal.data.qx.component_parts = false;
          this.addAdminModal.data.qx.download = false;
        }).catch(err => {
          Modal.error({
            title: '新增帐号失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.addAdminModal.loading = false;
        });
      },
      onCloseAddAdminModal() {
        this.addAdminModal.visible = false;
      },

    },

  }
</script>
<style>
  .admins-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .admins-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
  }
</style>
<style scoped>
  .admins-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
  }

</style>