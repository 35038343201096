//修理包管理

import Layout from '@/layout';

const kitRouter = {
  path: '/kit',
  name: 'kit',
  redirect: '/kit/index',
  component: Layout,
  meta: {title: '修理包管理'},
  children: [
    {
      path: 'index',
      name: 'kit-index',
      component: () => import('@/views/kit'),
      meta: {title: '修理包管理'},
    },
    {
      path: 'detail',
      name: 'kit-detail',
      component: () => import('@/views/kit/detail'),
      meta: {title: '修理包详情'}
    },
    {
      path: 'add',
      name: 'kit-add',
      component: () => import('@/views/kit/add'),
      meta: {title: '新增修理包'}
    },
    {
      path: 'update',
      name: 'kit-update',
      component: () => import('@/views/kit/update'),
      meta: {title: '修理包修改'}
    },
    {
      path: 'img',
      name: 'kit-img',
      component: () => import('@/views/kit/img'),
      meta: {title: '修理包图片'}
    },
  ],
};
export default kitRouter;