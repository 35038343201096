/**
 * 对需要token的路由进行检查是否有token
 */

import router from './router';
import store from './store';
//import {getToken} from './utils/storage.js'; // get token from cookie

const whiteList = [`/login`]; // don't need token whitelist

router.beforeEach(async (to, from, next) => {
  //console.log(to);
  //console.log(from);
  // determine whether the user has logged in
  const hasToken = store.getters.token;

  if (hasToken) {
    if (to.path === `/login`) {
      // if is logged in, redirect to the home page
      next({path: `/`});
    } else {
      // await store.dispatch('user/getMenus')
      next();
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      await store.dispatch('admin/clearToken');
      next(`/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {
});
