//配件管理

import Layout from '@/layout';

const partRouter = {
  path: '/part',
  name: 'part',
  redirect: '/part/index',
  component: Layout,
  meta: {title: '配件管理'},
  children: [
    {
      path: 'index',
      name: 'part-index',
      component: () => import('@/views/part'),
      meta: {title: '配件管理'},
    },
    {
      path: 'detail',
      name: 'part-detail',
      component: () => import('@/views/part/detail'),
      meta: {title: '配件详情'}
    },
    {
      path: 'add',
      name: 'part-add',
      component: () => import('@/views/part/add'),
      meta: {title: '新增配件'}
    },
    {
      path: 'update',
      name: 'part-update',
      component: () => import('@/views/part/update'),
      meta: {title: '配件修改'}
    },
    {
      path: 'img',
      name: 'part-img',
      component: () => import('@/views/part/img'),
      meta: {title: '配件图片'}
    },
  ],
};
export default partRouter;