//管理员管理

import Layout from '@/layout';

const glyRouter = {
  path: '/gly',
  name: 'gly',
  redirect: '/gly/index',
  component: Layout,
  meta: {title: '用户管理'},
  children: [
    {
      path: 'index',
      name: 'gly-index',
      component: () => import('@/views/gly'),
      meta: {title: '用户管理'},
    },
  ],
};
export default glyRouter;