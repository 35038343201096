import axios from 'axios';
import config from '@/config.js';
import store from '@/store';
//import SettingMer from '@/libs/settingMer'
//import { MessageBox } from 'element-ui'
const instance = axios.create({
  baseURL: config.baseURL,
  timeout: 50000,
});
const defaultOpt = {login: true};

function baseRequest(options) {
  const token = store.getters.token;
  const headers = options.headers || {};
  if (token) {
    headers['X-Token'] = token;
    //headers['X-Csrf-Token'] = token;
    options.headers = headers;
  }
  return instance(options).then(res => {
    const data = res.data || {};
    if (res.status !== 200) {
      return Promise.reject({message: '请求失败', res, data});
    }
    if ([410000, 410001, 410002, 40000].indexOf(data.status) !== -1) {
      console.log(res);
      store.dispatch('admin/clearToken').then(() => {
        location.reload();
      });
      //MessageBox.close()
      return Promise.reject({message: data.message, res, data});
    } else if (data.status === 200) {
      return Promise.resolve(data, res);
    } else {
      return Promise.reject({message: data.message, res, data});
    }
  })
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, data = {}, options = {}) => {
    return baseRequest(
      Object.assign({url, data, method}, defaultOpt, options)
    );
  };
  return request;
}, {});

['get', 'head'].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, params = {}, options = {}) => {
    return baseRequest(
      Object.assign({url, params, method}, defaultOpt, options)
    );
  };
});

export default request
