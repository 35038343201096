//元件管理

import Layout from '@/layout';

const componentRouter = {
  path: '/component',
  name: 'component',
  redirect: '/component/index',
  component: Layout,
  meta: {title: '元件管理'},
  children: [
    {
      path: 'index',
      name: 'component-index',
      component: () => import('@/views/component'),
      meta: {title: '元件管理'},
    },
    {
      path: 'detail',
      name: 'component-detail',
      component: () => import('@/views/component/detail'),
      meta: {title: '元件详情'}
    },
    {
      path: 'add',
      name: 'component-add',
      component: () => import('@/views/component/add'),
      meta: {title: '新增元件'}
    },
    {
      path: 'update',
      name: 'component-update',
      component: () => import('@/views/component/update'),
      meta: {title: '元件修改'}
    },
    {
      path: 'img',
      name: 'component-img',
      component: () => import('@/views/component/img'),
      meta: {title: '元件图片'}
    },
  ],
};
export default componentRouter;