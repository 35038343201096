<template>
  <div>
    <div style="padding-bottom: 4px;">
      <a-auto-complete
        v-model:value="boxesList.search_key"
        :options="boxesList.options"
        style="width: 500px"
        placeholder="编号、名称"
        @search="onBoxNoSearch(boxesList)"
        :loading="boxesList.optionsLoading"
      >
        <template #option="item">
          <span v-html="item.label_html"></span>
        </template>
      </a-auto-complete>
      <a-button type="primary" @click="onSearchKey">搜索</a-button>
      <a-button style="float: right;" type="primary" @click="goTo(null,'add')" v-if="_self&&_self.type==='admin'">新增包装</a-button>
    </div>
    <a-list class="boxes-list" :data-source="boxesList.data" :loading="boxesList.loading" size="small" bordered :pagination="boxesList.pagination">
      <template #renderItem="{ item }">
        <a-list-item class="item">
          <template #actions>
            <a-button type="primary" @click="goTo(item.id,'img')" size="small">图片</a-button>
            <a-button type="primary" @click="goTo(item.id,'update')" size="small" v-if="_self&&_self.type==='admin'">修改</a-button>
            <a-button danger type="primary" @click="onDeleteBox(item)" size="small" v-if="_self&&_self.type==='admin'">删除</a-button>
          </template>
          <a-list-item-meta class="item-meta" :description="'名称：'+item.box_name+'，中文名称：'+item.box_name_2" hover @click="goTo(item.id,'detail')">
            <template #title>
              {{ item.box_no }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>

  </div>
</template>

<script>
  import {getBoxes2, deleteBox2,searchBoxes} from "@/api/box";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "box-index",
    data() {
      return {
        boxesList: {
          data: [],
          loading: false,
          search_key: '',
          options: [],
          options_loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        let params = {
          search_key: '',
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        this.getBoxes2(params);
      },
      getBoxes2(params) {
        this.boxesList.loading = true;
        getBoxes2(params).then(res => {
          this.boxesList.data = res.data.boxes;
          this.boxesList.search_key = res.data.search_key;
          this.boxesList.pagination.current = res.data.current;
          this.boxesList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.boxesList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          search_key: this.boxesList.search_key,
          current: page,
        };
        this.getBoxes2(params);
      },
      onSearchKey() {
        let params = {
          search_key: this.boxesList.search_key,
          current: 1,
        };
        this.getBoxes2(params);
      },
      goTo(box_id, type) {
        this.params = {search_key: this.boxesList.search_key, current: this.boxesList.pagination.current};
        switch (type) {
          case 'detail':
            this.$router.push({path: '/box/detail', query: {box_id: box_id}});
            break;
          case 'update':
            this.$router.push({path: '/box/update', query: {box_id: box_id}});
            break;
          case 'img':
            this.$router.push({path: '/box/img', query: {box_id: box_id}});
            break;
          case 'add':
            this.$router.push({path: '/box/add'});
            break;
          default:
        }
      },
      onDeleteBox(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除包装 ' + item.box_no + ' ？',
          content: '若有修理包使用该包装，则修理包也会联动取消该包装！',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteBox2({box_id: item.id}).then(() => {
              Modal.success({
                title: '包装 ' + item.box_no + '删除成功',
              });
              that.getBoxes2({
                search_key: that.boxesList.search_key,
                current: that.boxesList.pagination.current
              });
            }).catch(err => {
              Modal.error({
                title: '删除包装' + item.box_no + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },

      onBoxNoSearch(list) {
        let params = {
          box_no: list.search_key,
        };

        if (list.timer) {
          clearTimeout(list.timer);
        }

        list.timer = setTimeout(() => {
          list.optionsLoading = true;
          searchBoxes(params).then(res => {
            if (list.search_key !== params.box_no) {
              return;
            }
            let reg_key = new RegExp('(' + params.box_no + ')', "ig");
            list.options = res.data.boxes.map(item => {
              return {
                value: item.box_no,
                label: item.box_no + ' (' + item.box_name + ')',
                label_html: item.box_no.replace(reg_key, "<span class='search_key_html'>$1</span>") + ' (' + item.box_name.replace(reg_key, "<span class='search_key_html'>$1</span>") + ')'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            list.optionsLoading = false;
          });
        }, 500);
      }
    },

  }
</script>
<style>
  .boxes-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .boxes-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
  }
</style>
<style scoped>
  .boxes-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

</style>