<template>
  <div></div>
</template>

<script>
  import {message} from 'ant-design-vue';

  export default {
    name: "logout",
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.$store.dispatch('admin/logout').then(() => {
        this.$router.push('/login');
      }).catch((err) => {
        message.error(err.message ? err.message : '操作失败', 5);
        console.log(err);
      });
      this.isGoBack = false;
    }
  }
</script>

<style scoped>

</style>