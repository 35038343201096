import {createStore} from 'vuex';
import getters from './getters';
import admin from './modules/admin.js';
import search from './modules/search.js';
import bus from './modules/bus.js';

export default createStore({
  state: {},
  getters,
  mutations: {},
  actions: {},
  modules: {
    admin,
    search,
    bus,
  }
})
