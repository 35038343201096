<template>
  <a-layout>
    <a-layout-header class="layout-header">
      <div class="sys-title">
        Power Seal管理系统
      </div>
      <div class="search">
        <a-form layout="inline" @submit="onSearch">
          <a-form-item name="model_key">
            <a-auto-complete
              class="header-search"
              style="width: 350px"
              v-model:value="model_key"
              :options="model_options"
              placeholder="机型前缀或系列号"
              @search="onModelNoSearch"
              :loading="model_loading"
              size="large"
            >
              <template #option="item">
                <span v-html="item.label_html"></span>
              </template>
            </a-auto-complete>
          </a-form-item>
          <a-form-item name="search_key">
            <a-auto-complete
              class="header-search"
              style="width: 350px"
              v-model:value="search_key"
              :options="search_options"
              placeholder="编号"
              @search="onPCKNoSearch"
              :loading="search_loading"
              size="large"
            >
              <template #option="item">
                <span v-html="item.label_html"></span>
              </template>
            </a-auto-complete>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" size="large" html-type="submit">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="user">
        <a-dropdown>
          <a-button size="large">
            <UserOutlined/>
            {{_self? _self['account']:''}}{{_self&&_self.type==='admin'? '（管理员）':'（用户）'}}
            <DownOutlined/>
          </a-button>

          <template #overlay>
            <a-menu @click="goTo">
              <a-menu-item key="/xgmm">修改密码</a-menu-item>
              <a-menu-item key="/logout">退出</a-menu-item>
            </a-menu>
          </template>
          <template #icon>
            <UserOutlined/>
          </template>
        </a-dropdown>
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-header class="layout-header-menu">
        <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal" :theme="theme" @click="goTo" style="font-size: 16px;">
          <a-menu-item key="/brand">品牌管理</a-menu-item>
          <a-menu-item key="/model">机型管理</a-menu-item>
          <a-menu-item key="/kit">修理包管理</a-menu-item>
          <a-menu-item key="/component">元件管理</a-menu-item>
          <a-menu-item key="/part">配件管理</a-menu-item>
          <a-menu-item key="/box">包装管理</a-menu-item>
          <a-menu-item key="/gly" v-if="_self&&_self.type==='admin'">用户管理</a-menu-item>
        </a-menu>
      </a-layout-header>
      <a-layout-content class="layout-content" style="min-height: 230px;">
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-layout>
  <a-divider/>
  <div class="footer">
    Copyright © {{(new Date).getFullYear()}} Power Seal. All rights reserved.
  </div>
</template>

<script>
  import {searchPCKs} from "@/api/search";
  import {searchModels} from "@/api/model";
  import {UserOutlined, DownOutlined} from '@ant-design/icons-vue';
  import {message, Modal} from 'ant-design-vue';
  import config from '@/config.js';

  export default {
    name: "layout",
    components: {
      UserOutlined,
      DownOutlined,
    },
    data() {
      return {
        imgs: {
          logo: config.staticURL + 'storage/login/logo.jpg',
        },

        theme: 'light',//dark,light
        selectedKeys: [],

        model_timer: null,
        model_options: [],
        model_loading: false,
        search_timer: null,
        search_options: [],
        search_loading: false,
      };
    },
    created() {
      this.init();
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init();
        }
      },
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      model_key: {
        get() {
          return this.$store.state.search.model_key;
        },
        set(newValue) {
          this.$store.commit('search/setModelKey', newValue);
        },
      },
      search_key: {
        get() {
          return this.$store.state.search.search_key;
        },
        set(newValue) {
          this.$store.commit('search/setSearchKey', newValue);
        },
      },
    },
    methods: {
      init() {
        this.getSelectedKeys();
      },
      getSelectedKeys() {
        this.selectedKeys = this.$route.matched.map(item => {
          return item.path;
        });
      },
      goTo({keyPath}) {
        this.$router.push({path: keyPath[0], query: {t: Date.now()}});
      },
      onSearch() {
        if (this.search_key) {
          this.$router.push({path: '/search', query: {t: Date.now()}});
        } else {
          Modal.warning({
            title: '搜索条件不能空',
            content: '请输入编号',
          });
        }
      },
      onModelNoSearch() {
        let params = {
          model_key: this.model_key,
        };

        if (this.model_timer) {
          clearTimeout(this.model_timer);
        }

        this.model_timer = setTimeout(() => {
          this.model_loading = true;
          searchModels(params).then(res => {
            if (this.model_key !== params.model_key) {
              return;
            }
            let reg_model_key = new RegExp('(' + params.model_key + ')', "ig");
            this.model_options = res.data.models.map(item => {
              return {
                value: item.model_no,
                label: item.model_no + ' (' + item.serial + ')',
                label_html: item.model_no.replace(reg_model_key, "<span class='search_key_html'>$1</span>") + ' (' + item.serial.replace(reg_model_key, "<span class='search_key_html'>$1</span>") + ')'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            this.model_loading = false;
          });
        }, 500);
      },
      onPCKNoSearch() {
        let params = {
          search_key: this.search_key,
        };

        if (this.search_timer) {
          clearTimeout(this.search_timer);
        }

        this.search_timer = setTimeout(() => {
          this.search_loading = true;
          searchPCKs(params).then(res => {
            if (this.search_key !== params.search_key) {
              return;
            }
            let reg_key = new RegExp('(' + params.search_key + ')', "ig");
            this.search_options = res.data.PCKs.map(item => {
              return {
                value: item.no,
                label: item.no + ' (' + item.name + ')',
                label_html: item.no.replace(reg_key, "<span class='search_key_html'>$1</span>") + ' (' + item.name.replace(reg_key, "<span class='search_key_html'>$1</span>") + ')'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            this.search_loading = false;
          });
        }, 500);
      }
    }

  }
</script>

<style>
  .layout-header .ant-form-inline .ant-form-item {
    margin-right: 1px;
  }

  .search_key_html {
    color: red;
  }
</style>
<style scoped>
  .layout-header {
    padding: 0;
    background-color: rgba(100, 100, 100, 0.2);
  }

  .layout-header-menu {
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    height: 48px;
    line-height: 48px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  }

  .layout-content {
    padding: 5px;
  }

  .sys-title {
    display: inline-block;
    font-size: 20px;
    width: 200px;
    text-align: center;
  }

  .search {
    display: inline-block;
  }

  .user {
    display: inline-block;
    float: right;
    margin-right: 5px;
  }

  .header-search {
    width: 200px;
  }

  .footer {
    text-align: center;
    color: #888;
    padding-bottom: 20px;
  }
</style>