<template>
  <div>
    <a-spin :spinning="updateKit.loading">
      <span class="my-label">KIT NO.：</span>
      <a-input v-model:value="updateKit.data.kit_no" style="width:200px;" placeholder="修理包编号"/>
      <br>
      <span class="my-label">KIT NAME：</span>
      <a-auto-complete
        v-model:value="updateKit.data.kit_name"
        :options="kitNames2"
        style="width: 200px"
        placeholder="修理包名称"
        @select="onKitNameSelect2(updateKit)"
        :filter-option="filterOption"
      />
      <span class="my-label2">修理包名称（中文）：</span>
      <a-input v-model:value="updateKit.data.kit_name_2" placeholder="修理包名称（中文）" style="width:200px;"/>
      <br>
      <span class="my-label">成本价：</span>
      <a-input-number v-model:value="updateKit.data.price_1" style="width:200px;" placeholder="保存后自动计算成本价" disabled/>
      <span class="my-label2">批发价：</span>
      <a-input-number v-model:value="updateKit.data.price_2" style="width:200px;" placeholder="批发价"/>
      <br>
      <span class="my-label">零售价：</span>
      <a-input-number v-model:value="updateKit.data.price_3" style="width:200px;" placeholder="零售价"/>
      <span class="my-label2">特殊价：</span>
      <a-input-number v-model:value="updateKit.data.price_4" style="width:200px;" placeholder="特殊价"/>
      <br>
      <span class="my-label">重量：</span>
      <a-input-number v-model:value="updateKit.data.weight" style="width:200px;" placeholder="保存后自动计算重量" disabled/>
      <span class="my-label2">重量单位：</span>
      <a-input v-model:value="updateKit.data.weight_unit" style="width:200px;" placeholder="重量单位" disabled/>
      <br>
      <span class="my-label">规格：</span>
      <a-input v-model:value="updateKit.data.spec" style="width:200px;" placeholder="规格"/>
      <span class="my-label2">排序：</span>
      <a-input-number v-model:value="updateKit.data.order" style="width:200px;" placeholder="排序"/>
      <br>
      <span class="my-label">包装：</span>
      <a-select
        show-search
        v-model:value="updateKit.data.box_no"
        :options="boxSelector.options"
        style="width: 200px"
        placeholder="选择包装"
        :loading="boxSelector.loading"
        allowClear
      ></a-select>
      <span style="font-size:12px;color:#999;" v-if="updateKit.data.box_no">
      （规格：{{boxes[updateKit.data.box_no]&&boxes[updateKit.data.box_no].spec}}）
    </span>
      <br>

      <template v-for="(component,c_index) in updateKit.data.components" :key="c_index">
        <a-divider style="background-color: #888;margin: 5px 0;"/>
        序号：
        <a-input v-model:value="component.data.index" style="width:50px;"/>
        元件编号：
        <a-auto-complete
          v-model:value="component.data.component_no"
          :options="component.options"
          style="width: 200px"
          placeholder="元件编号"
          @search="onComponentNameSearch(component)"
          @select="onComponentNameSelect(component)"
          :loading="component.loading"
        />
        元件名称：
        <a-auto-complete
          v-model:value="component.data.component_name"
          :options="componentNames2"
          style="width:calc(100%/2 - 730px/2);"
          placeholder="元件名称"
          @select="onComponentNameSelect2(component)"
          :filter-option="filterOption"
        />
        元件名称（中文）：
        <a-input v-model:value="component.data.component_name_2" placeholder="元件名称（中文）" style="width:calc(100%/2 - 730px/2);"/>
        数量：
        <a-input v-model:value="component.data.count" placeholder="" style="width:50px;"/>

        <MinusCircleOutlined title="取消元件" style="position: relative;top: 4px;left: 10px;" class="delete-button" @click="onRemoveComponent(updateKit,c_index)"/>
        <br>

        <a-table :columns="columns" :data-source="component.data.parts" bordered :pagination="false" size="small">
          <template #bodyCell="{record,index,column}">
            <template v-if="!['part_no','operation'].includes(column.dataIndex)">
              <a-input v-model:value="record.data[column.dataIndex]"/>
            </template>
            <template v-else-if="column.dataIndex==='part_no'">
              <a-auto-complete
                v-model:value="record.data[column.dataIndex]"
                :options="record.options"
                style="width: 100%"
                @search="onPartNameSearch(record)"
                @select="onPartNameSelect(record)"
                :loading="record.loading"
              />
            </template>
            <template v-else>
              <MinusCircleOutlined title="取消配件" class="delete-button" @click="onRemovePart(component,index)"/>
            </template>
          </template>
        </a-table>
        <div style="margin-top: 5px;">
          <a-button type="dashed" @click="onAddPart(component)">
            <PlusOutlined/>
            添加配件
          </a-button>
        </div>
      </template>
      <a-divider style="background-color: #888;margin: 5px 0;"/>
      <div>
        <a-button type="dashed" @click="onAddComponent(updateKit)">
          <PlusOutlined/>
          添加元件
        </a-button>
      </div>
      <div style="text-align: center;margin-top: 10px;">
        <a-button type="primary" @click="onUpdateKit" :loading="updateKit.submitting">
          更新
        </a-button>
        <a-button type="primary" @click="resetUpdateKit" style="margin-left:20px;">
          重填
        </a-button>
        <a-button type="primary" @click="goReplace" style="margin-left: 20px;">仅查看</a-button>
        <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
      </div>
      <p style="color:red;">
        备注：保存后会自动计算成本价、重量。(若有包装或配件缺少成本价、重量，则无法自动计算)
      </p>
    </a-spin>
  </div>
</template>

<script>
  import {getBoxes} from "@/api/box";
  import {getKit2, updateKit2, getKitNames} from "@/api/kit";
  import {cloneDeep} from "@/utils/util";
  import {searchComponents, getComponent, getComponentNames} from "@/api/component";
  import {searchParts, getPart} from "@/api/part";
  import {message, Modal} from 'ant-design-vue';
  import {
    MinusCircleOutlined,
    PlusOutlined,
  } from '@ant-design/icons-vue';

  export default {
    name: "kit-update",
    components: {
      MinusCircleOutlined,
      PlusOutlined,
    },
    data() {
      return {
        boxes: {},

        boxSelector: {
          options: [],
          loading: false,
        },

        kit: null,
        updateKit: {
          loading: false,
          options: [],
          data: {
            id: '',
            kit_no: '',
            kit_name: '',
            kit_name_2: '',
            price_1: null,
            price_2: null,
            price_3: null,
            price_4: null,
            weight: null,
            weight_unit: '',
            spec: '',
            order: null,
            box_no: null,
            components: [
              {
                data: {
                  index: '',
                  component_no: '',
                  component_name: '',
                  component_name_2: '',
                  count: '',
                  parts: [
                    {
                      data: {
                        order: '',
                        index: '',
                        part_no: '',
                        part_name: '',
                        spec: '',
                        count: '',
                        remark: '',
                      },
                      options: [],
                      loading: false,
                    }
                  ],
                },
                options: [],
                loading: false,
              },
            ],
          },
          submitting: false,
        },
        columns: [
          {
            title: '排序',
            dataIndex: 'order',
            width: '80px',
          },
          {
            title: '序号',
            dataIndex: 'index',
            width: '80px',
          },
          {
            title: '原厂号码',
            dataIndex: 'part_no',
            width: '20%',
          },
          {
            title: '名称',
            dataIndex: 'part_name',
            width: '20%',
          },
          {
            title: '规格',
            dataIndex: 'spec',
            width: '20%',
          },
          {
            title: '数量',
            dataIndex: 'count',
            width: '80px',
          },
          {
            title: '用处备注',
            dataIndex: 'remark',
            //width:'15%',
          },
          {
            title: '取消',
            dataIndex: 'operation',
            width: '45px',
          }
        ],

        kitNames: [],
        componentNames: [],
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      kitNames2() {
        return this.kitNames.map(item => {
          return {value: item.kit_name, label: item.kit_name, kit_name_2: item.kit_name_2};
        })
      },
      componentNames2() {
        return this.componentNames.map(item => {
          return {value: item.component_name, label: item.component_name, component_name_2: item.component_name2};
        })
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({kit_no}) {
        this.getBoxes();
        this.getKit2({kit_no: kit_no});
        this.isGoBack = false;
        this.getKitNames();
        this.getComponentNames();
      },
      getBoxes() {
        this.boxSelector.loading = true;
        getBoxes().then(res => {
          res.data.boxes.forEach(item => {
            this.boxes[item.box_no] = item;
          });
          this.boxSelector.options = res.data.boxes.map(item => {
            return {
              label: item.box_no,
              value: item.box_no,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.boxSelector.loading = false;
        });
      },
      getKit2(params) {
        this.updateKit.loading = true;
        getKit2(params).then(res => {
          this.kit = JSON.parse(JSON.stringify(res.data.kit));

          this.updateKit.data.id = res.data.kit.id;
          this.updateKit.data.kit_no = res.data.kit.kit_no;
          this.updateKit.data.kit_name = res.data.kit.kit_name;
          this.updateKit.data.kit_name_2 = res.data.kit.kit_name_2;
          this.updateKit.data.price_1 = res.data.kit.price_1;
          this.updateKit.data.price_2 = res.data.kit.price_2;
          this.updateKit.data.price_3 = res.data.kit.price_3;
          this.updateKit.data.price_4 = res.data.kit.price_4;
          this.updateKit.data.weight = res.data.kit.weight;
          this.updateKit.data.weight_unit = res.data.kit.weight_unit;
          this.updateKit.data.spec = res.data.kit.spec;
          this.updateKit.data.order = res.data.kit.order;
          this.updateKit.data.box_no = res.data.kit.box && res.data.kit.box.box_no;

          this.updateKit.data.components = res.data.kit.components.map(item => {
            item.parts = item.parts.map(item2 => {
              return {
                data: item2,
                options: [],
                loading: false,
              };
            });
            return {
              data: item,
              options: [],
              loading: false,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updateKit.loading = false;
        });
      },
      getKitNames() {
        getKitNames().then(res => {
          this.kitNames = res.data.kit_names;
        }).catch(err => {

        }).finally(() => {

        });
      },
      getComponentNames() {
        getComponentNames().then(res => {
          this.componentNames = res.data.component_names;
        }).catch(err => {

        }).finally(() => {

        });
      },
      filterOption(input, option) {
        return input ? option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0 : true;
      },

      onComponentNameSearch(component) {
        let params = {
          component_no: component.data.component_no,
        };

        if (component.timer) {
          clearTimeout(component.timer);
        }

        component.timer = setTimeout(() => {
          component.loading = true;
          searchComponents(params).then(res => {
            if (component.data.component_no !== params.component_no) {
              return;
            }
            component.options = res.data.components.map(item => {
              return {value: item.component_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            component.loading = false;
          });
        }, 500);
      },
      onComponentNameSelect(component) {
        let params = {
          component_no: component.data.component_no,
        };
        component.loading = true;
        getComponent(params).then(res => {
          component.data.component_no = res.data.component.component_no;
          component.data.component_name = res.data.component.component_name;
          component.data.component_name_2 = res.data.component.component_name_2;
          component.data.parts = res.data.component.parts.map(item => {
            return {
              data: item,
              options: [],
              loading: false,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          component.loading = false;
        });
      },
      onPartNameSearch(part) {
        let params = {
          part_no: part.data.part_no,
        };

        if (part.timer) {
          clearTimeout(part.timer);
        }

        part.timer = setTimeout(() => {
          part.loading = true;
          searchParts(params).then(res => {
            if (part.data.part_no !== params.part_no) {
              return;
            }
            part.options = res.data.parts.map(item => {
              return {value: item.part_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            part.loading = false;
          });
        }, 500);
      },
      onPartNameSelect(part) {
        let params = {
          part_no: part.data.part_no,
        };
        part.loading = true;
        getPart(params).then(res => {
          part.data.part_no = res.data.part.part_no;
          part.data.part_name = res.data.part.part_name;
          part.data.spec = res.data.part.spec;
          //part.data.remark = res.data.part.remark;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          part.loading = false;
        });
      },
      onAddComponent(kit) {
        kit.data.components.push({
          data: {
            index: '',
            component_no: '',
            component_name: '',
            component_name_2: '',
            count: 1,
            parts: [
              {
                data: {
                  order: '',
                  index: '',
                  part_no: '',
                  part_name: '',
                  spec: '',
                  count: '',
                  remark: '',
                },
                options: [],
                loading: false,
              }
            ],
          },
          options: [],
          loading: false,
        });
      },
      onAddPart(component) {
        component.data.parts.push({
          data: {
            order: '',
            index: '',
            part_no: '',
            part_name: '',
            spec: '',
            count: '',
            remark: '',
          },
          options: [],
          loading: false,
        });
      },
      onRemoveComponent(kit, c_index) {
        kit.data.components.splice(c_index, 1);
      },
      onRemovePart(component, p_index) {
        component.data.parts.splice(p_index, 1);
      },
      onUpdateKit() {
        //检查是不有空以及 TODO重复
        let kit = this.updateKit;
        if (this.checkEmpty(kit.data.kit_no, '修理包KIT NO.不能空') || this.checkEmpty(kit.data.kit_name, '修理包KIT NAME不能空') || this.checkEmpty(kit.data.kit_name_2, '修理包名称（中文）不能空') || this.checkEmpty(kit.data.order, '修理包排序不能空')) {
          return;
        }
        for (let i = 0; i < kit.data.components.length; i++) {
          let component = kit.data.components[i].data;
          if (this.checkEmpty(component.index, '第' + (i + 1) + '个元件序号不能空') || this.checkEmpty(component.component_no, '第' + (i + 1) + '个元件编号不能空') || this.checkEmpty(component.component_name, '第' + (i + 1) + '个元件名称不能空') || this.checkEmpty(component.component_name_2, '第' + (i + 1) + '个元件名称（中文）不能空') || this.checkEmpty(component.count, '第' + (i + 1) + '个元件数量不能空')) {
            return;
          }
          for (let j = 0; j < component.parts.length; j++) {
            let part = component.parts[j].data;
            if (this.checkEmpty(part.order, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件排序不能空') || this.checkEmpty(part.index, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件序号不能空') || this.checkEmpty(part.part_no, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件原厂号码不能空') || this.checkEmpty(part.part_name, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件名称不能空') || this.checkEmpty(part.spec, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件规格不能空') || this.checkEmpty(part.count, '第' + (i + 1) + '个元件 第' + (j + 1) + '个配件数量不能空')) {
              return;
            }
          }
        }

        let params = {
          kit: JSON.parse(JSON.stringify(kit.data)),
        };
        params.kit.components = params.kit.components.map((item, index) => {
          //item.data.index = index + 1;
          item.data.parts = item.data.parts.map(item2 => item2.data);
          return item.data;
        })
        kit.submitting = true;
        updateKit2(params).then(res => {
          Modal.success({
            title: '修理包 ' + params.kit.kit_no + ' 更新成功',
          });
          //清空修理包数据
          this.getKit2({kit_no: this.updateKit.data.kit_no});
        }).catch(err => {
          Modal.error({
            title: '更新修理包失败',
            content: err.message || '',
          });
        }).finally(() => {
          kit.submitting = false;
        });
      },
      resetUpdateKit() {
        let kit = JSON.parse(JSON.stringify(this.kit));

        this.updateKit.data.kit_no = kit.kit_no;
        this.updateKit.data.kit_name = kit.kit_name;
        this.updateKit.data.kit_name_2 = kit.kit_name_2;
        this.updateKit.data.price_1 = kit.price_1;
        this.updateKit.data.price_2 = kit.price_2;
        this.updateKit.data.price_3 = kit.price_3;
        this.updateKit.data.price_4 = kit.price_4;
        this.updateKit.data.weight = kit.weight;
        this.updateKit.data.weight_unit = kit.weight_unit;
        this.updateKit.data.spec = kit.spec;
        this.updateKit.data.order = kit.order;
        this.updateKit.data.box_no = kit.box && kit.box.box_no;

        this.updateKit.data.components = kit.components.map(item => {
          item.parts = item.parts.map(item2 => {
            return {
              data: item2,
              options: [],
              loading: false,
            };
          });
          return {
            data: item,
            options: [],
            loading: false,
          };
        });
      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/kit/detail', query: {kit_no: this.kit.kit_no}});
      },

      onKitNameSelect2(kit) {
        let op = this.kitNames.filter(item => {
          return item.kit_name === kit.data.kit_name;
        });
        if (op.length) {
          kit.data.kit_name_2 = op[0].kit_name_2;
        }
      },
      onComponentNameSelect2(component) {
        let op = this.componentNames.filter(item => {
          return item.component_name === component.data.component_name;
        });
        if (op.length) {
          component.data.component_name_2 = op[0].component_name_2;
        }
      },
    }
  }
</script>

<style scoped>
  .delete-button {
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .delete-button:hover {
    color: #ff4d4f;
  }

  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>