import request from '@/utils/request.js';

export function getBoxes() {
  return request.get('getBoxes');
}

//--------------------------------
export function getBoxes2(data) {
  return request.get('getBoxes2', data);
}

export function getBox2(data) {
  return request.get('getBox2', data);
}

export function deleteBox2(data) {
  return request.post('deleteBox2', data);
}

export function updateBox2(data) {
  return request.post('updateBox2', data);
}

export function addBox2(data) {
  return request.post('addBox2', data);
}

export function getBoxImg(data) {
  return request.get('getBoxImg', data);
}

export function deleteBoxImg(data) {
  return request.post('deleteBoxImg', data);
}

export function searchBoxes(data) {
  return request.get('searchBoxes', data);
}