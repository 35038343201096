<template>
  <div>
    <a-spin :spinning="box.loading">
      <a-image-preview-group v-if="box.data">
        <a-list :grid="{gutter:16,column: 2}" :data-source="box.data.img">
          <template #renderItem="{item,index}">
            <a-list-item>
              <a-card hoverable style="text-align: center;">
                <template #cover>
                  <a-image :src="item" style="max-width: 100%;width:auto;"/>
                </template>
                <template v-if="box.showEdit" #actions>
                  <delete-outlined @click="onDeleteImg(index)"/>
                </template>
              </a-card>
            </a-list-item>
          </template>
        </a-list>
      </a-image-preview-group>
      <div style="text-align: center;">
        <a-upload
          v-if="box.data&&_self&&_self.type==='admin'"
          :showUploadList="false"
          v-model:file-list="box.upload.fileList"
          name="img"
          :multiple="false"
          accept="image/apng,image/bmp,image/gif,image/jpeg,image/pjpeg,image/png,image/svg+xml,image/tiff,image/webp,image/x-icon"
          :headers="box.upload.headers"
          :action="box.upload.action"
          :before-upload="beforeUpload"
          @change="onUploadChange"
          :data="{box_id:box.data.id}"
          :disabled="box.upload.loading"
        >
          <a-button type="dashed" :loading="box.upload.loading">
            <upload-outlined v-if="!box.upload.loading"></upload-outlined>
            上传图片
          </a-button>
        </a-upload>
        <a-button type="primary" @click="onTriggerBoxEdit" style="margin-left: 20px;" v-if="_self&&_self.type==='admin'">{{box.showEdit? '仅查看':'可编辑'}}
        </a-button>
        <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getBoxImg, deleteBoxImg} from "@/api/box";
  import config from '@/config.js';
  import {message, Modal} from 'ant-design-vue';
  import {
    UploadOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
  } from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "box-img",
    components: {
      UploadOutlined,
      DeleteOutlined,
    },
    data() {
      return {
        box: {
          loading: false,
          data: null,
          upload: {
            loading: false,
            headers: {
              'X-Token': this.$store.getters.token,
            },
            fileList: [],
            action: config.baseURL + '/uploadBoxImg',
          },
          showEdit: false,
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({box_id}) {
        this.getBoxImg({box_id: box_id});
        this.isGoBack = false;
      },
      getBoxImg(params) {
        this.box.loading = true;
        getBoxImg(params).then(res => {
          res.data.box.img = res.data.box.img.map(item => {
            return config.staticURL + item;
          });
          this.box.data = res.data.box;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.box.loading = false;
        });
      },
      beforeUpload(file) {
        const isImg = ['image/apng', 'image/bmp', 'image/gif', 'image/jpeg', 'image/pjpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp', 'image/x-icon'].includes(file.type);
        if (!isImg) {
          Modal.warning({
            title: '上传提示',
            content: '只能上传图片文件',
          });
        }
        return isImg;
      },
      onUploadChange(info) {
        if (info.file.status === 'uploading') {
          this.box.upload.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          this.box.upload.loading = false;
          if (info.file.response) {
            if (info.file.response.status === 200) {
              Modal.success({
                title: '图片上传成功',
              });
            } else {
              Modal.error({
                title: '图片上传失败',
                content: info.file.response.message || '',
              });
            }
          }
          this.getBoxImg({box_id: this.box.data.id});
          return;
        }
        if (info.file.status === 'error') {
          this.box.upload.loading = false;
          this.getBoxImg({box_id: this.box.data.id});
          Modal.warning({
            title: '上传提示',
            content: '图片上传失败',
          });
        }

      },
      onDeleteImg(index) {
        let that = this;
        let deleteImgModal = Modal.confirm({
          title: '确定要删除图片？',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteImgModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteBoxImg({
              box_id: that.box.data.id,
              img_index: index
            }).then(() => {
              Modal.success({
                title: '图片删除成功',
              });
              that.getBoxImg({box_id: that.box.data.id});
            }).catch(err => {
              Modal.error({
                title: '图片删除失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      onTriggerBoxEdit() {
        this.box.showEdit = !this.box.showEdit;
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },

    },
  }
</script>

<style scoped>

</style>