<template>
  <div>
    <div style="padding-bottom: 4px;">
      <a-auto-complete
        v-model:value="modelsList.search_key"
        :options="modelsList.options"
        style="width: 500px"
        placeholder="编号或系列"
        @search="onModelNoSearch(modelsList)"
        :loading="modelsList.optionsLoading"
      >
        <template #option="item">
          <span v-html="item.label_html"></span>
        </template>
      </a-auto-complete>
      <a-button type="primary" @click="onSearchKey">搜索</a-button>
      <a-button style="float: right;" type="primary" @click="onShowAddModelModal" v-if="_self&&_self.type==='admin'">
        新增机型
      </a-button>
      <div style="float: right;margin-right: 20px;" v-if="_self&&_self.type==='admin'">
        <a-upload
          :showUploadList="false"
          v-model:file-list="upload.fileList"
          name="excel"
          :multiple="false"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :headers="upload.headers"
          :action="upload.action"
          :before-upload="beforeUpload"
          @change="onUploadChange"
          :disabled="upload.loading"
        >
          <a-button type="dashed" :loading="upload.loading">
            <upload-outlined v-if="!upload.loading"></upload-outlined>
            Excel批量导入机型
          </a-button>
        </a-upload>
      </div>
    </div>
    <a-list class="models-list" :data-source="modelsList.data" :loading="modelsList.loading" size="small" bordered :pagination="modelsList.pagination">
      <template #renderItem="{ item }">
        <a-list-item class="item">
          <template #actions>
            <a-button type="primary" @click="goTo2(item.model_no,'pdf')" size="small">文件</a-button>
            <a-button type="primary" @click="goTo2(item.model_no,'img')" size="small">图片</a-button>
            <a-button type="primary" @click="onShowUpdateModelModal(item)" size="small" v-if="_self&&_self.type==='admin'">
              修改
            </a-button>
            <a-button danger type="primary" @click="onDeleteModel(item)" size="small" v-if="_self&&_self.type==='admin'">
              删除
            </a-button>
          </template>
          <a-list-item-meta class="item-meta" :description="'系列：'+item.serial+(item.brand_no? '，品牌：'+item.brand_no:'')+(item.nick_name? '，别名：'+item.nick_name:'')" hover @click="goTo(item.brand_no,item.model_no)">
            <template #title>
              {{ item.model_no }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <div style="color:#aaa;font-size: 12px;padding:3px 0;">
      备注：Excel批量导入机型，只支持Excel文件（*.xlsx或*.xls）；文件格式严格按照（品牌编号，机型编号，机型系列，别名）的结构来；如机型编号在数据库中已有记录，则不导入该条数据。
    </div>
    <a-modal v-model:visible="addModelModal.visible" title="新增机型" :closable="true" :maskClosable="false" @cancel="onCloseAddModelModal" :footer="null">
      <a-form>
        <a-form-item label="品牌编号" name="brand_no">
          <a-auto-complete
            v-model:value="addModelModal.data.brand_no"
            :options="addModelModal.options"
            @search="onBrandNoSearch(addModelModal)"
            :loading="addModelModal.options_loading"/>
        </a-form-item>
        <a-form-item label="机型编号" name="model_no">
          <a-input v-model:value="addModelModal.data.model_no"/>
        </a-form-item>
        <a-form-item label="机型系列" name="serial">
          <a-auto-complete
            v-model:value="addModelModal.data.serial"
            :options="addModelModal.options2"
            @search="onModelSerialSearch(addModelModal)"
            :loading="addModelModal.options_loading2"/>
        </a-form-item>
        <a-form-item label="机型别名" name="nick_name">
          <a-auto-complete
            v-model:value="addModelModal.data.nick_name"
            :options="addModelModal.options3"
            @search="onModelNickNameSearch(addModelModal)"
            :loading="addModelModal.options_loading3"/>
        </a-form-item>
        <a-form-item label="机型排序" name="order" extra="排序号越大，机型越靠前">
          <a-input v-model:value="addModelModal.data.order"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onAdd" :loading="addModelModal.loading">新增</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="updateModelModal.visible" title="更新机型" :closable="true" :maskClosable="false" @cancel="onCloseUpdateModelModal" :footer="null">
      <a-form>
        <a-form-item label="品牌编号" name="brand_no">
          <a-input v-model:value="updateModelModal.data.brand_no"/>
        </a-form-item>
        <a-form-item label="机型编号" name="model_no">
          <a-input v-model:value="updateModelModal.data.model_no"/>
        </a-form-item>
        <a-form-item label="机型系列" name="serial">
          <a-input v-model:value="updateModelModal.data.serial"/>
        </a-form-item>
        <a-form-item label="机型别名" name="nick_name">
          <a-input v-model:value="updateModelModal.data.nick_name"/>
        </a-form-item>
        <a-form-item label="机型排序" name="order" extra="排序号越大，机型越靠前">
          <a-input v-model:value="updateModelModal.data.order"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onUpdateModel" :loading="updateModelModal.loading">更新</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {getModels2, deleteModel2, updateModel2, addModel, searchModels, searchSerials, searchNickNames} from "@/api/model";
  import {searchBrands} from "@/api/brand";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined, UploadOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';
  import config from '@/config.js';

  export default {
    name: "model",
    components: {
      UploadOutlined,
    },
    data() {
      return {
        modelsList: {
          data: [],
          loading: false,
          search_key: '',
          options: [],
          options_loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
        updateModelModal: {
          visible: false,
          loading: false,
          data: {
            id: '',
            brand_no: '',
            model_no: '',
            serial: '',
            nick_name: '',
            order: null,
          }
        },
        addModelModal: {
          visible: false,
          loading: false,
          options: [],
          options_loading: false,
          options2: [],
          options_loading2: false,
          options3: [],
          options_loading3: false,
          data: {
            brand_no: '',
            model_no: '',
            serial: '',
            nick_name: '',
            order: 0,
          }
        },

        upload: {
          loading: false,
          headers: {
            'X-Token': this.$store.getters.token,
          },
          fileList: [],
          action: config.baseURL + '/uploadModels',
        },
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        let params = {
          search_key: '',
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        this.getModels2(params);
      },
      getModels2(params) {
        this.modelsList.loading = true;
        getModels2(params).then(res => {
          this.modelsList.data = res.data.models;
          this.modelsList.search_key = res.data.search_key;
          this.modelsList.pagination.current = res.data.current;
          this.modelsList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.modelsList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          search_key: this.modelsList.search_key,
          current: page,
        };
        this.getModels2(params);
      },
      onSearchKey() {
        let params = {
          search_key: this.modelsList.search_key,
          current: 1,
        };
        this.getModels2(params);
      },

      onShowAddModelModal() {
        this.addModelModal.visible = true;
      },
      onAdd() {
        let model = this.addModelModal.data;
        if (this.checkEmpty(model.brand_no, '品牌编号不能空') || this.checkEmpty(model.model_no, '机型编号不能空') || this.checkEmpty(model.serial, '机型系列不能空') || this.checkEmpty(model.order, '机型排序不能空')) {
          return;
        }
        let params = {
          model: model,
        };
        this.addModel(params);
      },
      addModel(params) {
        this.addModelModal.loading = true;
        addModel(params).then(() => {
          Modal.success({
            title: '机型 ' + params.model.model_no + ' 新增成功',
          });
          this.addModelModal.visible = false;
          this.getModels2({search_key: this.modelsList.search_key, current: this.modelsList.pagination.current});
          this.addModelModal.data.brand_no = '';
          this.addModelModal.data.model_no = '';
          this.addModelModal.data.serial = '';
          this.addModelModal.data.nick_name = '';
          this.addModelModal.data.order = 0;
        }).catch(err => {
          Modal.error({
            title: '新增机型失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.addModelModal.loading = false;
        });
      },
      onCloseAddModelModal() {
        this.addModelModal.visible = false;
      },


      onShowUpdateModelModal(item) {
        this.updateModelModal.visible = true;
        this.updateModelModal.data.id = item.id;
        this.updateModelModal.data.brand_no = item.brand_no;
        this.updateModelModal.data.model_no = item.model_no;
        this.updateModelModal.data.serial = item.serial;
        this.updateModelModal.data.nick_name = item.nick_name;
        this.updateModelModal.data.order = item.order;
      },
      onCloseUpdateModelModal() {
        this.updateModelModal.visible = false;
      },
      onUpdateModel() {
        let model = this.updateModelModal.data;
        if (this.checkEmpty(model.brand_no, '品牌编号不能空') || this.checkEmpty(model.model_no, '机型编号不能空') || this.checkEmpty(model.serial, '机型系列不能空') || this.checkEmpty(model.order, '机型排序不能空')) {
          return;
        }
        let params = {
          model: this.updateModelModal.data,
        };
        this.updateModelModal.loading = true;
        updateModel2(params).then(res => {
          Modal.success({
            title: '机型 ' + params.model.model_no + ' 更新成功',
          });
          this.updateModelModal.visible = false;
          this.getModels2({search_key: this.modelsList.search_key, current: this.modelsList.pagination.current});
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updateModelModal.loading = false;
        });
      },
      onDeleteModel(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除机型 ' + item.model_no + ' ？',
          content: '若有品牌拥有该机型，则品牌也会联动取消该机型！',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteModel2({model_no: item.model_no}).then(() => {
              Modal.success({
                title: '机型' + item.model_no + '删除成功',
              });
              that.getModels2({search_key: that.modelsList.search_key, current: that.modelsList.pagination.current});
            }).catch(err => {
              Modal.error({
                title: '删除机型' + item.model_no + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },

      onBrandNoSearch(model) {
        let params = {
          brand_no: model.data.brand_no,
        };

        if (model.timer) {
          clearTimeout(model.timer);
        }

        model.timer = setTimeout(() => {
          model.options_loading = true;
          searchBrands(params).then(res => {
            if (model.data.brand_no !== params.brand_no) {
              return;
            }
            model.options = res.data.brands.map(item => {
              return {value: item.brand_no};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            model.options_loading = false;
          });
        }, 500);
      },
      onModelSerialSearch(model) {
        let params = {
          serial: model.data.serial,
        };

        if (model.timer2) {
          clearTimeout(model.timer2);
        }

        model.timer2 = setTimeout(() => {
          model.options_loading2 = true;
          searchSerials(params).then(res => {
            if (model.data.serial !== params.serial) {
              return;
            }
            model.options2 = res.data.serials.map(item => {
              return {value: item.serial};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            model.options_loading2 = false;
          });
        }, 500);
      },

      onModelNickNameSearch(model) {
        let params = {
          nick_name: model.data.nick_name,
        };

        if (model.timer3) {
          clearTimeout(model.timer3);
        }

        model.timer3 = setTimeout(() => {
          model.options_loading3 = true;
          searchNickNames(params).then(res => {
            if (model.data.nick_name !== params.nick_name) {
              return;
            }
            model.options3 = res.data.nick_names.map(item => {
              return {value: item.nick_name};
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            model.options_loading3 = false;
          });
        }, 500);
      },

      goTo(brand_no, model_no) {
        this.params = {search_key: this.modelsList.search_key, current: this.modelsList.pagination.current};
        this.$router.push({path: '/brand/model-kit?', query: {brand_no: brand_no, model_no: model_no}});
      },


      beforeUpload(file) {
        const isExcel = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type);
        if (!isExcel) {
          Modal.warning({
            title: '上传提示',
            content: '只能Excel文件,扩展名为xlsx',
          });
        }
        return isExcel;
      },
      onUploadChange(info) {
        if (info.file.status === 'uploading') {
          this.upload.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          this.upload.loading = false;
          console.log(info.file);
          if (info.file.response) {
            if (info.file.response.status === 200) {
              Modal.success({
                title: 'Excel批量导入成功',
                content: '成功插入 ' + info.file.response.data.affected + ' 条数据',
              });
              let params = {
                search_key: this.modelsList.search_key,
                current: this.modelsList.pagination.current,
              };
              this.getModels2(params);
            } else {
              Modal.error({
                title: 'Excel批量导入失败',
                content: info.file.response.message || '',
              });
            }
          }
          return;
        }
        if (info.file.status === 'error') {
          this.upload.loading = false;
          Modal.warning({
            title: '上传提示',
            content: 'Excel上传失败',
          });
        }
      },

      goTo2(model_no, type) {
        this.params = {search_key: this.modelsList.search_key, current: this.modelsList.pagination.current};
        switch (type) {
          case 'img':
            this.$router.push({path: '/model/img', query: {model_no: model_no}});
            break;
          case 'pdf':
            this.$router.push({path: '/model/pdf', query: {model_no: model_no}});
            break;
          default:
        }
      },

      onModelNoSearch(modelsList) {
        let params = {
          model_key: modelsList.search_key,
        };

        if (modelsList.timer) {
          clearTimeout(modelsList.timer);
        }

        modelsList.timer = setTimeout(() => {
          modelsList.optionsLoading = true;
          searchModels(params).then(res => {
            if (modelsList.search_key !== params.model_key) {
              return;
            }
            let reg_model_key = new RegExp('(' + params.model_key + ')', "ig");
            modelsList.options = res.data.models.map(item => {
              return {
                value: item.model_no,
                label: item.model_no + ' (' + item.serial + ')',
                label_html: item.model_no.replace(reg_model_key, "<span class='search_key_html'>$1</span>") + ' (' + item.serial.replace(reg_model_key, "<span class='search_key_html'>$1</span>") + ')'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            modelsList.optionsLoading = false;
          });
        }, 500);
      }
    },

  }
</script>
<style>
  .models-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .models-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
  }
</style>
<style scoped>
  .models-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

</style>