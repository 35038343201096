<template>
  <div>
    <a-spin :spinning="updateBox.loading">
      <span class="my-label">包装编号：</span>
      <a-input v-model:value="updateBox.data.box_no" style="width:200px;" placeholder="包装编号"/>
      <br>
      <span class="my-label">包装名称：</span>
      <a-input v-model:value="updateBox.data.box_name" placeholder="包装名称" style="width:200px;"/>
      <span class="my-label2">包装名称（中文）：</span>
      <a-input v-model:value="updateBox.data.box_name_2" placeholder="包装名称（中文）" style="width:200px;"/>
      <br>

      <span class="my-label">成本价：</span>
      <a-input-number v-model:value="updateBox.data.price_1" style="width:200px;" placeholder="成本价"/>
      <span class="my-label2">规格：</span>
      <a-input v-model:value="updateBox.data.spec" style="width:200px;" placeholder="规格"/>
      <br>
      <span class="my-label">重量：</span>
      <a-input-number v-model:value="updateBox.data.weight" style="width:200px;" placeholder="重量"/>
      <span class="my-label2">重量单位：</span>
      <a-input v-model:value="updateBox.data.weight_unit" style="width:200px;" placeholder="重量单位" disabled/>
      <br>
      <span class="my-label">排序：</span>
      <a-input-number v-model:value="updateBox.data.order" style="width:200px;" placeholder="排序"/>
      <br>

      <div style="text-align: center;margin-top: 10px;">
        <a-button type="primary" @click="onUpdateBox" :loading="updateBox.submitting">
          更新
        </a-button>
        <a-button type="primary" @click="resetUpdateBox" style="margin-left: 20px;">
          重填
        </a-button>
        <a-button type="primary" @click="goReplace" style="margin-left: 20px;">仅查看</a-button>
        <a-button type="primary" @click="goBack" style="margin-left: 20px;">返回</a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {getBox2, updateBox2} from "@/api/box";
  import {cloneDeep} from "@/utils/util";
  import {message, Modal} from 'ant-design-vue';

  export default {
    name: "box-update",
    data() {
      return {
        box: null,
        updateBox: {
          loading: false,
          options: [],
          data: {
            id:null,
            box_no: '',
            box_name: '',
            box_name_2: '',
            price_1: null,
            weight: null,
            weight_unit: '',
            spec: '',
            order: null,
          },
          submitting: false,
        },
      };
    },
    computed: {
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init({box_id}) {
        this.getBox2({box_id: box_id});
        this.isGoBack = false;
      },
      getBox2(params) {
        this.updateBox.loading = true;
        getBox2(params).then(res => {
          this.box = JSON.parse(JSON.stringify(res.data.box));

          this.updateBox.data.id=res.data.box.id;
          this.updateBox.data.box_no = res.data.box.box_no;
          this.updateBox.data.box_name = res.data.box.box_name;
          this.updateBox.data.box_name_2 = res.data.box.box_name_2;
          this.updateBox.data.price_1 = res.data.box.price_1;
          this.updateBox.data.weight = res.data.box.weight;
          this.updateBox.data.weight_unit = res.data.box.weight_unit;
          this.updateBox.data.spec = res.data.box.spec;
          this.updateBox.data.order = res.data.box.order;

        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.updateBox.loading = false;
        });
      },
      onUpdateBox() {
        //检查是不有空以及 TODO重复
        let box = this.updateBox;
        if (this.checkEmpty(box.data.box_no, '包装编号不能空') || this.checkEmpty(box.data.box_name, '包装名称不能空') || this.checkEmpty(box.data.box_name_2, '包装名称（中文）不能空') || this.checkEmpty(box.data.order, '包装排序不能空')) {
          return;
        }

        let params = {
          box: JSON.parse(JSON.stringify(box.data)),
        };

        box.submitting = true;
        updateBox2(params).then(res => {
          Modal.success({
            title: '包装 ' + params.box.box_no + ' 更新成功',
          });
          //清空配件数据
          this.getBox2({box_id: this.updateBox.data.id});
        }).catch(err => {
          Modal.error({
            title: '更新包装失败',
            content: err.message || '',
          });
        }).finally(() => {
          box.submitting = false;
        });
      },
      resetUpdateBox() {
        let box = JSON.parse(JSON.stringify(this.box));

        this.updateBox.data.box_no = box.box_no;
        this.updateBox.data.box_name = box.box_name;
        this.updateBox.data.box_name_2 = box.box_name_2;
        this.updateBox.data.price_1 = box.price_1;
        this.updateBox.data.weight = box.weight;
        this.updateBox.data.weight_unit = box.weight_unit;
        this.updateBox.data.spec = box.spec;
        this.updateBox.data.order = box.order;

      },
      checkEmpty(value, message) {
        if (value === '' || value === null || value === undefined) {
          Modal.warning({
            title: message,
          });
          return true;
        }
        return false;
      },
      goBack() {
        this.isGoBack = true;
        this.$router.back();
      },
      goReplace() {
        this.$router.replace({'path': '/box/detail', query: {box_id: this.box.id}});
      },
    }
  }
</script>

<style scoped>
  .delete-button {
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }

  .delete-button:hover {
    color: #ff4d4f;
  }

  .my-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    padding: 6px 0;
  }

  .my-label2 {
    display: inline-block;
    width: 150px;
    text-align: right;
  }

  .my-text {
    display: inline-block;
    width: 200px;
  }
</style>