<template>
  <div>
    <div style="padding-bottom: 4px;">
      <a-select
        show-search
        v-model:value="brandSelector.value"
        :options="brandSelector.options"
        style="width: 200px"
        placeholder="选择品牌"
        :loading="brandSelector.loading"
        allowClear
      ></a-select>
      <a-auto-complete
        v-model:value="partsList.search_key"
        :options="partsList.options"
        style="width: 350px"
        placeholder="编号、名称、规格"
        @search="onPartNoSearch(partsList)"
        :loading="partsList.optionsLoading"
      >
        <template #option="item">
          <span v-html="item.label_html"></span>
        </template>
      </a-auto-complete>
      <a-button type="primary" @click="onSearchKey">搜索</a-button>
      <a-button style="float: right;" type="primary" @click="goTo(null,'add')" v-if="_self&&_self.type==='admin'">新增配件
      </a-button>
      <div style="float: right;margin-right: 20px;" v-if="_self&&_self.type==='admin'">
        <a-upload
          :showUploadList="false"
          v-model:file-list="upload.fileList"
          name="excel"
          :multiple="false"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :headers="upload.headers"
          :action="upload.action"
          :before-upload="beforeUpload"
          @change="onUploadChange"
          :disabled="upload.loading"
        >
          <a-button type="dashed" :loading="upload.loading">
            <upload-outlined v-if="!upload.loading"></upload-outlined>
            Excel批量导入配件
          </a-button>
        </a-upload>
      </div>
      <a-button type="dashed" style="float:right;margin-right: 10px;" v-if="brandSelector.value&&_self&&(_self.type==='admin'||(_self.type==='user'&&_self.qx.download))">
        <a :href="downloadBaseUrl +'&brand_no='+brandSelector.value" target="_blank">
          <download-outlined/>
          按品牌导出配件</a>
      </a-button>
      <!--
      <a-button style="float: right;margin-right: 10px;" type="primary" @click="autoReplacement" :loading="autoReplacementing">
        计算代替件
      </a-button>
      -->
    </div>
    <a-list class="parts-list" :data-source="partsList.data" :loading="partsList.loading" size="small" bordered :pagination="partsList.pagination">
      <template #renderItem="{ item }">
        <a-list-item class="item">
          <template #actions>
            <a-button type="primary" @click="goTo(item.part_no,'img')" size="small">图片</a-button>
            <a-button type="primary" @click="goTo(item.part_no,'update')" size="small" v-if="_self&&_self.type==='admin'">
              修改
            </a-button>
            <a-button danger type="primary" @click="onDeletePart(item)" size="small" v-if="_self&&_self.type==='admin'">
              删除
            </a-button>
          </template>
          <a-list-item-meta class="item-meta" :description="'名称：'+item.part_name+(item.part_name_2? '，中文名称：'+item.part_name_2:'')+(item.spec? '，规格：'+item.spec:'')+(item.material? '，材质：'+item.material:'')+(item.remark? '，备注：'+item.remark:'')" hover @click="goTo(item.part_no,'detail')">
            <template #title>
              {{ item.part_no }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>

    <div style="color:#aaa;font-size: 12px;padding:3px 0;">
      备注：Excel批量导入配件，只支持Excel文件（*.xlsx或*.xls）；文件格式严格按照（原厂号码，名称，规格，材质，成本价，批发价，零售价，特殊价，备注）的结构来；如原厂号码在数据库中已有记录，则不导入该条数据。
    </div>
  </div>
</template>

<script>
  import {getBrands} from "../../api/brand";
  import {getParts2, deletePart2, autoReplacement, searchParts} from "@/api/part";
  import {message, Modal} from 'ant-design-vue';
  import {ExclamationCircleOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';
  import config from '@/config.js';

  export default {
    name: "part",
    components: {
      UploadOutlined,
      DownloadOutlined,
    },
    data() {
      return {
        downloadBaseUrl: config.baseURL + '/downloadPartsByBrand?token=' + this.$store.getters.token,

        brandSelector: {
          value: undefined,
          options: [],
          loading: false,
        },

        partsList: {
          data: [],
          loading: false,
          search_key: '',
          options: [],
          options_loading: false,
          pagination: {
            position: 'bottom',
            pageSize: 20,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `总共${total}条数据`,
            onChange: this.onChange,
            size: 'small',
            showSizeChanger: false,
          },
        },
        upload: {
          loading: false,
          headers: {
            'X-Token': this.$store.getters.token,
          },
          fileList: [],
          action: config.baseURL + '/uploadParts',
        },

        autoReplacementing: false,
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
      params: {
        get() {
          return this.$store.state.bus.params;
        },
        set(newValue) {
          this.$store.commit('bus/setParams', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        this.getBrands();
        let params = {

          search_key: '',
          current: 1,
        };
        if (this.isGoBack && this.params) {
          params = {
            search_key: this.params.search_key || '',
            current: this.params.current || 1,
          };
        }
        this.isGoBack = false;
        this.getParts2(params);

      },
      getBrands() {
        this.brandSelector.loading = true;
        getBrands().then(res => {
          this.brandSelector.options = res.data.brands.map(item => {
            return {
              label: item.brand_no,
              value: item.brand_no,
            };
          });
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.brandSelector.loading = false;
        });
      },
      getParts2(params) {
        this.partsList.loading = true;
        getParts2(params).then(res => {
          this.partsList.data = res.data.parts;
          this.partsList.search_key = res.data.search_key;
          this.partsList.pagination.current = res.data.current;
          this.partsList.pagination.total = res.data.total;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.partsList.loading = false;
        });
      },
      onChange(page) {
        let params = {
          brand_no: this.brandSelector.value,
          search_key: this.partsList.search_key,
          current: page,
        };
        this.getParts2(params);
      },
      onSearchKey() {
        let params = {
          brand_no: this.brandSelector.value,
          search_key: this.partsList.search_key,
          current: 1,
        };
        this.getParts2(params);
      },
      goTo(part_no, type) {
        this.params = {search_key: this.partsList.search_key, current: this.partsList.pagination.current};
        switch (type) {
          case 'detail':
            this.$router.push({path: '/part/detail', query: {part_no: part_no}});
            break;
          case 'update':
            this.$router.push({path: '/part/update', query: {part_no: part_no}});
            break;
          case 'img':
            this.$router.push({path: '/part/img', query: {part_no: part_no}});
            break;
          case 'add':
            this.$router.push({path: '/part/add'});
            break;
          default:
        }
      },
      onDeletePart(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除配件 ' + item.part_no + ' ？',
          content: '若有元件拥有该配件，则元件也会联动取消该配件！',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deletePart2({part_no: item.part_no}).then(() => {
              Modal.success({
                title: '配件 ' + item.part_no + ' 删除成功',
              });
              that.getParts2({search_key: that.partsList.search_key, current: that.partsList.pagination.current});
            }).catch(err => {
              Modal.error({
                title: '删除配件 ' + item.part_no + ' 失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },

      beforeUpload(file) {
        const isExcel = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type);
        if (!isExcel) {
          Modal.warning({
            title: '上传提示',
            content: '只能Excel文件,扩展名为xlsx',
          });
        }
        return isExcel;
      },
      onUploadChange(info) {
        if (info.file.status === 'uploading') {
          this.upload.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          this.upload.loading = false;
          console.log(info.file);
          if (info.file.response) {
            if (info.file.response.status === 200) {
              Modal.success({
                title: 'Excel批量导入成功',
                content: '成功插入 ' + info.file.response.data.affected + ' 条数据',
              });
              let params = {
                search_key: this.partsList.search_key,
                current: this.partsList.pagination.current,
              };
              this.getParts2(params);
            } else {
              Modal.error({
                title: 'Excel批量导入失败',
                content: info.file.response.message || '',
              });
            }
          }
          return;
        }
        if (info.file.status === 'error') {
          this.upload.loading = false;
          Modal.warning({
            title: '上传提示',
            content: 'Excel上传失败',
          });
        }
      },

      autoReplacement() {
        this.autoReplacementing = true;
        autoReplacement().then(res => {
          Modal.success({
            title: '自动计算完成',
          });
        }).catch(err => {
          Modal.error({
            title: '自动计算失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.autoReplacementing = false;
        });
      },
      onPartNoSearch(list) {
        let params = {
          part_no: list.search_key,
        };

        if (list.timer) {
          clearTimeout(list.timer);
        }

        list.timer = setTimeout(() => {
          list.optionsLoading = true;
          searchParts(params).then(res => {
            if (list.search_key !== params.part_no) {
              return;
            }
            let reg_key = new RegExp('(' + params.part_no + ')', "ig");
            list.options = res.data.parts.map(item => {
              return {
                value: item.part_no,
                label: item.part_no + ' (' + item.part_name + ')' + ' [' + item.spec + ']',
                label_html: item.part_no.replace(reg_key, "<span class='search_key_html'>$1</span>") + ' (' + item.part_name.replace(reg_key, "<span class='search_key_html'>$1</span>") + ')' + ' [' + item.spec.replace(reg_key, "<span class='search_key_html'>$1</span>") + ']'
              };
            });
          }).catch(err => {
            message.error(err.message ? err.message : '未知错误', 5);
          }).finally(() => {
            list.optionsLoading = false;
          });
        }, 500);
      }
    },

  }
</script>
<style>
  .parts-list .ant-list-item-meta-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .parts-list .ant-list-item-meta-description {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
  }
</style>
<style scoped>
  .parts-list {
    background-color: #fff;
  }

  .item:hover {
    background-color: #e6f7ff;
  }

  .item-meta {
    cursor: pointer;
  }

</style>