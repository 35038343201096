<template>
  <div style="padding: 11px;">
    <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6, xxxl: 8}" :data-source="brandsList.data" :loading="brandsList.loading">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-card hoverable>
            <a-card-meta @click="goToModelKit(item.brand_no)">
              <template #title>{{item.brand_no}}</template>
            </a-card-meta>
            <template v-if="brandsList.showEdit" #actions>
              <edit-outlined key="update" @click="onShowUpdateBrandModal(item)"/>
              <delete-outlined key="delete" @click="onDelete(item)"/>
            </template>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
    <div class="bottom-button" v-if="_self&&_self.type==='admin'">
      <a-button type="primary" @click="onShowAddBrandModal">新增品牌</a-button>
      <a-button v-if="brandsList.data.length" type="primary" @click="onTriggerEdit" style="margin-left: 5px;">
        {{brandsList.showEdit? '仅查看':'可编辑'}}
      </a-button>
    </div>

    <a-modal v-model:visible="addBrandModal.visible" title="新增品牌" :closable="true" :maskClosable="false" @cancel="onCloseAddBrandModal" :footer="null">
      <a-form>
        <a-form-item label="品牌编号" name="brand_no">
          <a-input v-model:value="addBrandModal.data.brand_no"/>
        </a-form-item>
        <a-form-item label="品牌排序" name="order" extra="排序号越大，品牌越靠前">
          <a-input v-model:value="addBrandModal.data.order"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onAdd" :loading="addBrandModal.loading">新增</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="updateBrandModal.visible" title="更新品牌" :closable="true" :maskClosable="false" @cancel="onCloseUpdateBrandModal" :footer="null">
      <a-form>
        <a-form-item label="品牌编号" name="brand_no">
          <a-input v-model:value="updateBrandModal.data.brand_no"/>
        </a-form-item>
        <a-form-item label="品牌排序" name="order" extra="排序号越大，品牌越靠前">
          <a-input v-model:value="updateBrandModal.data.order"/>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button type="primary" @click.prevent="onUpdate" :loading="updateBrandModal.loading">更新</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {getBrands, addBrand, updateBrand, deleteBrand} from "@/api/brand.js";
  import {message, Modal} from 'ant-design-vue';
  import {EditOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue';
  import {createVNode} from 'vue';

  export default {
    name: "brand",
    components: {
      EditOutlined,
      DeleteOutlined
    },
    data() {
      return {
        brandsList: {
          data: [],
          loading: false,
          showEdit: false,
        },
        addBrandModal: {
          visible: false,
          loading: false,
          data: {
            brand_no: '',
            order: 0,
          }
        },
        updateBrandModal: {
          visible: false,
          loading: false,
          data: {
            id: '',
            brand_no: '',
            order: null,
          }
        }
      };
    },
    computed: {
      _self() {
        return this.$store.state.admin.self;
      },
      isGoBack: {
        get() {
          return this.$store.state.bus.isGoBack;
        },
        set(newValue) {
          this.$store.commit('bus/setIsGoBack', newValue);
        },
      },
    },
    created() {
      this.init(this.$route.query);
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.init(to.query);
        }
      }
    },
    methods: {
      init() {
        this.getBrands();
        this.isGoBack = false;
      },
      getBrands() {
        this.brandsList.loading = true;
        getBrands().then(res => {
          this.brandsList.data = res.data.brands;
        }).catch(err => {
          message.error(err.message ? err.message : '未知错误', 5);
        }).finally(() => {
          this.brandsList.loading = false;
        });
      },

      onShowAddBrandModal() {
        this.addBrandModal.visible = true;
      },
      onAdd() {
        if (this.addBrandModal.data.brand_no === '') {
          Modal.warning({
            title: '品牌编号不能空',
          });
          return;
        }
        if (this.addBrandModal.data.order === '') {
          Modal.warning({
            title: '品牌排序不能空',
          });
          return;
        }
        this.addBrand({brand: this.addBrandModal.data});
      },
      addBrand(params) {
        this.addBrandModal.loading = true;
        addBrand(params).then(() => {
          Modal.success({
            title: '品牌 ' + params.brand.brand_no + ' 新增成功',
          });
          this.addBrandModal.visible = false;
          this.getBrands();
          this.addBrandModal.data.brand_no = '';
          this.addBrandModal.data.order = 0;
        }).catch(err => {
          Modal.error({
            title: '新增品牌失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.addBrandModal.loading = false;
        });
      },
      onCloseAddBrandModal() {
        this.addBrandModal.visible = false;
      },

      onShowUpdateBrandModal(item) {
        this.updateBrandModal.visible = true;
        this.updateBrandModal.data.id = item.id;
        this.updateBrandModal.data.brand_no = item.brand_no;
        this.updateBrandModal.data.order = item.order;
      },
      onUpdate() {
        if (this.updateBrandModal.data.order === '') {
          Modal.warning({
            title: '品牌排序不能空',
          });
          return;
        }
        this.updateBrand({brand: this.updateBrandModal.data});
      },
      updateBrand(params) {
        this.updateBrandModal.loading = true;
        updateBrand(params).then(() => {
          Modal.success({
            title: '品牌 ' + params.brand.brand_no + ' 更新成功',
          });
          this.updateBrandModal.visible = false;
          this.getBrands();
        }).catch(err => {
          Modal.error({
            title: '品牌更新失败',
            content: err.message || '',
          });
        }).finally(() => {
          this.updateBrandModal.loading = false;
        });
      },
      onCloseUpdateBrandModal() {
        this.updateBrandModal.visible = false;
      },

      onTriggerEdit() {
        this.brandsList.showEdit = !this.brandsList.showEdit;
      },

      onDelete(item) {
        let that = this;
        let deleteModal = Modal.confirm({
          title: '确定要删除品牌 ' + item.brand_no + ' ？',
          icon: createVNode(ExclamationCircleOutlined),
          confirmLoading: false,
          onOk() {
            deleteModal.update({
              confirmLoading: true,
              cancelButtonProps: {disabled: true},
            });
            return deleteBrand({brand_no: item.brand_no}).then(() => {
              Modal.success({
                title: '品牌' + item.brand_no + '删除成功',
              });
              that.getBrands();
            }).catch(err => {
              Modal.error({
                title: '删除品牌' + item.brand_no + '失败',
                content: err.message || '',
              });
            });
          },
          onCancel() {
          },
        });
      },

      goToModelKit(brand_no) {
        this.$router.push({path: '/brand/model-kit', query: {brand_no: brand_no}});
      }
    },
  }
</script>

<style scoped>
  .bottom-button {
    text-align: center;
    margin: 10px auto;
  }
</style>