import {createRouter, createWebHistory} from 'vue-router'
import Login from '@/views/login';
import Logout from '@/views/logout';
import Layout from '@/layout';

import brandRouter from './modules/brand';
import modelRouter from './modules/model';
import kitRouter from './modules/kit';
import componentRouter from './modules/component';
import partRouter from './modules/part';
import boxRouter from './modules/box';
import glyRouter from './modules/gly';
import searchRouter from './modules/search';

const routes = [
  brandRouter,
  modelRouter,
  kitRouter,
  componentRouter,
  partRouter,
  boxRouter,
  glyRouter,
  searchRouter,
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {title: '登录'},
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {title: '退出'},
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: `/dashboard`,
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: {title: '控制台', icon: 'dashboard', affix: true}
      },
      {
        path: `/xgmm`,
        component: () => import('@/views/xgmm/index'),
        name: 'Xgmm',
        meta: {title: '修理密码'}
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
